import { publicRequest } from "../../requestMeth";
import {
  companiesStart,
  companiesSuccess,
  companiesFailure,
  createCompanyFailure,
  createCompanyStart,
  createCompanySuccess,
  updateFailure,
  updateStart,
  updateSuccess,
  deleteFailure,
  deleteStart,
  deleteSuccess,
  companyEditStart,
  companyEditSuccess,
  companyEditFailure,
} from "../reducers/companiesReducer";

//listo
export const getAllcompanies = async (dispatch, token) => {
  dispatch(companiesStart());
  try {
    const res = await publicRequest.get(`/master/companies`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(companiesSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(companiesFailure([]));
  }
};

//listo
export const getCompanyById = async (dispatch, id, token) => {
  dispatch(companyEditStart());
  try {
    const res = await publicRequest.get(`/master/company/${id}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(companyEditSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(companyEditFailure({}));
  }
};

export const createCompany = async (dispatch, payload, token) => {
  dispatch(createCompanyStart());
  try {
    const res = await publicRequest.post(`/master/company`, payload, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(createCompanySuccess(res.data));
  } catch (error) {
    dispatch(createCompanyFailure({}));
    return {
      error: true,
      message: "Error al crear sucursal. Intente nuevamente.",
    };
  }
};

export const updateCompany = async (dispatch, id, payload, token) => {
  dispatch(updateStart());
  try {
    const res = await publicRequest.put(`/master/company/${id}`, payload, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(updateSuccess(res.data));
  } catch (error) {
    dispatch(updateFailure({}));
    return {
      error: true,
      message: "No se pudo editar la sucursal. Intente nuevamente2",
    };
  }
};

export const deleteCompany = async (dispatch, id, token) => {
  dispatch(deleteStart());
  try {
    const res = await publicRequest.delete(`/master/company/${id}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(deleteSuccess(res.data));
  } catch (error) {
    dispatch(deleteFailure([]));
  }
};
