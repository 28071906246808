import React from 'react'

const Activity = ({activity}) => {
  return (
    <tr className='user-row'>
      <td>{activity.user}</td>
      <td>{activity.message}</td>
      <td>{activity.date}</td>
    </tr>
  )
}

export default Activity