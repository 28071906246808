import { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { monthsInOrder } from "../../helpers/infoFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: undefined,
  scales: {
    y: {
      min: 0,
      ticks: {
        callback: function (value, index, values) {
          return "$" + value;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.dataset.label || "";
          const value = context?.parsed?.y;
          const formattedValue = value?.toLocaleString("es-AR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return `${label}: ${formattedValue}`;
        },
      },
    },
    title: {
      display: true,
      text: "Comparación de gastos por mes con año anterior",
    },
  },
};

const LineChart = () => {
  
  const generalData = useSelector((state) => state.data.generalData); 
  const isFetchingGeneral = useSelector((state) => state.data.isFetchingGeneralData);
  const orderedCostsByMonthLastYear = monthsInOrder?.length > 0 && monthsInOrder?.map(
    (month) => generalData?.costsByMonthLastYear && generalData?.costsByMonthLastYear[month]
  );
  const orderedCostsByMonthCurrentYear = monthsInOrder?.length > 0 && monthsInOrder?.map(
    (month) => generalData?.costsByMonthCurrentYear && generalData?.costsByMonthCurrentYear[month]
  );

  const dataMemo = useMemo(
    function () {
      return {
        datasets: [
          {
            label: "Año anterior",
            data: orderedCostsByMonthLastYear,
            tension: 0.3,
            borderColor: "rgb(83, 144, 217)",
            pointRadius: 3,
            pointBackgroundColor: "rgb(83, 144, 217)",
            backgroundColor: "rgba(83, 144, 217, 0.3)",
          },
          {
            label: "Año actual",
            tension: 0.3,
            data: orderedCostsByMonthCurrentYear,
            borderColor: "rgb(174, 184, 254)",
            pointBackgroundColor: "rgb(174, 184, 254)",
            backgroundColor: "rgba(174, 184, 254, 0.3)",
            pointRadius: 3,
          },
        ],
        labels: monthsInOrder,
      };
    },
    [generalData]
  );

  return (
    <div style={{ height: "310px", width: "100%" }}>
      {isFetchingGeneral ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="visually-hidden">Cargando CO2...</span>
          </div>
        </div>
      ) : (
      <Line data={dataMemo} options={options} />
    )}
    </div>
  );
};

export default LineChart;
