import "./Home.css";
import React, { useEffect, useState } from "react";
import { generateID } from "../../helpers/widgetsCharts";
import DashboardSelector from "../../components/Home/DashboardSelector";
import TemplatesSelect from "../../components/Home/TemplatesSelect";
import FilterComponent from "../../components/Home/FilterComponent/FilterComponent";
import { format, isValid, startOfMonth, endOfMonth } from "date-fns";

function Home() {
  const [boardSelected, setBoardSelected] = useState(["board1"]); 
  const [board, setBoard] = useState({
    board1: [{
      template: "templateA",
      id_widget: generateID(),
      board: "board1",
      class: [],
      date: [],
      provinces: [],
      companies: [],
      utilities: [],
    }],
  });


  const [filterOptions, setFilterOptions] = useState({
    date: [],
    provinces: [],
    companies: [],
    utilities: [],
    class: [],
  });

  const [filterClass, setFilterClass] = useState({
    class: [],
  });


  const addImageToBoard = (templateName) => {
    let addTemplate = {
      template: templateName,
      id_widget: generateID(),
      board: boardSelected[0],
      class: filterClass.class,
      ...filterOptions
    };
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };
      const boardName = boardSelected;
      newBoard[boardName] = [addTemplate];

      return newBoard;
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    if (isValid(currentDate)) {
      const firstDayOfMonth = startOfMonth(currentDate);
      const lastDayOfMonth = endOfMonth(currentDate);
      const formattedStartDate = format(firstDayOfMonth, "yyyy-MM-dd");
      const formattedEndDate = format(lastDayOfMonth, "yyyy-MM-dd");

      setFilterOptions({
        ...filterOptions,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    }
  }, []); 

  return (
    <div className="">
      <div className="cont-home">
        <div className="parent">
          <div className="div1">
            <TemplatesSelect 
              addImageToBoard={addImageToBoard}
            />
          </div>
          <div className="dash">
            <ul className="cont-nav">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" type="input">
                  Escritorio
                </a>
              </li>
              <li className="nav-item">
                <FilterComponent
                  board ={board}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                  filterClass={filterClass}
                  setFilterClass={setFilterClass}
                />
              </li>
              <li className="nav-item dropdown">
              </li>
            </ul>
            <div className="cont-dashselector">
              <DashboardSelector
                board={board} 
                filterOptions={filterOptions} 
                boardSelected={boardSelected}
                filterClass={filterClass} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
