import { userStart, userSuccess, userFailure, updateStart, updateSuccess, updateFailure } from "../reducers/editUserReducer";
import { publicRequest, userRequest } from "../../requestMeth"; 
import { usersSuccess } from "../reducers/usersReducer";


export const getUser = async (dispatch, id, token) => {
    dispatch(userStart());
    try {
        const res = await publicRequest.get(`/users/${id}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(userSuccess(res.data));
    } catch (error) {
        dispatch(userFailure({}));
        return error.response.data
    }
};


export const updateUser = async (dispatch, id, payload, token) => {
    dispatch(updateStart());
    try {
        const res = await userRequest.put(`/users/edit/${id}`, payload, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(usersSuccess(res.data));
    } catch (error) {
        dispatch(updateFailure({}));
        return error.response.data
    }
};