import { createSlice } from "@reduxjs/toolkit";

const createUserlice = createSlice({
  name: "userCreate",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false
  },
  reducers: {
    createUserStart: (state) => {
      state.isFetching = true;
    },
    createUserSuccess: (state, action) => {
        state.isFetching = false;
        state.currentUser = action.payload
        state.error = false;
    },
    createUserFailure: (state) => {
        state.currentUser = null;
        state.isFetching = false;
        state.error = true;
    }
  },
});


export const { createUserStart, createUserSuccess, createUserFailure } = createUserlice.actions;
export default createUserlice.reducer;