import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import {
  getSupplier,
  updateSupplier,
} from "../../redux/apiCalls/editSupplierCall";
import Loading from "../../components/Loading/Loading";

const EditSupplier = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const supplier = useSelector((state) => state.supplierEdit.currentSupplier);
  const isFetching = useSelector((state) => state.supplierEdit.isFetching);
  const [modifiedFields, setModifiedFields] = useState({});
  const [inputs, setInputs] = useState({
    nombre: supplier?.nombre || "",
    codificacion: supplier?.codificacion || "",
    servicio: supplier?.servicio || "",
    accesoFacturas: supplier?.accesoFacturas || "",
    usuario: supplier?.usuario || "",
    contraseña: supplier?.contraseña || "",
    contacto: supplier?.contacto || "",
    email: supplier?.email || "",
    telefono: supplier?.telefono || "",
    observaciones: supplier?.observaciones || "",
    reserva2: supplier?.reserva2 || "",
    reserva1: supplier?.reserva1 || "",
    estado: supplier?.estado || "",
  });
  
  useEffect(() => {
    getSupplier(dispatch, id, user?.accessToken).then((res) => {
      if(res?.error){
        Toast.fire({
          icon: "error",
          title: res?.message,
        });
      }
      setInputs({
        nombre: res?.nombre,
        codificacion: res?.codificacion,
        servicio: res?.servicio,
        accesoFacturas: res?.accesoFacturas,
        usuario: res?.usuario,
        contraseña: res?.contraseña,
        contacto: res?.contacto,
        email: res?.email,
        telefono: res?.telefono,
        observaciones: res?.observaciones,
        reserva2: res?.reserva2,
        reserva1: res?.reserva1,
        estado:
          res?.estado === "Habilitado"
            ? "Habilitado"
            : res?.estado === "Deshabilitado"
            ? "Deshabilitado"
            : "",
      });
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    setModifiedFields({
      ...modifiedFields,
      [name]: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Está seguro que desea editar el proveedor?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const modifiedData = {};

        for (const key in modifiedFields) {
          if (modifiedFields[key]) {
            modifiedData[key] = inputs[key];
          }
        }
        updateSupplier(dispatch, id, modifiedData, user.accessToken).then(
          (res) => {
            if (res?.error) {
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
              navigate(`/supplier/${id}`);
            } else {
              Toast.fire({
                icon: "success",
                title: `Se modificó el proveedor.`,
              });
              navigate(`/supplier/${id}`);
            }
          }
          );
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo editar el proveedor.`,
        });
      }
    });
  };

  if (isFetching) return <Loading />;
  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=suppliers`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Editar proveedor</h2>
      <form>
        <div className="form-group">
          <label htmlFor="nombre">Nombre del proveedor: </label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            placeholder={inputs?.nombre}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="codificacion">Codificación del proveedor: </label>
          <input
            type="text"
            id="codificacion"
            name="codificacion"
            placeholder={inputs?.codificacion || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="servicio">Servicio del proveedor: </label>
          <select
            id="servicio"
            name="servicio"
            placeholder={inputs?.servicio || ""}
            value={inputs.servicio}
            onChange={handleInputChange}
          >
            <option value="AGUA">AGUA</option>
            <option value="ELECTRICIDAD">ELECTRICIDAD</option>
            <option value="GAS NATURAL">GAS NATURAL</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="accesoFacturas">Acceso a las facturas: </label>
          <input
            type="text"
            id="accesoFacturas"
            name="accesoFacturas"
            placeholder={inputs?.accesoFacturas || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="usuario">Usuario para acceder: </label>
          <input
            type="text"
            id="usuario"
            name="usuario"
            placeholder={inputs?.usuario || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contraseña">Contraseña para acceder: </label>
          <input
            type="password"
            id="contraseña"
            name="contraseña"
            placeholder={inputs?.contraseña || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contacto">Contacto</label>
          <input
            type="text"
            id="contacto"
            name="contacto"
            placeholder={inputs?.contacto || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder={inputs?.email || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono</label>
          <input
            type="text"
            id="telefono"
            name="telefono"
            placeholder={inputs?.telefono || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="observaciones">Observaciones</label>
          <input
            type="text"
            id="observaciones"
            name="observaciones"
            placeholder={inputs?.observaciones || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserva1">Reserva 1</label>
          <input
            type="text"
            id="reserva1"
            name="reserva1"
            placeholder={inputs?.reserva1 || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserva2">Reserva 2</label>
          <input
            type="text"
            id="reserva2"
            name="reserva2"
            placeholder={inputs?.reserva2 || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserva2">Estado</label>
          <select
            id="estado"
            name="estado"
            value={inputs.estado}
            onChange={handleInputChange}
          >
            <option value="" disabled>
              Selecciona una opción
            </option>
            <option value="Habilitado">Habilitado</option>
            <option value="Deshabilitado">Deshabilitado</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Editar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSupplier;
