import axios from "axios";


//const BASE_URL = `https://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;
const BASE_URL = `https://${process.env.REACT_APP_API_HOST}/api/`;

const getUser = ()=>{
  const data = JSON.parse(localStorage.getItem("persist:root"))?.user;
  return data;
}
const user = getUser();
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});
