import { publicRequest } from "../../requestMeth"; 
import { billsReportFailure, billsReportStart, billsReportSuccess, powerReportStart, powerReportFailure, powerReportSuccess, energyReportSuccess, energyReportFailure, energyReportStart, } from "../reducers/billsReportReducer";

export const getBillsReport = async (dispatch, token, querys) => {
    dispatch(billsReportStart());
    try {
        const res = await publicRequest.get(`/data/anualEditor?supplier=${querys?.supplierSelected}&year=${querys?.year}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(billsReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(billsReportFailure({}));
        return error.response.data
    }
};

export const getPowerReport = async (dispatch, token, querys) => {
    dispatch(powerReportStart());
    try {
        const res = await publicRequest.get(`/data/auditPower?supplier=${querys?.supplierSelected}&year=${querys?.year}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(powerReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(powerReportFailure({}));
        return error.response.data
    }
};

export const getEnergyReport = async (dispatch, token, querys) => {
    dispatch(energyReportStart());
    try {
        const res = await publicRequest.get(`/data/auditEnergy?supplier=${querys?.supplierSelected}&year=${querys?.year}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(energyReportSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(energyReportFailure({}));
        return error.response.data
    }
};