import React from 'react';
import { LoadScript } from '@react-google-maps/api';

const LoadScriptWrapper = ({ children }) => {
  const API_KEY = process.env.REACT_APP_MAPS_APIKEY;
  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      {children}
    </LoadScript>
  );
};

export default LoadScriptWrapper;
