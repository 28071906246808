import React from 'react'
import summaryTemplate from '../../../assets/corpSummary.png'
import energyDash from '../../../assets/energyDash.png'
import financeTemplate from '../../../assets/finance.png'
import sustainabilityTemplate from '../../../assets/sustainabilitydash.png'


const DashboardsInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">Dashboards y reportes</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div>
          <h5>Filtros</h5>
          <ul>
            <li>Período: Se debe seleccionar mes de inicio y mes de fin. Los datos que se muestren en los dashboards serán de las facturas con vencimiento en los meses elegidos.</li>
            <li>Provincia: Se puede seleccionar una o más provincias. Cuenta con buscador y limpia filtro. </li>
            <li>Sucursal: Se puede seleccionar una o más sucursales. Cuenta con buscador y limpia filtro. Estas sucursales elegidas se tendrán en cuenta para el reporte de facturas y el reporte de consumos.</li>
            <li>Servicio: Puede elegir entre el servicio de electricidad, gas o agua.</li>
            <li>Concepto: Se puede seleccionar uno o más conceptos, se muestran conceptos relacionados al servicio elegido.</li>
          </ul>
        </div>
        <div>
          <h5>Dashboards</h5>
          <div>
            <img src={energyDash} width="50px" height="50px" alt="Energy dash"/>
            <p>El dashboard de energía muestra consumos en unidades físicas. Cuenta con tablas y gráficas que muestran consumos discriminados por servicios, proveedores, conceptos, sucursales.</p>
          </div>
          <div>
            <img src={financeTemplate} width="60px" height="60px" alt="Finance dash"/>
            <p>El dashboard económico muestra información de gastos.</p>
          </div>
          <div>
            <img src={sustainabilityTemplate} width="50px" height="50px" alt="Sustainability dash"/>
            <p>El dashboard de sostenibilidad representa la emisión de carbono, cuenta con un gráfico de comparativa anual. Se muestran las sucursales con mayor emisión de CO2 y consumo de agua. </p>
          </div>
          <div>
            <img src={summaryTemplate} width="50px" height="50px" alt="Corporate dash"/>
            <p>El dashboard corporativo cuenta con información de consumos, gastos y emisión de carbono. Se ven datos generales.</p>
          </div>
        </div>
        <div>
          <h5>Reportes</h5>
          <ul>
            <li>Reporte de facturas: Muestra toda la información de las facturas, conceptos, fechas, consumos y costos. Puede aplicar filtros.</li>
            <li>Reporte de consumos: Reporte de consumos prorrateados por mes del año en curso. Muestra los días calculados y el porcentaje de carga por mes.</li>
            <li>Completitud de cargas facturas: Se muestra por sucursal las facturas que se esperan en el mes, cuántas se cargaron y el porcentaje de completitud.</li>
          </ul>
        </div>
      </div>
    </div>
    
  )
}

export default DashboardsInfo