import "./ConfigPassword.css"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toast } from "../../helpers/alert";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getPassPolicies, updatePassPolicies } from "../../redux/apiCalls/passPoliciesCall";
import Loading from "../../components/Loading/Loading";
import { FaArrowLeft } from "react-icons/fa";


const ConfigPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const passPolicies = useSelector((state) => state.passPolicies);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [policiesLoaded, setPoliciesLoaded] = useState(false);

useEffect(() => {
  if (!policiesLoaded) {
    getPassPolicies(dispatch, user.currentUser?.accessToken);
    setPoliciesLoaded(true);
  } else {
    setSelectedOptions({
      characters: passPolicies.passPolicies?.characters ?? selectedOptions.characters,
      mayus: passPolicies.passPolicies?.mayus ?? selectedOptions.mayus,
      numbers: passPolicies.passPolicies?.numbers ?? selectedOptions.numbers,
      days: passPolicies.passPolicies?.days ?? selectedOptions.days,
      length: passPolicies.passPolicies?.length ?? selectedOptions.length
    });
  }
}, [policiesLoaded, passPolicies.passPolicies]);
  
  const handleOptionChange = (e) => {
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name] : e.target.value
    })
  };

  const handleCheckChange = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? 1 : 0;
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name] : value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
      Swal.fire({
        title: "¿Está seguro que desea modificar las políticas de contraseña?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, hacer los cambios!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
              await updatePassPolicies(
                dispatch,
                selectedOptions,
                user.currentUser?.accessToken
              );
              Toast.fire({
                icon: "success",
                title:"Los cambios fueron aplicados", 
              });
              navigate("/admin/lists?show=users");
        } else{
          Toast.fire({
            icon: "info",
            title:"Cambios descartados",
          });
        }
      });
  }


  if (!passPolicies.passPolicies || passPolicies.isFetching) return <Loading/>
  return (
    <>
      <div className="cont-pass">
        <div className="list-group list-group-flush list-group-pass">
          <div className="cont-back" onClick={() => navigate(`/admin/lists?show=users`)}>
            <i>
              <FaArrowLeft />
            </i>
            <p>
              Usuarios
            </p>
          </div> 
          <p>Gestión de claves</p>
          <div className="ind-info">
            <label>Duración de la contraseña: </label>
            <li className="list-group-item list-group-item-action">
              <select className="form-select" onChange={handleOptionChange} name="days" value={selectedOptions?.days}>
                <option value="30">30 dias</option>
                <option value="60">60 dias </option>
                <option value="90">90 dias</option>
              </select>
            </li>
          </div>
          <div className="ind-info">
            <label> Tipo de contraseña: </label>
            <li className="list-group-item">
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="numbers"
                  onChange={handleCheckChange}
                  checked={selectedOptions?.numbers === 1 ? true : false}
                />
                <label className="form-check-label">Al menos 1 dígito</label>
              </div>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="characters"
                  onChange={handleCheckChange}
                  checked={selectedOptions?.characters === 1 ? true : false}
                />
                <label className="form-check-label">Carácter especial</label>
              </div>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="mayus"
                  onChange={handleCheckChange}
                  checked={selectedOptions?.mayus === 1 ? true : false}
                />
                <label className="form-check-label">Letra mayúscula</label>
              </div>
            </li>
          </div>
          <div className="ind-info">
            <label>Número de caracteres: </label>
            <li className="list-group-item list-group-item-action">
              <input
                type="number"
                className="form-control"
                name="length"
                min={6}
                max={15}
                placeholder={selectedOptions?.length}
                inputMode="numeric"
                pattern="[6-9]|1[0-5]"
                onChange={handleOptionChange}
              />
            </li>
          </div>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Actualizar
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfigPassword;
