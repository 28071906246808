// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cont-chart1 {
  width: 100%;
  height: 100%;
}

.cont-home { 
  display: grid; 
  width: 95%;
  margin: auto;
  margin-bottom: 10%;

}

.dash {
  margin-top: 2%;
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);
  height: 100%; 
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

  .div1 {
    margin-bottom: 10px; 
  }


}
}


.cont-nav {
  padding: 5px 20px 5px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e6e9ed;
}

.cont-nav a {
  color: #73879c;
  font-weight: 700;
}

.nav-item{
  list-style-type: none;
}

.img-dash{
  width: 60px;
}

.select-Temp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-Temp p {
  font-size: 14px;
  text-align: center;
}

.cont-dashselector{
  /* max-height: calc(100vh - 100px); */
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,UAAU;EACV,YAAY;EACZ,kBAAkB;;AAEpB;;AAEA;EACE,cAAc;EACd,+CAA+C;EAC/C,uDAAuD;EACvD,oDAAoD;EACpD,YAAY;AACd;;AAEA,sCAAsC;AACtC;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;;EAEjB;IACE,mBAAmB;EACrB;;;AAGF;AACA;;;AAGA;EACE,0BAA0B;EAC1B,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;AAClB","sourcesContent":[".cont-chart1 {\n  width: 100%;\n  height: 100%;\n}\n\n.cont-home { \n  display: grid; \n  width: 95%;\n  margin: auto;\n  margin-bottom: 10%;\n\n}\n\n.dash {\n  margin-top: 2%;\n  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);\n  -webkit-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);\n  -moz-box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.28);\n  height: 100%; \n}\n\n/* Estilos para dispositivos móviles */\n@media (max-width: 768px) {\n  .parent {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n\n  .div1 {\n    margin-bottom: 10px; \n  }\n\n\n}\n}\n\n\n.cont-nav {\n  padding: 5px 20px 5px 20px;\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 2px solid #e6e9ed;\n}\n\n.cont-nav a {\n  color: #73879c;\n  font-weight: 700;\n}\n\n.nav-item{\n  list-style-type: none;\n}\n\n.img-dash{\n  width: 60px;\n}\n\n.select-Temp{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.select-Temp p {\n  font-size: 14px;\n  text-align: center;\n}\n\n.cont-dashselector{\n  /* max-height: calc(100vh - 100px); */\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
