import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    currentData: null,
    generalData: null,
    generalDataCO2: null,
    anualData: null,
    co2Data: null,
    uploadedBills: null,
    isFetching: false,
    isFetchingAnual: false,
    isFetchingGeneralData: false,
    isFetchingGeneralCo2: false,
    isFetchingCo2: false,
    billFetching: false,
    error: false,
    errorMessage: null
  },
  reducers: {
    dataStart: (state) => {
      state.isFetching = true;
    },
    dataSuccess: (state, action) => {
      state.isFetching = false;
      state.currentData = action.payload;
    },
    dataFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    generalDataStart: (state) => {
      state.isFetchingGeneralData = true;
    },
    generalDataSuccess: (state, action) => {
      state.isFetchingGeneralData = false;
      state.generalData = action.payload;
    },
    generalDataFailure: (state, action) => {
      state.isFetchingGeneralData = false;
      state.generalData = action.payload;
      state.error = true;
    },
    generalDataStartCO2: (state) => {
      state.isFetchingGeneralCo2 = true;
    },
    generalDataSuccessCO2: (state, action) => {
      state.isFetchingGeneralCo2 = false;
      state.generalDataCO2 = action.payload;
    },
    generalDataFailureCO2: (state, action) => {
      state.isFetchingGeneralCo2 = false;
      state.generalDataCO2 = action.payload;
      state.error = true;
    },
    anualDataStart: (state) => {
      state.isFetchingAnual = true;
    },
    anualDataSuccess: (state, action) => {
      state.isFetchingAnual = false;
      state.anualData = action.payload;
    },
    anualDataFailure: (state, action) => {
      state.isFetchingAnual = false;
      state.anualData = action.payload;
      state.error = true;
    },
    co2DataStart: (state) => {
      state.isFetchingCo2 = true;
    },
    co2DataSuccess: (state, action) => {
      state.isFetchingCo2 = false;
      state.co2Data = action.payload;
    },
    co2DataFailure: (state, action) => {
      state.isFetchingCo2 = false;
      state.co2Data = action.payload;
      state.error = true;
    },
    logoutInfoData: (state) => {
      state.currentData = null;
      state.generalData = null;
      state.generalDataCO2 = null;
      state.anualData = null;
      state.co2Data = null;
      state.uploadedBills = null;
      state.isFetching = false;
      state.isFetchingGeneralData = false;
      state.isFetchingCo2 = false;
      state.isFetchingGeneralCo2 = false;
      state.billFetching = false;
      state.errorMessage = null;
      state.error = false;
    },
    billStart: (state) => {
      state.billFetching = true;
      state.error = false;
    },
    billSuccess: (state, action) => {
      state.billFetching = false;
      state.error = false;
    },
    billFailure: (state, action) => {
      state.billFetching = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    uploadedBillsStart: (state) => {
      state.isFetching = true;
    },
    uploadedBillsSuccess: (state, action) => {
      state.isFetching = false;
      state.uploadedBills = action.payload;
    },
    uploadedBillsFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.uploadedBills = action.payload;
    },
    deleteBillsStart: (state) => {
      state.isFetching = true;
    },
    deleteBillsSuccess: (state, action) => {
      state.isFetching = false;
      state.uploadedBills = action.payload;
    },
    deleteBillsFailure: (state,action) => {
      state.isFetching = false;
      state.error = true;
      state.uploadedBills = action.payload;
    },
  },
});

export const { dataStart, dataSuccess, dataFailure, generalDataStart, generalDataSuccess, generalDataFailure, logoutInfoData, 
  billStart, billSuccess, billFailure, uploadedBillsFailure, uploadedBillsStart, 
  uploadedBillsSuccess, deleteBillsFailure, deleteBillsStart, deleteBillsSuccess,
  co2DataStart, co2DataFailure, co2DataSuccess, anualDataFailure, anualDataStart, anualDataSuccess, generalDataFailureCO2, generalDataStartCO2,
generalDataSuccessCO2} = dataSlice.actions;
export default dataSlice.reducer;