import { createUserStart, createUserSuccess, createUserFailure  } from "../reducers/createUserReducer";
import { publicRequest, userRequest } from "../../requestMeth"; 


export const createUser = async (dispatch, payload, token) => {
    dispatch(createUserStart());
    try {
        const res = await publicRequest.post("/users/register", payload,{
            headers: { token: `Bearer ${token}` }
        });
        dispatch(createUserSuccess(res.data));
    } catch (error) {
        dispatch(createUserFailure({}));
        return error.response.data
    }
};