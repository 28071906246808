import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./reducers/loginReducer";
import profileReducer from "./reducers/profileReducer";
import dataReducer from './reducers/dataReducer';
import usersReducer from "./reducers/usersReducer";
import loggersReducer from "./reducers/loggersReducer";
import companiesReducer from "./reducers/companiesReducer";
import passPoliciesReducer from "./reducers/passPoliciesReducer"
import createUserReducer from "./reducers/createUserReducer";
import userEditReducer from "./reducers/editUserReducer";
import supplierReducer from './reducers/supplierReducer';
import supplierCompanyReducer from './reducers/supplierCompanyReducer';
import itemBills from './reducers/itemBills';
import createSupplierReducer from './reducers/createSupplierReducer';
import editSupplierReducer from './reducers/editSupplierReducer';
import editItemBills from './reducers/editItemBills';
import billsReportReducer from './reducers/billsReportReducer';


const rootReducer = combineReducers({ 
  user: loginReducer, 
  profile: profileReducer,
  data : dataReducer,
  users: usersReducer,
  userCreate: createUserReducer,
  userEdit: userEditReducer,
  passPolicies: passPoliciesReducer,
  companies: companiesReducer,
  loggers: loggersReducer,
  supplier: supplierReducer,
  supplierCompany: supplierCompanyReducer,
  itemsBill: itemBills,
  supplierCreate: createSupplierReducer,
  supplierEdit: editSupplierReducer,
  editItemBill: editItemBills,
  billsReport: billsReportReducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['data', 'loggers', 'supplierCompany', 'billsReport'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
  

export let persistor = persistStore(store)
