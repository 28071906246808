export const torres = [
  "SUCURSAL 3012CPD TORRE CPD",
  "SUCURSAL 3012P1 TORRE PISO 1",
  "SUCURSAL 3012P2 TORRE PISO 2",
  "SUCURSAL 3012P21 TORRE PISO 21",
  "SUCURSAL 3012P22 TORRE PISO 22",
  "SUCURSAL 3012P23 TORRE PISO 23",
  "SUCURSAL 3012P24 TORRE PISO 24",
  "SUCURSAL 3012P25 TORRE PISO 25",
  "SUCURSAL 3012P26 TORRE PISO 26",
  "SUCURSAL 3012P27 TORRE PISO 27",
  "SUCURSAL 3012P28 TORRE PISO 28",
  "SUCURSAL 3012P29 TORRE PISO 29",
  "SUCURSAL 3012P30 TORRE PISO 30",
  "SUCURSAL 3012P31 TORRE PISO 31",
  "SUCURSAL 3012P32 TORRE PISO 32",
  "SUCURSAL 3012P33 TORRE PISO 33"
];

export const conceptosEdsT3 = [
  'Cargo Fijo T3',
  'Cap.Sum.Conv T3',
  'Cap.Sum.Adquirida T3',
  'Energ. Hrs. Restante',
  'Energ. Hrs. Valle No',
  'Energ. Hrs. punta',
  'Recargo Energia Reac',
  'Imp. Valor Agregado 27.000%',
  'Contribución Municipal 6.383%',
  'Percep IVA RG2408/08 3.000%'
];


export const conceptoMapEdsT3 = {
  'Cargo Fijo T3': 'Cargo Fijo T3',
  'Cap.Sum.Conv T3': 'Capacidad de suministro convenida T3',
  'Cap.Sum.Adquirida T3': 'Capacidad de suministro adquirida T3',
  'Energ. Hrs. Restante': 'Energ. Hrs. Restantes',
  'Energ. Hrs. Valle No': 'Energ. Hrs. Valle Noc.',
  'Energ. Hrs. punta': 'Energ. Hrs. Punta',
  'Recargo Energia Reac': 'Energia Reactiva T3',
  'Imp. Valor Agregado 27.000%': 'Imp. Valor Agregado 27%',
  'Contribución Municipal 6.383%': 'Contribución Municipal 6,383%',
  'Percep IVA RG2408/08 3.000%': 'Percep IVA RG2408/08'
};

export const conceptosEdsT2 = [
  'Cargo Fijo T2',
  'Cap.Sum.Conv T2',
  'Cap.Sum.Adquirida',
  'Cargo Variable',
  'Recargo Energ í a Reactiva T2',
  'Imp. Valor Agregado 27.000%',
  'Contribuci ó n Municipal 6.383%',
  'Percep IVA RG2408/08 3.000%'
];

export const conceptoMapEdsT2 = {
  'Cargo Fijo T2': 'Cargo Fijo T2',
  'Cap.Sum.Conv T2': 'Capacidad de suministro convenida T2',
  'Cap.Sum.Adquirida': 'Capacidad de suministro adquirida T2',
  'Cargo Variable': 'Energía consumida T2',
  'Recargo Energ í a Reactiva T2': 'Energía reactiva T2',
  'Imp. Valor Agregado 27.000%': 'Imp. Valor Agregado 27%',
  'Contribuci ó n Municipal 6.383%': 'Contribución Municipal 6,383%',
  'Percep IVA RG2408/08 3.000%': 'Percep IVA RG2408/08'
};

export const conceptosEdsT1 = [
  'Cargo Fijo',
  'Cargo Variable',
  'Cap.Sum.Conv T1',
  'Cap.Sum.Adquirida',
  'Imp. Valor Agregado    27.000%',
  'Percep IVA RG2408/08     3.000%',
  'Contribuci ó n Municipal     6.383%',
  'SUBTOTAL',
  'Energ í a Consumida',
  'Total CONSUMIDO (A) '
];
export const conceptoMapEdsT1 = {
  'Cargo Fijo': 'Cargo Fijo T1',
  'Cap.Sum.Conv T1': 'Capacidad de suministro convenida T1',
  'Cap.Sum.Adquirida': 'Capacidad de suministro adquirida T1',
  'Cargo Variable': 'Energía consumida T1',
  'Energ í a Consumida': 'Energía consumida T1',
  'Recargo Energ í a Reactiva T1': 'Energía reactiva T1',
  'Imp. Valor Agregado    27.000%': 'Imp. Valor Agregado 27%',
  'Contribuci ó n Municipal     6.383%': 'Contribución Municipal 6,383%',
  'Percep IVA RG2408/08     3.000%': 'Percep IVA RG2408/08'
};


