import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../helpers/alert";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { resetPass } from "../../redux/apiCalls/profileCall";
import { publicRequest } from "../../requestMeth";
import Loading from "../../components/Loading/Loading";
import { logoutUser } from "../../redux/apiCalls/loginCall";
import { getPassPolicies } from "../../redux/apiCalls/passPoliciesCall";
import { generateRegex } from "../../helpers/Regex";

const UpdatePassword = () => {
  const isFetching = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.user.currentUser);
  const passPolicies = useSelector((state) => state.passPolicies);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getPassPolicies(dispatch, currentUser?.accessToken);
  }, []);

  function validar(input, passPolicies) {
    let errors = {};
    const passRegex = generateRegex(passPolicies);

    if (!input.oldPassword) {
      errors.oldPassword = "Este campo no puede estar vacío";
    }
    if (!input.newPassword) {
      errors.newPassword = "Este campo no puede estar vacío";
    } else if (!passRegex.regex.test(input.newPassword)) {
      errors.newPassword = passRegex.messageRegex;
    }
    if (!input.confirmPassword) {
      errors.confirmPassword = "Confirma tu contraseña";
    } else if (input.newPassword !== input.confirmPassword) {
      errors.confirmPassword = "Las contraseñas deben ser las mismas";
    }
    return errors;
  }

  function handlePaswordChange(e) {
    setPassword({ ...password, [e.target.name]: e.target.value });
    setErrors(
      validar(
        { ...password, [e.target.name]: e.target.value },
        passPolicies.passPolicies
      )
    );
  }

  async function handleBack(e) {
    e.preventDefault();
    logoutUser(dispatch, currentUser?.accessToken, { user: currentUser?.user });
    navigate("/login");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!errors.oldPassword && !errors.newPassword && !errors.confirmPassword) {
      Swal.fire({
        title: "¿Está seguro de que desea editar su perfil?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, hacer los cambios!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (password.oldPassword !== "") {
            try {
              const verified = await publicRequest.post("auth/users/login", {
                username: currentUser.user,
                pass: password.oldPassword,
              });
              const passBody = { pass: password.confirmPassword };
              await resetPass({
                dispatch,
                id: currentUser.id_user,
                pass: passBody,
                token: currentUser.accessToken,
              });
              Toast.fire({
                icon: "success",
                title: "Tu contraseña ha sido cambiada con éxito.",
              });
              logoutUser(dispatch, currentUser?.accessToken, {
                user: currentUser?.user,
              });
              navigate("/login");
            } catch (error) {
              Toast.fire({
                icon: "error",
                title: "Contraseña antigua incorrecta",
              });
            }
          }
        }
      });
    } else {
      Toast.fire({
        icon: "error",
        title: `${Object.values(errors)}`,
      });
    }
  }

  if (isFetching.isFetching) return <Loading />;

  return (
    <>
      <div className="container-login container-fluid cont-pass">
        <div className="upd-pass-dialog">
          <div className="upd-pass-content formLogin">
            <div className="upd-pass-header">
              <h1 className="upd-pass-title fs-5" id="exampleModalLabel">
                Cambiar contraseña
              </h1>
              <p>
                Por razones de seguridad, su contraseña expiró. Es necesario
                cambiarla cada {passPolicies.passPolicies?.days} días.
              </p>
            </div>
            <div className="upd-pass-body">
              <div>
                <label className="label-pass">Anterior contraseña : </label>
                <input
                  className="inpt-pass form-control"
                  name="oldPassword"
                  type="password"
                  onChange={handlePaswordChange}
                  value={password.oldPassword}
                />
                {errors.oldPassword && (
                  <span className="error-pass">{errors.oldPassword}</span>
                )}
              </div>
              <div>
                <label className="label-pass">Nueva contraseña :</label>
                <input
                  className="inpt-pass form-control"
                  name="newPassword"
                  type="password"
                  onChange={handlePaswordChange}
                  value={password.newPassword}
                />
                {errors.newPassword && (
                  <span className="error-pass">{errors.newPassword}</span>
                )}
              </div>
              <div>
                <label className="label-pass">
                  Confirmar nueva contraseña :
                </label>
                <input
                  className="inpt-pass form-control"
                  name="confirmPassword"
                  type="password"
                  onChange={handlePaswordChange}
                  value={password.confirmPassword}
                />
                {errors.confirmPassword && (
                  <span className="error-pass">{errors.confirmPassword}</span>
                )}
              </div>
            </div>
            <div className="upd-pass-footer">
              <button
                type="button"
                className="btn btn-change"
                onClick={(e) => handleBack(e)}
              >
                Atras
              </button>
              <button
                type="button"
                className="btn btn-change confirm-pass"
                onClick={(e) => handleSubmit(e)}
              >
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
