export const normalizeText = (text) => text?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");

export function formatDate(dateString) {
  const options = { day: "2-digit", month: "short", year: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}

export const applyFilters = async (
  filterOptions,
  filterClass,
  bills,
  setFilteredData
) => {

  if (bills) {
    let newData = [...bills];

    if (filterOptions.provinces.length > 0) {
      newData = newData.filter((item) =>
        filterOptions.provinces.includes(item.state)
      );
    }

    // Filtrar por servicio
    if (filterOptions.utilities.length > 0) {
      newData = newData.filter((item) =>
        filterOptions.utilities.includes(item.utilityType)
      );
    }

    // Filtrar por clase
    if (filterClass.class.length > 0) {
      newData = newData.filter((item) =>
        {
          const normalizedClass = normalizeText(item?.class)
          return filterClass.class.includes(normalizedClass)
        }
      );
    }

    // Filtrar por compañía
    if (filterOptions.companies.length > 0) {
      newData = newData.filter((item) =>
        filterOptions.companies.includes(item.locationName)
      );
    }

    newData = replaceNegativeValues(newData)
    await setFilteredData(newData);
  }
};

const replaceNegativeValues = (data) => {
  return data.map((d) => {
    const newData = { ...d }; // Crear una copia del objeto
    if (newData.cost === "-1") { 
      newData.cost = "N/A";
    }

    if (newData.consumption === "-1") { 
      newData.consumption = "N/A";
    }

    return newData;
  });
};

export const calculateDays = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const start = new Date(startDate);
  const end = new Date(endDate);

  const diffDays = Math.round(Math.abs((start - end) / oneDay));
  return isNaN(diffDays) ? "" : (diffDays + 1);
};