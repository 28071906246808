import { utils, writeFile } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";

export default function ItemsExcel({ items }) {

  const downloadExcel = () => {
    if (items && Array.isArray(items)) {
      const wb = utils.book_new();
      if (Array.isArray(items)) {
        

        const dataAsArray = items.map(item => ({
          ID: item.id,
          Concepto: item.lineItemText !== "null" && item.lineItemText !== "" ? item.lineItemText : "N/A",
          Proveedor: item.proveedor !== "null" && item.proveedor !== "" ? item.proveedor : "N/A",
          Clase: item.class !== "null" && item.class !== "" ? item.class : "N/A",
          "Unidad": item.unit && item.unit !== "null" ? item.unit : "N/A",
          "Unidad de costo": item.costUnit && item.costUnit !== "null" ? item.costUnit : "N/A",
          "Unidad de consumo": item.consumptionUnit && item.consumptionUnit !== "null" ? item.consumptionUnit : "N/A",

        }));
      

        const ws = utils.json_to_sheet(dataAsArray);

        const columnWidths = dataAsArray.map(item => item.Concepto.length);
        const maxColumnWidth = Math.max(...columnWidths);

        ws['!cols'] = [
          { wch: 10 },
          { wch: maxColumnWidth + 2 },
          { wch: 15 },
          { wch: 15 },
        ];
        utils.book_append_sheet(wb, ws);
      }

      writeFile(wb, `ReporteItems.xlsx`);
    }
  }

  return (
    <>
      <b onClick={downloadExcel} >
        <FaFileDownload /> Reporte por items
      </b>
    </>
  );
}
