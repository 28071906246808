// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatingHelpIcon{
    position: fixed;
    bottom: 20px;
    left: 20px;
    border-radius: 50%;
    z-index: 1000;
    color: #151414b7;
    background-color: #9aa1b643;
    border: 1px solid #616a8446;
    align-items: center;
    font-weight: 900;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
}

.floatingHelpIcon:hover{
    background-color: #515b795f;
    border: 1px solid #08123046;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}`, "",{"version":3,"sources":["webpack://./src/components/HelpSidebar/HelpSideBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,2BAA2B;IAC3B,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;IAChD,wDAAwD;IACxD,qDAAqD;AACzD;;AAEA;IACI,2BAA2B;IAC3B,2BAA2B;IAC3B,gDAAgD;IAChD,wDAAwD;IACxD,qDAAqD;AACzD","sourcesContent":[".floatingHelpIcon{\n    position: fixed;\n    bottom: 20px;\n    left: 20px;\n    border-radius: 50%;\n    z-index: 1000;\n    color: #151414b7;\n    background-color: #9aa1b643;\n    border: 1px solid #616a8446;\n    align-items: center;\n    font-weight: 900;\n    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.55);\n    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);\n    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);\n}\n\n.floatingHelpIcon:hover{\n    background-color: #515b795f;\n    border: 1px solid #08123046;\n    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);\n    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);\n    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
