import React from "react";
import { FaLightbulb } from "react-icons/fa";
import SupplierRow from "../Cards/Supplier";
import { useNavigate } from "react-router-dom";
import SuppliersExcel from "../Reports/ReportSuppliersList";


const SuppliersList = ({suppliers}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">PROVEEDORES</p>
          <div className="btn-users">
            <button
              className="adduser"
              onClick={() => navigate("/supplier/register")}
              title="Crear proveedor"
            >
              <i>
                <FaLightbulb />
                <b>+</b>
              </i>
              Nuevo proveedor
            </button>
            <button className="adduser" >
        <SuppliersExcel  suppliers = {suppliers} />
        </button> 
          </div>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="user-row">
            <th>CÓDIGO PROVEEDOR</th>
            <th>NOMBRE</th>
            <th>CODIFICACIÓN</th>
            <th>SERVICIO</th>
            <th>ACCESO FACTURAS</th>
            <th>OBSERVACIONES</th>
            <th>ESTADO</th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {suppliers && suppliers?.map((supplier) => (
            <SupplierRow key={supplier.id} supplier={supplier} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SuppliersList;
