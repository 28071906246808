import { createSlice } from "@reduxjs/toolkit";

const passPoliciesSlice = createSlice({
  name: "passPolicies",
  initialState: {
    passPolicies: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    passPoliciesStart: (state) => {
      state.isFetching = true;
    },
    passPoliciesSuccess: (state, action) => {
      state.passPolicies = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    passPoliciesFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutpassPolicies: (state) => {
      state.passPolicies = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  passPoliciesStart,
  passPoliciesSuccess,
  passPoliciesFailure,
  logoutpassPolicies
} = passPoliciesSlice.actions;

export default passPoliciesSlice.reducer;
