import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import { useSelector } from 'react-redux';

const MapWaterChart = ({ waterConsumption }) => {
  const offices = useSelector((state) => state.companies.companies);
  const [markers, setMarkers] = useState([]);
  const [maxConsumption, setMaxConsumption] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const API_KEY = process.env.REACT_APP_MAPS_APIKEY;
  const filteredData = waterConsumption?.filter((item) => (item?.units)?.toUpperCase() === "M3");
  const sortedWaterConsArray = filteredData
    ?.sort((a, b) => b?.consumption - a?.consumption)
    .slice(0, 10);

  useEffect(() => {
    const fetchMapData = async () => {
      let maxConsumption = 0;
      const markerPositions = new Map();

      const markerData = await Promise.all(
        sortedWaterConsArray.map(async (data, index) => {
          const officeFound = offices?.find((o) => o?.name === data?.locationName);
          const { locationName, consumption } = data;
          const { address, state: province } = officeFound;
          const coordinates = await getCoordinates(address, province);

          maxConsumption = Math.max(maxConsumption, consumption);

          if (coordinates) {
            let key = `${coordinates.lat},${coordinates.lng}`;
            if (markerPositions.has(key)) {
              const variation = 0.00002 * (markerPositions.get(key) + 1);
              markerPositions.set(key, markerPositions.get(key) + 1);
              coordinates.lat += variation;
              coordinates.lng += variation;
            } else {
              markerPositions.set(key, 0);
            }

            return {
              position: { lat: coordinates.lat, lng: coordinates.lng },
              consumption,
              locationName
            };
          } else {
            return null;
          }
        })
      );

      setMaxConsumption(maxConsumption);
      setMarkers(markerData.filter(Boolean));
    };
    
    fetchMapData();
  }, [waterConsumption]);

  const getCoordinates = async (address, province) => {
    try {
      const fullAddress = `${address}, ${province}`;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${API_KEY}`;

      const response = await axios.get(url);
      if (response?.data?.status === 'OK' && response?.data?.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location;
        return { lat, lng };
      }
    } catch (error) {
      console.error('Error obtaining coordinates:', address, error);
    }
    return null;
  };
  

  const getColor = (consumption) => {
    // Escala el consumo para determinar el tono de celeste
    const scale = (consumption / maxConsumption) * 100;
  
    // Determina el color basado en el consumo
    if (consumption > 8000) {
      return '#046a86e4'; // Celeste más oscuro
    } else if (consumption > 4000) {
      return '#6ec2d9da'; // Celeste medio
    } else {
      return '#afdeeb92'; // Celeste más claro
    }
  };
  
  

  return (
    <div>
      <p>Mapa de consumo de agua</p>
      <GoogleMap
        mapContainerStyle={{ height: '600px', width: '100%' }}
        center={{ lat: -38.4161, lng: -63.6167 }}
        zoom={4}
      >
        {markers?.map((marker, index) => (
          <Marker
            key={`marker-${index}`}
            position={marker.position}
            icon={{
              path: window?.google?.maps?.SymbolPath?.CIRCLE,
              scale: 10,
              fillColor: getColor(marker?.consumption),
              fillOpacity: 0.6,
              strokeWeight: 0.4
            }}
            onClick={() => setSelectedMarker(marker)}
          />
        ))}
        {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <p>Ubicación: {selectedMarker.locationName}</p>
              <p>Consumo: {selectedMarker.consumption} m3</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <div
        style={{
          width: '100%',
          height: '10px',
          background: `linear-gradient(to right, hsl(120, 100%, 50%), ${getColor(maxConsumption)})`,
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <p>0</p>
        <p>{maxConsumption}</p>
      </div>
    </div>
  );
};

export default MapWaterChart;
