import { publicRequest, userRequest } from "../../requestMeth";
import { usersStart, usersSuccess, usersFailure, deleteStart, deleteSuccess, deleteFailure } from "../reducers/usersReducer";


export const getAllUsers = async (dispatch, token) => {
  dispatch(usersStart());
  try {
    const res = await publicRequest.get("/users", {
      headers: { token: `Bearer ${token}` }
    });
    
    dispatch(usersSuccess(res.data));
  } catch (error) {
    dispatch(usersFailure([]));
  }
};

export const deleteUser = async (dispatch, id, token) =>{
  dispatch(deleteStart());
  try {
    const res = await userRequest.delete(`/users/delete/${id}`, {
      headers: { token: `Bearer ${token}` }
  });
      dispatch(deleteSuccess(res.data));
  } catch (error) {
      dispatch(deleteFailure([]));
      return error.response.data;
  }
};

export const getUsersByName = async (dispatch, name, token) => {
  dispatch(usersStart());
  try {
    const resp = await publicRequest.get(`/admin/users?user=${name}`, {
      headers: { token: `Bearer ${token}` }
    });
      dispatch(usersSuccess(resp.data));
  } catch (error) {
    dispatch(usersFailure([]));
  }
};