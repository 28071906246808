import React from "react";

const TableCorporate = ({ title, data, co2, totalCO2 }) => {
  const locationTotals = {};
  const co2Emissions = co2.reduce((acc, item) => {
    const locationName = item?.locationName;
    const co2Value = parseFloat(item?.CO2) / parseFloat(totalCO2) * 100;
  
    if (acc[locationName]) {
      acc[locationName] += co2Value;
    } else {
      acc[locationName] = co2Value;
    }
  
    return acc;
  }, {});

  data?.forEach((item) => {
    const consumo = parseFloat(item?.consumption);
    const tipoServicio = item?.utilityType?.toUpperCase();
    const locationName = item?.locationName;

    if (!locationTotals[locationName]) {
      locationTotals[locationName] = {};
    }

    if (tipoServicio === "AGUA") {
      if (consumo !== -1 && !isNaN(consumo) && item?.units !== "N/A") {
        locationTotals[locationName].agua = (locationTotals[locationName].agua || 0) + consumo;
      }
    } else if ((tipoServicio === "GAS" || tipoServicio === "GAS NATURAL") && (item?.class === "Gas Cons. a 9300 Kcal/m3")) {
      if (consumo !== -1 && !isNaN(consumo) && item?.units !== "N/A") {
        locationTotals[locationName].gas = (locationTotals[locationName].gas || 0) + consumo;
      }
    } else if (tipoServicio === "ELECTRICIDAD" && (item?.class === "Energía Consumida" || item?.class === "Energia Consumida")) {
      if (consumo !== -1 && !isNaN(consumo) && item?.units !== "N/A") {
        locationTotals[locationName].electricidad = (locationTotals[locationName].electricidad || 0) + consumo;
      }
    }
  });

  const sortedLocations = Object.entries(locationTotals)?.sort(([, a], [, b]) => {
    const consumoA = a.electricidad || 0;
    const consumoB = b.electricidad || 0;
    return consumoB - consumoA;
  });

  return (
    <div style={{ overflowX: "auto" }}>
      <div style={{ maxHeight: "450px", overflowY: "auto", position: "relative" }}>
        <p className="title-consumpCost">{title}</p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sitio</th>
              <th scope="col">Electricidad (kWh)</th>
              <th scope="col">Gas (m3)</th>
              <th scope="col">Agua (m3)</th>
              <th scope="col">Porcentaje emisión CO2</th>
            </tr>
          </thead>
          <tbody>
            {sortedLocations?.map(([locationName, totals]) => (
              <tr key={locationName}>
                <td>{locationName}</td>
                <td>{(totals.electricidad || totals.electricidad === 0) ? (totals.electricidad)?.toLocaleString('es-AR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  }) : "N/A"}
                </td>
                <td>{(totals.gas || totals.gas === 0) ? (totals.gas)?.toLocaleString('es-AR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  }) : "N/A"}
                </td>
                <td>{(totals.agua || totals.agua === 0) ? (totals.agua)?.toLocaleString('es-AR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : "N/A"}
                </td>
                <td>{(co2Emissions[locationName] || co2Emissions[locationName] === 0) ? `${co2Emissions[locationName].toFixed(2)}%` : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

};

export default TableCorporate;
