import { createSlice } from "@reduxjs/toolkit";

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    supplier: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    supplierStart: (state) => {
      state.isFetching = true;
    },
    supplierSuccess: (state, action) => {
      state.supplier = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    supplierFailure: (state, action) => {
      state.supplier = action.payload;
      state.isFetching = false;
      state.error = true;
    },
    logoutSupplier: (state) => {
      state.supplier = null;
      state.isFetching = false;
      state.error = false;
    },
    deleteStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteSuccess: (state, action) => {
      state.supplier = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    deleteFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutInfosuppliers: (state) => {
      state.supplier = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
   supplierFailure,
   supplierStart,
   supplierSuccess,
   logoutSupplier,
   logoutInfosuppliers,
   deleteFailure,
   deleteStart,
   deleteSuccess
} = supplierSlice.actions;

export default supplierSlice.reducer;
