import React from "react";
import { FaEdit } from "react-icons/fa";
import { useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";

const SupplierOffice = ({ supplierOffice }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.currentUser);

  return (
    <tr className="user-row" id={`supOffice-${supplierOffice.id}`}>
      <td>{supplierOffice.locationName}</td>
      <td>{supplierOffice.supplierName}</td>
      <td>{supplierOffice.supplierService}</td>
      <td>{supplierOffice.frecuenciaFact}</td>
      <td>
        {supplierOffice.numeroCuenta ? supplierOffice.numeroCuenta : "N/A"}
      </td>
      <td>
        {supplierOffice.numeroMedidor ? supplierOffice.numeroMedidor : "N/A"}
      </td>
      <td>{supplierOffice.numeroNis ? supplierOffice.numeroNis : "N/A"}</td>
      <td>
        {supplierOffice.numeroContrato ? supplierOffice.numeroContrato : "N/A"}
      </td>
      <td>
        {supplierOffice.estado === "ACTIVO" ? (
          <p className="data-text enable enable-user">ACTIVO</p>
        ) : (
          <p className="data-text enable disable-user">BAJA</p>
        )}
      </td>
      {user?.privilege === "master" ? (
        <td className="cont-actions">
          <div
            className="btnUser-edit btn-actions"
            onClick={() =>
              navigate(`/supplierOffice/edit/${supplierOffice.id}`)
            }
          >
            <FaEdit />
            <p>Editar</p>
          </div>
        </td>
      ) : null}
    </tr>
  );
};

export default SupplierOffice;
