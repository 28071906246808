import {
    loginFailure,
    loginStart,
    loginSuccess,
    sessionSuccess,
    logoutStart,
    fetchingComplete,
} from "../reducers/loginReducer";
import { publicRequest } from "../../requestMeth";
import { logoutInfoData } from "../reducers/dataReducer";
import { logoutInfousers } from "../reducers/usersReducer";
import { logoutpassPolicies } from "../reducers/passPoliciesReducer";
import { logoutInfoCompanies } from "../reducers/companiesReducer";
import { logoutInfologgers } from "../reducers/loggersReducer";
import { logoutEditUser } from "../reducers/editUserReducer";
import { logoutInfoSupplier } from "../reducers/supplierCompanyReducer";
import { logoutSupplier } from "../reducers/supplierReducer";
import { logoutInfoItemsBill } from "../reducers/itemBills";
import { logoutbillsReport } from "../reducers/billsReportReducer";


export const login = async (dispatch, user) => {
  dispatch(loginStart()); 
  try {
    const res = await publicRequest.post("/auth/users/login", user);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : 'Error desconocido';
    dispatch(loginFailure(errorMessage));
  } finally {
    dispatch(fetchingComplete());
  }
};

export const session = async (dispatch, user, token) => {
  try {
    const res = await publicRequest.get("/auth/users/session", {
      headers: { token: `Bearer ${token}` },
    });
    const local = localStorage.getItem("persist:root");
    let localPars = JSON.parse(local);
    let a = JSON.parse(localPars.user); 
    if (res.data.newToken) {
      a.currentUser = { ...a.currentUser, accessToken: res.data.newToken};
      localPars.user = JSON.stringify(a)
      localStorage.setItem("persist:root", JSON.stringify(localPars));
      dispatch(sessionSuccess(res.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = async (dispatch, token, user) => {
  localStorage.removeItem("persist:root");
  dispatch(logoutStart());
  dispatch(logoutInfoData());
  dispatch(logoutInfousers())
  dispatch(logoutpassPolicies())
  dispatch(logoutInfoCompanies())
  dispatch(logoutInfologgers())
  dispatch(logoutEditUser())
  dispatch(logoutInfoSupplier())
  dispatch(logoutSupplier())
  dispatch(logoutInfoItemsBill())
  dispatch(logoutbillsReport())
  try {
    const res = await publicRequest.post("/auth/users/logout", user, {
      headers: { token: `Bearer ${token}` },
    });

  } catch (error) {
    dispatch(logoutStart());
    console.error(error);
  }
  
};