import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";

const PopupContent = ({
  bills,
  user,
  handleDelete,
  filterOptions,
  companies,
  handleSearch,
  firstOpen,
  setFirstOpen
}) => {
  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [inputValue, setInputValue] = useState("");
  const [filteredBills, setFilteredBills] = useState([]);
  const [firstFilter, setFirstFilter] = useState(true);
  useEffect(() => {
    let billsArray = Array.isArray(bills) ? bills : [bills]; 
  
      let filteredBills = billsArray;
  
      if (user?.privilege === "master") {

        if (selectedUser !== "all") {
          filteredBills = billsArray?.filter((invoice) => invoice?.user === selectedUser);
        }
  
        if (inputValue !== "" && (selectedUser === "all" || inputValue === "all")) {
          filteredBills = billsArray?.filter((invoice) => invoice?.Bill_ID === inputValue);
        }
  
        if (selectedCompany !== "all") {
          filteredBills = filteredBills?.filter((invoice) => invoice?.locationName === selectedCompany);
        }
      }
      
      setFilteredBills(filteredBills);
    
  }, [selectedUser, selectedCompany, inputValue, bills]);
  
  
  const currentDate = new Date();
  const distinctUsers =
    bills?.length > 0 &&
    Array.from(new Set(bills?.map((invoice) => invoice?.user)));

  const handleChangeUser = (selectedValue) => {
    setSelectedUser(selectedValue);
    if(firstFilter){
      setFirstFilter(false)
    }
  };

  const handleSelect = (company) => {
    setSelectedCompany(company);
    if(firstFilter){
      setFirstFilter(false)
    }
  };
  
  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSearch(inputValue);
    }
    if(setFirstOpen){
      setFirstOpen(false)
    }
  };
  
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const cellStyle = {
    padding: "8px",
    textAlign: "left",
    border: "1px solid #dddddd",
    minWidth: "100px", 
  };

  const actionStyle = {
    cursor: "pointer",
  };

  const headerStyle = {
    ...cellStyle,
    backgroundColor: "#343a40",
    color: "white",
  };

  const colorWarning = {
    color: "red",
  };

  return (
    <div>
      <h1>Facturas cargadas</h1>
      <p>
        Fecha:{" "}
        {filterOptions?.startDate
          ? filterOptions?.endDate
            ? `${filterOptions?.startDate} - ${filterOptions?.endDate}`
            : filterOptions?.startDate
          : currentDate.toLocaleDateString()}
      </p>
      {user?.privilege !== "master" ? (
        <p>Cargadas por el usuario: {user?.user}</p>
      ) : (
        <>
          <label>
            Filtrar por usuario:
            <select
              value={selectedUser}
              onChange={(e) => handleChangeUser(e.target.value)}
            >
              <option value="all">Seleccionar usuario</option>
              {distinctUsers?.length > 0 &&
                distinctUsers?.map((user) => (
                  <option key={user} value={user}>
                    {user}
                  </option>
                ))}
            </select>
          </label>
          <label>
            Filtrar por ID de factura:
            <input
              placeholder="Ingrese ID de factura"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} 
              onKeyUp={handleKeyUp}
            />

          </label>
          <label>
            Filtrar por sucursal:
            <select
              value={selectedCompany}
              onChange={(e) => handleSelect(e.target.value)}
            >
              <option value="all">Seleccionar sucursal</option>
              {companies?.length > 0 &&
                companies?.map((c) => (
                  <option key={c.id_company} value={c.name}>
                    {c.name}
                  </option>
                ))}
            </select>
          </label>
        </>
      )}
      {!bills?.length ? (
        <p style={colorWarning}>No se encuentran facturas cargadas.</p>
      ) : null}
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={headerStyle}>Sucursal</th>
            <th style={headerStyle}>Utilidad</th>
            <th style={headerStyle}>Proveedor</th>
            <th style={headerStyle}>Nro cuenta</th>
            <th style={headerStyle}>ID factura</th>
            <th style={headerStyle}>Emisión</th>
            <th style={headerStyle}>Lectura actual</th>
            <th style={headerStyle}>Lectura anterior</th>
            <th style={headerStyle}>Vencimiento</th>
            <th style={headerStyle}>Consumo</th>
            <th style={headerStyle}>Costo</th>
            <th style={headerStyle}>User</th>
            <th style={headerStyle}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {!firstFilter || filteredBills?.length === 1 || (!firstOpen && filteredBills?.length > 0) ?
            filteredBills?.map((invoice, index) => (
              <tr key={`${invoice?.id}-${index}`}>
                <td style={cellStyle}>{invoice?.locationName}</td>
                <td style={cellStyle}>{invoice?.utilityType}</td>
                <td style={cellStyle}>{invoice?.supplier}</td>
                <td style={cellStyle}>{invoice?.AccountNumber}</td>
                <td style={cellStyle}>{invoice?.Bill_ID}</td>
                <td style={cellStyle}>{invoice?.invoiceDate}</td>
                <td style={cellStyle}>{invoice?.readingDate}</td>
                <td style={cellStyle}>{invoice?.previousDate}</td>
                <td style={cellStyle}>{invoice?.dueDate}</td>
                <td style={cellStyle}>
                  {invoice?.consumption === "-1"
                    ? "N/A"
                    : Number(invoice?.consumption).toFixed(2) + " kWh"}
                </td>
                <td style={cellStyle}>
                  {invoice?.cost === "-1"
                    ? "N/A"
                    : "AR$ " + Number(invoice?.cost).toFixed(2)}
                </td>
                <td style={cellStyle}>{invoice?.user}</td>
                <td style={cellStyle}>
                  <FaTrash
                    style={actionStyle}
                    onClick={(e) => handleDelete(e, invoice?.Bill_ID)}
                  />
                </td>
              </tr>
            )): null}
        </tbody>
      </table>
    </div>
  );
};

export default PopupContent;
