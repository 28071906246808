import { publicRequest } from "../../requestMeth";
import { loggersStart, loggersSuccess, loggersFailure,  } from "../reducers/loggersReducer";


export const getLoggers = async (dispatch, token) => {
  dispatch(loggersStart());
  try {
    const res = await publicRequest.get("/master/loggers", {
      headers: { token: `Bearer ${token}` }
    });
    
    dispatch(loggersSuccess(res.data));
  } catch (error) {
    dispatch(loggersFailure());
  }
};