import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2'; 
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: undefined,
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          const value = context?.parsed?.y;
          if (label) {
            label += ': CO2 ';
          }
          label += value?.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return label;
        }
      }
    },
    title: {
      display: true,
      text: 'Comparativa anual de emisiones de CO2',
    },
  },
};

const LineChartWater = () => {
  const generalDataCO2 = useSelector((state) => state.data.generalDataCO2);
  const isFetchingGeneral = useSelector((state) => state.data.isFetchingGeneralData);

  const utilityColors = {
    AGUA: '#92a9c5',
  };

  const dataMemo = useMemo(() => {
    let usagePerMonthCurrentYear = {};
    let usagePerMonthLastYear = {};
    let labels = [];

    if (generalDataCO2 && generalDataCO2.consByMonthCurrentYear && generalDataCO2.consByMonthLastYear) {
      labels = Object.keys(generalDataCO2.consByMonthCurrentYear);

      for (let month in generalDataCO2.consByMonthCurrentYear) {
        usagePerMonthCurrentYear[month] = parseFloat(generalDataCO2.consByMonthCurrentYear[month]);
      }

      for (let month in generalDataCO2.consByMonthLastYear) {
        usagePerMonthLastYear[month] = parseFloat(generalDataCO2.consByMonthLastYear[month]);
      }
    }

    let datasets = [
      {
        label: 'Año actual',
        data: labels.map(month => usagePerMonthCurrentYear[month] || 0),
        borderColor: utilityColors.AGUA,
        backgroundColor: utilityColors.AGUA,
        fill: false,
      },
      {
        label: 'Año pasado',
        data: labels.map(month => usagePerMonthLastYear[month] || 0),
        borderColor: '#ff6384',
        backgroundColor: '#ff6384',
        fill: false,
      },
    ];

    return {
      labels: labels,
      datasets: datasets,
    };
  }, [generalDataCO2]); // data ya no es necesario en las dependencias

  return (
    <div style={{ height: '250px', width: '100%' }}>
      {isFetchingGeneral ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="visually-hidden">Cargando CO2...</span>
          </div>
        </div>
      ) : (
        <Line data={dataMemo} options={options} />
      )}
    </div>
  );
};

export default LineChartWater;
