import { publicRequest } from "../../requestMeth";
import {deleteFailure, deleteStart, deleteSuccess, supplierFailure, supplierStart, supplierSuccess } from "../reducers/supplierReducer"


export const getAllsupplier = async (dispatch, token) => {
  dispatch(supplierStart());
  try {
    const res = await publicRequest.get(`/supplier`, {
      headers: { token: `Bearer ${token}` }
    });
    dispatch(supplierSuccess(res.data))
    return res.data
  } catch (error) {
    dispatch(supplierFailure([]));
  }
};

export const deleteSupplier = async (dispatch, id, token) =>{
  dispatch(deleteStart());
  try {
    const res = await publicRequest.delete(`/supplier/${id}`, {
      headers: { token: `Bearer ${token}` }
  });
      dispatch(deleteSuccess(res.data));
  } catch (error) {
      dispatch(deleteFailure());
  }
};
