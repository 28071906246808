import "./RegisterUser.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { generateRegex } from "../../helpers/Regex";
import { getPassPolicies } from "../../redux/apiCalls/passPoliciesCall";
import { FaArrowLeft, FaEye, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import { createUser } from "../../redux/apiCalls/createUserCall";
import { Toast } from "../../helpers/alert";
import { getAllcompanies } from "../../redux/apiCalls/companiesCall";

const RegisterUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const companies = useSelector((state) => state.companies?.companies);
  const users = useSelector((state) => state.users.users);
  const passPolicies = useSelector((state) => state.passPolicies.passPolicies);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({
    user: "",
    mail: "",
    phone: "",
    contact: "",
    privilege: "",
    enable: "",
    companies: [],
  });
  const uniqueCompanies = [
    ...new Map(companies.map((company) => [company.name, company])).values(),
  ];
  
  useEffect(() => {
    getAllcompanies(dispatch, user.currentUser?.accessToken);
  }, []);

  useEffect(() => {
    getPassPolicies(dispatch, user.currentUser?.accessToken);
  }, []);

  function validar(inputs) {
    let errors = {};
    const passRegex = generateRegex(passPolicies);

    if (!inputs.mail || !/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(inputs.mail)) {
      errors.mail =
        "Se requiere usuario. Debe ser un correo electrónico válido.";
    } else if (!inputs.user) {
      errors.user = "Por favor ingrese un usuario válido";
    } else if (!passRegex.regex.test(inputs.pass)) {
      errors.password = passRegex.messageRegex;
    } else if (!inputs.phone) {
      errors.phone = "Por favor ingrese un teléfono válido.";
    } else if (inputs.privilege === "viewer" && inputs.companies.length === 0)
      errors.companies = "Por favor seleccionar una sucursal.";
    return errors;
  }

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors(
      validar({
        ...inputs,
        [name]: value,
      })
    );
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleCompanySelectChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) =>
      Number(option.value)
    );
    const selectedCompanyNames = new Set(inputs.companies); // Usamos un conjunto para evitar duplicados

    // Agregamos el nombre de la empresa seleccionada
    selectedOptions.forEach((id) => {
      const selectedCompany = companies.find(
        (company) => company.id_company === id
      );
      if (selectedCompany) {
        selectedCompanyNames.add(selectedCompany.name);
      }
    });

    const selectedCompanyIds = [];

    // Buscamos todas las empresas con el mismo nombre y agregamos sus id_company
    companies.forEach((company) => {
      if (selectedCompanyNames.has(company.name)) {
        selectedCompanyIds.push(company.id_company);
      }
    });
    let updateCompanies = inputs.companies;
    if (selectedCompanyIds.length > 1) {
      selectedCompanyIds.forEach((companyId) => {
        if (updateCompanies.includes(companyId)) {
          updateCompanies = updateCompanies.filter((c) => c !== companyId); // si la compañía está, se elimina
        } else {
          updateCompanies.push(companyId);
        }
      });
      // updateCompanies = [...updateCompanies, ...inputs.companies]
    } else {
      updateCompanies = inputs.companies.includes(selectedCompanyIds[0])
        ? inputs.companies.filter((c) => c !== selectedCompanyIds[0]) // si la companie está, se elimina
        : [...inputs.companies, selectedCompanyIds[0]];
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      companies: updateCompanies,
    }));
  };

  const handleSelectAllCompanies = () => {
    const allCompanyIds = companies.map((company) => company.id_company);

    // Si "selectAll" está en inputs.companies, lo excluimos
    const updatedCompanies = inputs.companies.includes("selectAll")
      ? inputs.companies.filter((id) => id !== "selectAll")
      : inputs.companies.filter((id) => !isNaN(id));

    if (updatedCompanies.length === allCompanyIds.length) {
      // Si todas las sucursales están seleccionadas, deselecciónalas
      setInputs((prevInputs) => ({
        ...prevInputs,
        companies: [],
      }));
    } else {
      // Si no todas las sucursales están seleccionadas, selecciona todas
      setInputs((prevInputs) => ({
        ...prevInputs,
        companies: allCompanyIds,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let userExists = users.find(
      (u) => u.mail === inputs.mail || u.user === inputs.user
    );
    let errors = validar(inputs);
    setErrors(errors);
    Swal.fire({
      title: "¿Está seguro de que desea crear un nuevo usuario?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear.",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed && !Object.keys(errors).length && !userExists) {
        createUser(dispatch, inputs, user.currentUser?.accessToken).then(
          (res) => {
            if(res?.error){
              Toast.fire({
                icon: "info",
                title: res?.message
              });
            }else{
              navigate(`/admin/lists?show=users`);
            }
          }
        );
      } else {
        if (userExists) {
          Toast.fire({
            icon: "info",
            title:
              "No se puede crear. El usuario o correo electrónico ya existe.",
          });
        } else {
          Toast.fire({
            icon: "info",
            title: `No se pudo crear. ${Object.values(errors)}`,
          });
        }
      }
    });
  };

  return (
    <div className="registration-container">
      <div className="registration-form">
        <div
          className="cont-back"
          onClick={() => navigate(`/admin/lists?show=users`)}
        >
          <i>
            <FaArrowLeft />
          </i>
          <p>Usuarios</p>
        </div>
        <h2>Registro de Usuario</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="user"
              placeholder="Usuario"
              value={inputs.user}
              onChange={handleChange}
              required
            />
          </div>
          {errors.user && <p className="error-msg">{errors.user}</p>}
          <div className="form-group">
            <input
              type="email"
              name="mail"
              placeholder="Email"
              value={inputs.mail}
              onChange={handleChange}
              required
            />
          </div>
          {errors.mail && <p className="error-msg">{errors.mail}</p>}
          <div className="form-group">
            <input
              className="input_text"
              placeholder="Ingresar contraseña"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              name="pass"
            ></input>
            <button
              style={{ display: "contents", width: "20%" }}
              onClick={toggleShowPassword}
            >
              {showPassword ? (
                <FaEye style={{ marginLeft: "90%", marginTop: "-5%" }} />
              ) : (
                <FaEyeSlash style={{ marginLeft: "90%", marginTop: "-5%" }} />
              )}
            </button>
          </div>
          {errors.password && <p className="error-msg">{errors.password}</p>}
          <div className="form-group">
            <input
              type="phone"
              name="phone"
              placeholder="Telefono"
              value={inputs.phone}
              onChange={handleChange}
              required
            />
          </div>
          {errors.phone && <p className="error-msg">{errors.phone}</p>}
          <div className="form-group">
            <input
              type="contact"
              name="contact"
              placeholder="Contacto"
              value={inputs.contact}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <select
              name="privilege"
              value={inputs.privilege}
              onChange={handleChange}
              required
            >
              <option
                value=""
                disabled
                defaultValue={"Selecciona permisos"}
                hidden
              >
                Selecciona permisos
              </option>
              {user?.currentUser.privilege === "admin" ? (
                <option value="viewer">Viewer</option>
              ) : user?.currentUser.privilege === "master" ? (
                <>
                  <option value="admin">Admin</option>
                  <option value="viewer">Viewer</option>
                  <option value="editor">Editor</option>
                </>
              ) : null}
            </select>
          </div>
          <div className="form-group">
            <select
              name="enable"
              value={inputs.enable}
              onChange={handleChange}
              required
            >
              <option
                value=""
                disabled
                defaultValue={"Selecciona el estado"}
                hidden
              >
                Selecciona el estado
              </option>
              <option value="enable">Habilitado</option>
              <option value="disable">Desabilitado</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="companySelect">Selecciona las empresas:</label>
            <select
              id="companySelect"
              name="company"
              value={inputs.companies}
              onChange={handleCompanySelectChange}
              multiple
            >
              <option value="selectAll" onClick={handleSelectAllCompanies}>
                Seleccionar todas
              </option>
              {uniqueCompanies?.map((company) => (
                <option key={company.id_company} value={company.id_company}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <button type="submit">Crear usuario</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterUser;
