import { publicRequest } from "../../requestMeth"; 
import { supplierFailure, supplierStart, supplierSuccess, updateFailure, updateStart, updateSuccess } from "../reducers/editSupplierReducer";


export const updateSupplier = async (dispatch, id, payload, token) => {
    dispatch(updateStart());
    try {
        const res = await publicRequest.put(`/supplier/edit/${id}`, payload, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(updateSuccess(res.data));
    } catch (error) {
        dispatch(updateFailure({}));
        return error.response.data;
    }
};

export const getSupplier = async (dispatch, id, token) => {
    dispatch(supplierStart());
    try {
        const res = await publicRequest.get(`/supplier/${id}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(supplierSuccess(res.data));
        return res.data;
    } catch (error) {
        dispatch(supplierFailure({}));
        return error.response.data
    }
};