import "./Users.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/apiCalls/usersCall";
import { FaArrowLeft } from "react-icons/fa";
import { getAllsupplier } from "../../redux/apiCalls/supplierCall";
import SelectList from "../../components/List/SelectList/SelectList";
import UsersList from "../../components/List/UsersList";
import OfficesList from "../../components/List/OfficesList";
import SuppliersList from "../../components/List/SuppliersList";
import SupplierOfficeList from "../../components/List/SupplierOfficeList";
import { getAllsupplierCompany } from "../../redux/apiCalls/supplierCompanyCall";
import { getAllItemsBill } from "../../redux/apiCalls/itemBills";
import ItemsBill from "../../components/List/ItemsBill";
import FloatingHelpIcon from "../../components/HelpSidebar/FloatingHelpIcon";

const Lists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const queryType = query.get("show");
  const user = useSelector((state) => state.user.currentUser);
  const users = useSelector((state) => state.users.users);
  const companies = useSelector((state) => state.companies.companies);
  const itemsBill = useSelector((state) => state.itemsBill.itemsBill);
  const suppliers = useSelector((state) => state.supplier.supplier);
  const [tableSelected, setTableSelected] = useState(queryType);

  useEffect(() => {
    if (user.privilege !== "editor") {
      getAllUsers(dispatch, user.accessToken);
    }
  }, [dispatch]);

  useEffect(() => {
    if (user.privilege === "editor" || user.privilege === "master") {
      getAllsupplier(dispatch, user.accessToken);
    }
  }, [dispatch]);

  useEffect(() => {
    if (user.privilege === "editor" || user.privilege === "master") {
        getAllsupplierCompany(dispatch, user.accessToken);
    }
  }, [dispatch]); 



  return (
    <>
      <div className="table-responsive users-list">
        <div
          className="cont-back cont-back-users"
          onClick={() => {
            navigate(`/`);
          }}
        >
          <i>
            <FaArrowLeft />
          </i>
          <p>Escritorio</p>
        </div>
        <SelectList
          tableSelected={tableSelected}
          setTableSelected={setTableSelected}
          user={user}
        />

        {/*aca cada lista*/}
        {tableSelected === "users" ? (
          <UsersList users={users} user={user} />
        ) : tableSelected === "companies" ? (
          <OfficesList companies={companies} user={user} />
        ) : tableSelected === "suppliers" && user.privilege !== "admin" ? (
          <SuppliersList suppliers={suppliers}/>
        ) : tableSelected === "supplierOffice" && user.privilege !== "admin" ? (
          <SupplierOfficeList user={user}/>
        ) : tableSelected === "itemsBill" && user.privilege !== "admin" ? (
          <ItemsBill items={itemsBill}/>
        )
        : null}
      </div>
      <FloatingHelpIcon infoHelp={"lists"}/>
    </>
  );
};

export default Lists;
