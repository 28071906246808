import React from 'react'

const ItemBillInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">Crear concepto</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ol>
          <li>Proveedor: Selecciona el proveedor al cual le asignaras el nuevo concepto</li>
          <li>Clase: Puedes seleccionar un nombre de clase existente o escribir una nueva.</li>
          <li>Concepto: Puedes seleccionar un nombre de concepto existente o escribir un nuevo concepto.</li>
          <li>Unidad de consumo: Acá debes seleccionar la unidad de consumo que tendrá. Si no quieres que pida el dato de consumo, debes dejar marcada la opción "No corresponde consumo".</li>
          <li>Unidad de costo: Acá debes seleccionar la unidad de costo que tendrá. Si no quieres que pida el dato de costo, debes dejar marcada la opción "No corresponde costo".</li>
        </ol>
      </div>
    </div>
  )
}

export default ItemBillInfo