
import { createSlice } from "@reduxjs/toolkit";

const userEditSlice = createSlice({
  name: "userEdit",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false
  },
  reducers: {
    userStart: (state) => {
      state.isFetching = true;
    },
    userSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    userFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //Update
    updateStart: (state) => {
      state.currentUser = null;
      state.isFetching = true;
      state.error = false;
    },
    updateSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload
      state.error = false;
    },
    updateFailure: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = true;
    },
    logoutEditUser: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});


export const { userStart, userSuccess, userFailure, updateStart, updateSuccess, updateFailure, logoutEditUser } = userEditSlice.actions;
export default userEditSlice.reducer;