import { createSlice } from "@reduxjs/toolkit";

const supplierCompanySlice = createSlice({
  name: "supplierCompany",
  initialState: {
    supplier: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    supplierStart: (state) => {
      state.isFetching = true;
    },
    supplierSuccess: (state, action) => {
      state.supplierCompany = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    supplierFailure: (state, action) => {
      state.supplierCompany = action.payload;
      state.isFetching = false;
      state.error = true;
    },
    supplierCompanyStart: (state) => {
      state.isFetching = true;
    },
    supplierCompanySuccess: (state, action) => {
      state.isFetching = false;
      state.supplierCompany = action.payload;
      state.error = false;
    },
    supplierCompanyFailure: (state) => {
      state.currentSupplier = null;
      state.isFetching = false;
      state.error = true;
    },
    editSupplierOfficeSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
    },
    logoutInfoSupplier: (state) => {
      state.supplierCompany = null;
      state.currentSupplier = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  supplierStart,
  supplierCompanyStart,
  supplierCompanyFailure,
  supplierCompanySuccess,
  supplierFailure,
  supplierSuccess,
  editSupplierOfficeSuccess,
  logoutInfoSupplier,
} = supplierCompanySlice.actions;

export default supplierCompanySlice.reducer;
