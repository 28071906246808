import React from 'react'

const ChangePassInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
      <h5 className="offcanvas-title" id="offcanvasRightLabel">Cambio de clave</h5>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <p>
          Aquí podrás cambiar tu contraseña. 
          Primero ingresa tu clave anterior, luego deberás crear una nueva y repetirla para confirmar. 
        </p>
      </div>
    </div>
  )
}

export default ChangePassInfo