import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SupplierOffice from "../Cards/SupplierOffice";
import ProvedoorSucExcel from "../Reports/ReportSupplierOfficesList";

const SupplierOfficeList = ({ user }) => {
  const supplierCompany = useSelector(
    (state) => state.supplierCompany.supplierCompany
  );
  const editedCompanyRef = useRef(null);

  // Suponiendo que recibes el ID del usuario editado desde la URL
  const searchParams = new URLSearchParams(window.location.search);
  const editedSupOfficeId = searchParams.get("editedSupOfficeId");
 
  useEffect(() => {
   if (editedSupOfficeId) {
     const companyElement = document.getElementById(`supOffice-${editedSupOfficeId}`);
     if (companyElement) {
       companyElement.scrollIntoView({ behavior: "smooth", block: "center" });
     }
   }
 }, [editedSupOfficeId]);

  return (
    <>
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">RELACIÓN PROVEEDOR-SUCURSAL</p>
        </div>
        <button className="adduser">
          <ProvedoorSucExcel supplierCompany={supplierCompany} />
        </button>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="user-row">
            <th>SUCURSAL</th>
            <th>PROVEEDOR</th>
            <th>SERVICIO</th>
            <th>FRECUENCIA FACTURACIÓN</th>
            <th>NRO CUENTA</th>
            <th>NRO MEDIDOR</th>
            <th>NRO NIS</th>
            <th>NRO CONTRATO</th>
            <th>ESTADO</th>
            {user?.privilege === "master" ? (<th>ACCIONES</th>) : null}
          </tr>
        </thead>
        <tbody>
          {supplierCompany?.length > 0 && supplierCompany?.map((e) => (
            <SupplierOffice key={e.id} supplierOffice={e} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SupplierOfficeList;
