import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2'; 
import { monthsInOrder, unitsClass } from '../../helpers/infoFilter';
import { normalizeText } from "../../helpers/functions"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const applyLogTransformation = (data) => {
  return data.map(value => Math.log10(value + 1)); // Aplicar logaritmo base 10 a los valores
};

const BarChart = ({data, type, filterClass = []}) => {
  const utilityColors = {
    ELECTRICIDAD: '#616161', // negro,
    "Energía Consumida": "#4D4D4D",
    "Energia Consumida": "#4D4D4D",
    "Energia Reactiva": "#535353",
    "Energía Reactiva": "#535353",
    "Potencia Excedida":"#595959",
    "Potencia Contratada":"#717171",
    "Potencia Demandada":"#7D7D7D",
    "Energia Inyectada":"#5F5F5F",
    "Potencia Renovable":"#756e6e",
    "Energia Renovable":"#454242",
    "Energia Autogenerada": "#272525",
    "Coseno fi":"#656565",
    "Tangente fi":"#6B6B6B",
    "GAS NATURAL": '#eaea59ed', // amarillo
    "Gas Cons. a 9300 Kcal/m3": "#eaea59ed",
    "Gas Consumido": "#eaea89ed",
    "Agua Consumida": "#63b670",
    AGUA: '#63b670', // verde
  };

  const dataMemo = useMemo(() => {
    let usagePerMonth = {};
    let labels = [];
    let allUtilities = new Set();
    if (Array.isArray(data)) {
      if(type === "finance"){
        data?.forEach((item) => {
          if(item?.lineItemText !== "Total Factura"){
            let cost = parseFloat(item?.cost);
            if (!isNaN(cost)) {
              let date = new Date(Date.parse(item?.dueDate));
              let monthYear = date.toLocaleString('es-ES', { month: 'long' }) + '-' + date?.getFullYear();
              if (!usagePerMonth[monthYear]) {
                usagePerMonth[monthYear] = {ELECTRICIDAD: 0, "GAS NATURAL": 0, AGUA: 0};
                labels.push(monthYear);
              }
              if (item?.utilityType in usagePerMonth[monthYear]) {
                usagePerMonth[monthYear][item.utilityType] += cost;
                allUtilities.add(item?.utilityType);
              }
            }
          }
        });
      } else{
        if(type === "consumption" && filterClass.length > 0){
          data?.forEach((item) => {
            if (item?.lineItemText !== "Total Factura") {
              let consumption = parseFloat(item?.consumption);
              if (!isNaN(consumption)) {
                let date = new Date(Date.parse(item?.dueDate));
                let monthYear = date.toLocaleString('es-ES', { month: 'long' }) + '-' + date?.getFullYear();
                if (!usagePerMonth[monthYear]) {
                  usagePerMonth[monthYear] = {};
                  labels.push(monthYear);
                }
                let classType = normalizeText(item?.class);
                if (!usagePerMonth[monthYear][classType]) {
                  usagePerMonth[monthYear][classType] = 0;
                }
                usagePerMonth[monthYear][classType] += consumption;
                allUtilities.add(classType);
              }
            }
          });
        }else{
          data?.forEach((item) => {
            if(item?.lineItemText !== "Total Factura"){
              let consumption = parseFloat(item?.consumption);
              if (!isNaN(consumption)) {
                let date = new Date(Date.parse(item?.dueDate));
                let monthYear = date.toLocaleString('es-ES', { month: 'long' }) + '-' + date?.getFullYear();
                if (!usagePerMonth[monthYear]) {
                  usagePerMonth[monthYear] = {ELECTRICIDAD: 0, "GAS NATURAL": 0, AGUA: 0};
                  labels.push(monthYear);
                }
                if (item?.utilityType in usagePerMonth[monthYear]) {
                  usagePerMonth[monthYear][item.utilityType] += consumption;
                  allUtilities.add(item?.utilityType);
                }
              }
            }
          });
        }
      }
    }
    labels?.sort((a, b) => {
      const [monthA, yearA] = a.split('-');
      const [monthB, yearB] = b.split('-');
      if (yearA !== yearB) {
        return yearA - yearB;
      } else {
        return monthsInOrder.indexOf(monthA) - monthsInOrder.indexOf(monthB);
      }
    });
    
    labels?.forEach(monthYear => {
      for (let utility in usagePerMonth[monthYear]) {
        if (usagePerMonth[monthYear][utility] === undefined) {
          usagePerMonth[monthYear][utility] = 0;
        }
      }
    });

    let datasets = [];
    allUtilities?.forEach(utility => {
      let data = labels?.map(monthYear => usagePerMonth[monthYear][utility]);
      data = applyLogTransformation(data); // Aplica la transformación logarítmica
      datasets.push({
        label: type === "finance" ?  utility + ' AR$' : utility,
        data: data,
        tension: 0.3,
        borderColor: utilityColors[utility],
        backgroundColor: utilityColors[utility],
      });
    });
    if(type === "consumption" && filterClass?.length > 0){
      const order = ["Gas Consumido", "Gas Cons. a 9300 Kcal/m3", "Agua Consumida"];
      datasets.sort((a, b) => {
        const indexA = order.indexOf(a.label);
        const indexB = order.indexOf(b.label);
        if (indexA === -1 && indexB === -1) {
          return 0; // Ambos elementos no están en el orden, mantener su orden actual
        } else if (indexA === -1) {
          return 1; // 'a' no está en el orden, ponerlo después de 'b'
        } else if (indexB === -1) {
          return -1; // 'b' no está en el orden, ponerlo después de 'a'
        } else {
          return indexA - indexB; // Ambos están en el orden, comparar sus índices
        }
      });
      
    }
    
    return {
      labels: labels,
      datasets: datasets,
    };
  }, [data]);
  
  const options = {
    responsive: true,
  maintainAspectRatio: false,
  aspectRatio: undefined,
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
      ticks: {
        // Personaliza la generación de etiquetas en el eje y para mostrar valores reales
        callback: function (value) {
          return (Math.pow(10, value))?.toLocaleString('es-AR', {maximumFractionDigits: 2}); // Muestra el valor real
        }

      }
    },
  },
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: type === "finance" ? 'Gastos de Electricidad, Agua y Gas' : "Consumos de Electricidad, Agua y Gas" ,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          const unit = (label === "GAS NATURAL" || label === "AGUA") ? "m3" : (label === "ELECTRICIDAD" && filterClass?.length === 0) ? "kWh" : unitsClass[label] || '';
          if (label) {
            label = type === "finance" ? `${label}: ` : `Consumo de ${label}: `;
          }
          const originalValue = Math.pow(10, context.parsed.y) - 1;
          const formattedValue = originalValue?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          label = `${label} ${formattedValue} ${type === "consumption" ? unit : ""}`
          return label;
        }
      }
    }
  },
};


  return (
    <div style={{ height: '250px', width: '100%' }}>
        <Bar data={dataMemo} options={options} />
    </div>
  );
};

export default BarChart;
