import { utils, writeFile } from 'xlsx';
import { useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";

export default function DownloadExcel() {
  const totalInvoices = useSelector((state) => state.data.anualData?.totalInvoices);

  const downloadExcel = () => {
    if (totalInvoices && Array.isArray(totalInvoices)) {
      const wb = utils.book_new();
      let date;
      const headerDates = [];
    
      totalInvoices.forEach((monthData) => {
        const data = monthData[Object.keys(monthData)[0]];
        if (Array.isArray(data)) {
          data.forEach(item => {
            date = item.mes + " " + item.año;
            if (!headerDates.includes(date)) {
              headerDates.push(date);
            } 
          });
        }
      });
    
      // Agregar "Fechas de filtrado" al principio de headerDates
      headerDates.unshift(`Fechas: ${headerDates[0]} - ${headerDates[headerDates.length - 1]}`);

      const headerColumns = ["nombre del sitio", "ciudad", "pais", "Fact. Esperadas", "Fact. Cargadas", "%", "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%", 
        "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%",  "Fact. Esperadas", "Fact. Cargadas", "%"];
  
      const headerColumnsWithGaps = headerDates.reduce((acc, date) => [...acc, '', ''], []);
      const ws = utils.aoa_to_sheet([['', '', ''].concat(headerColumnsWithGaps), []]);
      
        ws["!cols"] = [
          { width: 30 },
          { width: 25 },
          { width: 25 },
          { width: 25 },
          ...Array(headerDates.length * 3 - 1).fill({ width: 10 }),
          { width: 25 },
          { width: 25 },
          { width: 25 },
        ];

      let currentColumn = 0;


      ws["!cols"] = [{ width: 30 }, ...Array(headerColumns.length - 1).fill({ width: 10 })];


      headerColumns.forEach((column, index) => {
        ws[utils.encode_cell({ r: 1, c: currentColumn + index })] = { v: column };
      });

      headerDates.forEach((date, index) => {
        const cell = utils.encode_cell({ r: 0, c: currentColumn + index * 3});
        ws[cell] = { v: date };
      });

      let headersAdded = false;

      totalInvoices.forEach((monthData, index) => {
        const month = Object.keys(monthData)[0];
        const data = monthData[month];
        if (Array.isArray(data)) {
          const dataAsArray = data.map((item) => [
            headersAdded ? null : item["nombre del sitio"],
            headersAdded ? null : item.ciudad,
            headersAdded ? null : "Argentina",
            item.exp,
            item.act,
            item["%"],
          ]);
          const filteredData = dataAsArray.map(row => row.filter(value => value !== null && value !== ''));

          headersAdded = true;

          utils.sheet_add_aoa(ws, filteredData, { origin: { r: 2, c: currentColumn } });

          currentColumn += filteredData[0].length;
        }
      });

      utils.book_append_sheet(wb, ws, "Completitud Facturas");
      writeFile(wb, `CompletitudFacturas_${headerDates[0]} - ${headerDates[headerDates.length - 1]}.xlsx`);
    }
  };

  return (
    <>
      <div style={{ display: "contents", fontWeight: "bold" }} onClick={downloadExcel}>
        <FaFileDownload /> Completitud de cargas facturas
      </div>
    </>
  );
}
