import React, { useEffect, useState } from 'react';
import Tesseract from 'tesseract.js';
import stringSimilarity from 'string-similarity';


const Uploaded = ({onDataExtracted, item}) => {
    const [textFromImage, setTextFromImage] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const lineItemTexts = item.map(item => item.lineItemText);
    const [loading, setLoading] = useState(false); // Added loading state
    
let cargoName = '';
let cargoValue = 0;
let cargoImporte = 0;
let consolidatedData = [];
let billIdArray = [];

const handleFileUpload = (e) => {
  const selectedFile = e.target.files[0] ? e.target.files[0] : "";
  
  // Validar el tipo de archivo
  if (selectedFile && selectedFile.type === 'image/jpeg') {
    setLoading(true); 
    setUploadedFileName(selectedFile.name);
    processImage(selectedFile, selectedFile.name); 
  } else {
    alert("Por favor, sube un archivo con formato JPG.");
  }
};


const processImage = async (file, filename) => {
  const reader = new FileReader();

  reader.onloadend = async () => {
    const dataUrl = reader.result;

    Tesseract.recognize(dataUrl, 'spa').then(({ data: { text } }) => {
      setTextFromImage(text);
      extractInformation(text);
    });
  };

  reader.readAsDataURL(file);
};

const extractInformation = (text, lineItemTexts) => {
  const lines = text.split('\n');

  for (let i = 0; i < lines.length; i++) {
    const sanitizedLine = lines[i].replace(/[|–<>()]/g, '');
    const parts = sanitizedLine.split(/\s+/).filter(Boolean);

    if (parts.length >= 3) {
      cargoName = parts.join(' ').trim();
      cargoValue = parts[parts.length - 3].replace(/[,]/g, '') || 0;
      cargoImporte = parts[parts.length - 1].replace(/[,]/g, '') || 0;
    }

    if (lineItemTexts && Array.isArray(lineItemTexts)) {
      const matchingLineItems = getMatchingSubstrings(
        cargoName.toLowerCase(),
        lineItemTexts.map(item => item.toLowerCase())
      );

      if (matchingLineItems.length > 0) {
        const matchingLineItem = findBestMatchingLineItem(cargoName.toLowerCase(), matchingLineItems);

        const existingEntryIndex = consolidatedData.findIndex(entry => entry.Nombre === matchingLineItem);
        if (existingEntryIndex === -1) {
          const parsedValue = parseFloat(cargoValue) || 0;
          const parsedImporte = parseFloat(cargoImporte) || 0;

          // Verificar que tanto el valor como el importe no sean " " antes de agregar la entrada
          if (parsedValue !== 0 || parsedImporte !== 0) {
            consolidatedData.push({
              lineItemText: matchingLineItem,
              consumption: parsedValue,
              cost: parsedImporte,
            });

            if (consolidatedData.length === 0) {
              billIdArray.push([""]);
            } else if (billIdArray.length === 0) {
              billIdArray.push(uploadedFileName.slice(0, -4));
            }
          }
        }
      }
    }
  }

  onDataExtracted(consolidatedData, billIdArray[0]);
  setLoading(false);
};

const findBestMatchingLineItem = (cargoName, matchingLineItems) => {
  let bestMatch = matchingLineItems[0];
  let bestSimilarity = 0;

  matchingLineItems.forEach(lineItem => {
    const similarity = calculateTokenSimilarity(cargoName, lineItem);
    if (similarity > bestSimilarity) {
      bestMatch = lineItem;
      bestSimilarity = similarity;
    }
  });

  return bestMatch;
};

const calculateTokenSimilarity = (a, b) => {
  const tokensA = a.toLowerCase().split(/\s+/).filter(Boolean);
  const tokensB = b.toLowerCase().split(/\s+/).filter(Boolean);

  // Lógica para calcular la similitud entre tokens
  const intersection = tokensA.filter(token => tokensB.includes(token));
  const similarity = intersection.length / Math.max(tokensA.length, tokensB.length);
  return similarity;
};

const getMatchingSubstrings = (a, bArray) => {
  const matchingSubstrings = [];

  if (typeof a !== 'string' || !Array.isArray(bArray)) {
    return matchingSubstrings;
  }

  const normalizedA = a.toLowerCase();

  bArray.forEach((b) => {
    if (typeof b !== 'string') {
      return;
    }

    const normalizedB = b;
    const similarity = stringSimilarity.compareTwoStrings(normalizedA, normalizedB);

    if (similarity > 0.3) { 
      matchingSubstrings.push(b);
    }
  });
  
  return matchingSubstrings;
};


useEffect(() => {
  extractInformation(textFromImage, lineItemTexts);
  }, [textFromImage]);
  
  

  return (
    <div>
    <input
      type="file"
      accept="image/*"
      onChange={(e) => handleFileUpload(e)}
      style={{width: "7.5%"}}
    />
   
      {loading ? (
        <div className="loading-spinner">Autocompletando factura...</div>
      ) : (
       ""
      )}
    </div>
  );
};

export default Uploaded;