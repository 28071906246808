import { createSlice } from "@reduxjs/toolkit";

const createSupplierSlice = createSlice({
  name: "supplierCreate",
  initialState: {
    currentSupplier: null,
    isFetching: false,
    error: false
  },
  reducers: {
    createSupplierStart: (state) => {
      state.isFetching = true;
    },
    createSupplierSuccess: (state, action) => {
        state.isFetching = false;
        state.currentSupplier = action.payload
        state.error = false;
    },
    createSupplierFailure: (state) => {
        state.currentSupplier = null;
        state.isFetching = false;
        state.error = true;
    }
  },
});


export const { createSupplierStart, createSupplierSuccess, createSupplierFailure } = createSupplierSlice.actions;
export default createSupplierSlice.reducer;