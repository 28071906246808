// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemText-thead {
  border-color: #cfcfcf;
}

.itemText-row {
  border: 1px solid #cfcfcf;
}

.itemText-row th {
  color: #73879c;
  font-size: 15px;
  font-weight: 600;
}

.itemText-row td {
  font-size: 14px;
}

.itemColumn {
  display: flex;
  align-items: center;
}

.itemColumn input{
  width: 60%;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 0;
}

.info-price{
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.info-price p{
  font-weight: bolder;
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 0;
}

.btn-charges{
  background-color: transparent;
  color: #537293;
  padding: 0; 
  margin-bottom: 2%;
  font-weight: 600;
}

.btn-charges:hover{
  background-color: transparent;
  color: #5a8fc7;

}

`, "",{"version":3,"sources":["webpack://./src/components/UploadBill/LineItemText.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;;AAEhB","sourcesContent":[".itemText-thead {\n  border-color: #cfcfcf;\n}\n\n.itemText-row {\n  border: 1px solid #cfcfcf;\n}\n\n.itemText-row th {\n  color: #73879c;\n  font-size: 15px;\n  font-weight: 600;\n}\n\n.itemText-row td {\n  font-size: 14px;\n}\n\n.itemColumn {\n  display: flex;\n  align-items: center;\n}\n\n.itemColumn input{\n  width: 60%;\n  margin-right: 10px;\n  border: 1px solid black;\n  border-radius: 0;\n}\n\n.info-price{\n  display: flex;\n  align-items: center;\n  margin-bottom: 1em;\n}\n\n.info-price p{\n  font-weight: bolder;\n  font-size: 20px;\n  margin-left: 5px;\n  margin-bottom: 0;\n}\n\n.btn-charges{\n  background-color: transparent;\n  color: #537293;\n  padding: 0; \n  margin-bottom: 2%;\n  font-weight: 600;\n}\n\n.btn-charges:hover{\n  background-color: transparent;\n  color: #5a8fc7;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
