import "./DashSustainability.css";
import React, { useEffect, useState } from "react";
import DashGrid from "./DashGrid";
import { FaBars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DownloadExcel from "../../Reports/ReportsExcel";
import SostentabilidadExcel from "../../Reports/ReportSostentabilidad";
import { getAllcompanies } from "../../../redux/apiCalls/companiesCall";
import { provinces } from "../../../helpers/infoFilter";
import ReportSostentabilidadPdf from "../../Reports/ReportSostentabilidadPdf";

const sumConsumptionsByLocation = (dataWater, offices) => {
  const consumptionByLocationAndUtility = {};

  // Función para armar la estructura del array, cada sucursal es un objeto
  dataWater?.forEach((item) => {
    const { locationName, consumption, utilityType } = item;
    const consumptionValue = Number(consumption);

    if (!isNaN(consumptionValue) && locationName && utilityType) {
      if (!consumptionByLocationAndUtility[locationName]) {
        consumptionByLocationAndUtility[locationName] = {};
      }
      
      if (!consumptionByLocationAndUtility[locationName][utilityType]) {
        consumptionByLocationAndUtility[locationName][utilityType] = 0;
      }

      consumptionByLocationAndUtility[locationName][utilityType] += consumptionValue;
    }
  });

  // Mapeo de los datos para agregar dirección, provincia y unidad según la ubicación
  const allConsumption = Object.entries(consumptionByLocationAndUtility).flatMap(
    ([locationName, utilityData]) => {
      const officeFound = offices?.find((o) => o.name === locationName);

      return Object.entries(utilityData).map(([utilityType, consumption]) => {
        const itemFound = dataWater?.find((item) => item?.locationName === locationName && item.utilityType === utilityType);
        const units = itemFound?.units?.toUpperCase()?.trim();

        return {
          locationName,
          utilityType,
          consumption,
          class: itemFound?.class,
          units: (units === "KWH" || units === "M3") ? units : null,
          medicion: itemFound?.utilityType || null, // Agrega la unidad a partir de data o usa una cadena vacía si no está presente
          address: officeFound ? officeFound?.address : null, // Agrega la dirección si se encuentra la oficina
          province: officeFound ? officeFound?.state : null, // Agrega la provincia si se encuentra la oficina
        };
      });
    }
  );

  return allConsumption;
};


const DashSustainability = ({ data, filterOptions, filterClass, co2 }) => {
  const offices = useSelector((state) => state.companies.companies);
  const dataFetching = useSelector((state) => state.data.isFetching);
  const co2Fetching = useSelector((state) => state.data.isFetchingCo2);
  const co2dataFetching = useSelector((state) => state.data.co2Data);
  const [waterConsumption, setWaterConsumption] = useState([]);
  const generalDataCO2 = useSelector((state) => state.data.generalDataCO2);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const consumoSostentabilidad = {};
  const nameProvinces = filterOptions?.provinces
    .map((value) => {
      const province = provinces?.find((p) => p?.value === value);
      return province ? province?.name : value;
    })
    .join(", ");

    // Obtener el mes y año actual
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Obtener el mes y año hace un año
    const lastYearDate = new Date(currentDate);
    lastYearDate.setFullYear(currentYear - 1);
    const lastYearMonth = 0; // Comienza desde enero del año pasado
    const lastYearYear = lastYearDate.getFullYear();
    
    // Función para obtener el nombre del mes a partir de su número
    const getMonthName = (monthNumber) => {
      const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];
      return months[monthNumber];
    };
    
    const monthsToFetch = [];
    for (let year = lastYearYear; year <= currentYear; year++) {
      const startMonth = (year === lastYearYear) ? lastYearMonth : 0;
      const endMonth = (year === currentYear) ? currentMonth : 11;
    
      for (let month = startMonth; month <= endMonth; month++) {
        monthsToFetch.push({ year, month });
      }
    }
    
    monthsToFetch.forEach(({ year, month }) => {
      const monthName = getMonthName(month);
      const monthDataKey = `${monthName} ${year}`;
      const monthData = co2 && co2.groupedByMonth ? co2.groupedByMonth[monthDataKey] : undefined;
    
      // Inicializar consumoSostentabilidad[monthDataKey] si no está definido
      if (!consumoSostentabilidad[monthDataKey]) {
        consumoSostentabilidad[monthDataKey] = [];
      }
    
      // Agregar datos existentes para el mes actual
      if (monthData && monthData?.length > 0) {
        monthData.forEach(data => {
          consumoSostentabilidad[monthDataKey].push({
            office: data?.office,
            consumoTotalMes: parseFloat(data?.consumoTotalMes),
            mesProrrateado: data?.mesProrrateado,
            unidad: data?.unidad || "",
            tiposConsumo: data?.tiposConsumo || [],
            days: data?.diasCalculados
          });
        });
      }
    
      offices?.forEach(office => {
        if (!consumoSostentabilidad[monthDataKey].some(item => item?.office === office?.name)) {
          consumoSostentabilidad[monthDataKey].push({
            office: office?.name,
            consumoTotalMes: 0.00,
            mesProrrateado: monthDataKey,
            unidad: "",
            tiposConsumo: [],
          });
        }
      });
    });

  useEffect(() => {
    getAllcompanies(dispatch, user?.accessToken);
  }, [dispatch]);


  useEffect(() => {
    if (data && data?.length > 0) {
      const dataWater = data?.filter((d) => {
        return (
          (d?.class === "Energia Consumida" ||
           d?.class === "Gas Cons. a 9300 Kcal/m3"  ||
           d?.class === "Agua Consumida") &&
           d?.consumption > 0
        );
      });

      const waterOffice = sumConsumptionsByLocation(dataWater, offices);
      // Calcular el CO2 y agregarlo a waterOffice
      const waterWithCO2 = waterOffice?.map((office) => {
        let multiplicador = 1; // Valor predeterminado
        if (office.medicion === "ELECTRICIDAD"  && office?.class === "Energia Consumida" ) {
          multiplicador = 0.31; // Factor de conversión para CO2 en electricidad (kg CO2/kWh)
        } else if (office.medicion === "AGUA"  && office?.class === "Agua Consumida") {
          multiplicador = 0.12132; // Factor de conversión para CO2 en agua
        } else if (office.medicion === "GAS NATURAL"  && office?.class === "Gas Cons. a 9300 Kcal/m3") {
          multiplicador = 2.17; // Factor de conversión para CO2 en gas
        }
        let CO2 = office?.consumption * multiplicador;
        CO2 = CO2 / 1000000
        return { ...office, CO2 };
      });
      setWaterConsumption(waterWithCO2);
    }
  }, [data, filterOptions]);

  return (
    <>
      {dataFetching && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: "9999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              className="spinner-border"
              role="status"
              style={{ width: "4rem", height: "4rem" }}
            >
              <span className="visually-hidden">Cargando...</span>
            </div>
            <p style={{ marginTop: "10px" }}>Cargando...</p>
          </div>
        </div>
      )}
      <div
        className="template-bar"
        style={{ opacity: dataFetching ? "0.5" : "1" }}
      >
        <div className="template-title">
          <p className="title">REPORTE DE SOSTENIBILIDAD</p>
          <ul className="cont-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                REPORTES
                <FaBars />
              </a>
              <ul className="dropdown-menu" aria-label="Board">
                <li>
                {co2Fetching || !co2dataFetching? (
                <div>
                      <button className="btn-reportDisable" disabled>Generando reporte prorrateo...</button> 
                      </div>
                  ) : (
                    <div>
                        <SostentabilidadExcel data={consumoSostentabilidad} filter={filterOptions?.companies !== undefined ? filterOptions?.companies : []} />
                      </div>
                  )}
                </li>
                <li style={{ width: "110%" }}>
                  <button>
                    <DownloadExcel date={filterOptions} />
                  </button>
                </li>
                <li>
                  <button>
                    <ReportSostentabilidadPdf filterOptions={filterOptions} />
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="template-filters">
          <p>
            {filterOptions?.startDate || filterOptions?.endDate
              ? `Fecha: ${
                  filterOptions?.startDate
                    ? new Date(
                        new Date(filterOptions?.startDate).getTime() +
                          24 * 60 * 60 * 1000
                      )
                        .toLocaleDateString("es-ES", {
                          month: "long",
                          year: "numeric",
                        })
                        .replace(
                          /\b\w{3}\b/g,
                          (match) =>
                            match.charAt(0).toUpperCase() + match.slice(1)
                        )
                    : " "
                } - ${
                  filterOptions?.endDate
                    ? new Date(
                        new Date(filterOptions?.endDate).getTime() -
                          24 * 60 * 60 * 1000
                      )
                        .toLocaleDateString("es-ES", {
                          month: "long",
                          year: "numeric",
                        })
                        .replace(
                          /\b\w{3}\b/g,
                          (match) =>
                            match.charAt(0).toUpperCase() + match.slice(1)
                        )
                    : " "
                }`
              : ""}
          </p>
          <p>
            {filterOptions?.provinces?.length > 0
              ? `Provincias: ${nameProvinces}`
              : null}
          </p>
          <p>
            {filterOptions?.companies?.length > 0
              ? `Sucursales: ${filterOptions?.companies}`
              : null}
          </p>
          <p>
            {filterOptions?.utilities?.length > 0
              ? `Servicio: ${filterOptions?.utilities}`
              : null}
          </p>
          <p>
            {filterClass?.class?.length > 0
              ? `Conceptos: ${filterClass?.class}`
              : null}
          </p>
        </div>
      </div>

      <DashGrid
        data = {data}
        waterConsumption={waterConsumption}
        filterOptions={filterOptions}
        generalDataCO2={generalDataCO2}
      />
      <div />
    </>
  );
};

export default DashSustainability;