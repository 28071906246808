import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "./helpers/alert";
import { ThemeContext } from "./context/ThemeContext";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Footer from "./components/Footer/Footer";
import { logoutUser, session } from "./redux/apiCalls/loginCall";
import Topbar from "./components/Topbar/Topbar";
import RegisterUser from "./pages/Register/RegisterUser";
import EditUserAdmin from "./pages/EditUser/EditUserAdmin";
import ConfigPassword from "./pages/Password/ConfigPassword";
import UpdatePassword from "./pages/Password/UpdatePassword";
import Profile from "./pages/Profile/Profile";
import NotFound from "./pages/NotFound/NotFound";
import UserActivity from "./pages/UserActivity/UserActivity";
import EditOffice from "./pages/EditOffice/EditOffice";
import RegisterCompany from "./pages/Register/RegisterCompany";
import AssignSupplierCompany from "./pages/SupplierCompany/AssignSupplierCompany";
import UploadBill from "./pages/UploadBill/UploadBill";
import RegisterSupplier from "./pages/Register/RegisterSupplier";
import EditSupplier from "./pages/EditSupplier/EditSupplier";
import Lists from "./pages/Users/Lists";
import RegisterBill from "./pages/Register/RegisterBill";
import EditItemBill from "./pages/EditItemBill/EditItemBill";
import EditSupplierOffice from "./pages/EditSupplierOffice/EditSupplierOffice";
import ChangePass from "./pages/Profile/ChangePass";
import Audit from "./pages/Audit/Audit";

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [lastClickTime, setLastClickTime] = useState(Date.now());

  useEffect(() => {
    const handleClick = () => {
      setLastClickTime(Date.now());
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const currentTimeT = Date.now(); //traigo tiempo actual
    const currentTime = Math.floor(Date.now() / 1000); // tiempo actual formato unix
    const timeDifference = currentTimeT - lastClickTime; // al tiempo actual le resto el tiempo que hice el ultimo click
    const rest = user.currentUser?.exp - currentTime; // nada
    if (rest <= 0 || timeDifference >= 10 * 60 * 1000) {
      logoutUser(dispatch,user.currentUser?.accessToken, {user:user.currentUser?.user});
      navigate("/login");
      Toast.fire({
        icon: "info",
        title:
          "Por razones de seguridad, su sesión ha sido cerrada. Por favor Iniciar sesión.",
      });
    } else if (rest < 540 && user.currentUser?.exp - currentTime > 1) {
      session(dispatch, user, user.currentUser?.accessToken);
    }
  }, [lastClickTime]);

  useEffect(() => {
    if (!user.currentUser?.accessToken && !user.currentUser) {
      // si no haytoken o user, redirecciono también
      navigate("/login");
    } else if (user.currentUser?.passwordExpired === true) {
      navigate("/expired-password");
    }
  }, [user.currentUser]);

  let adminEditorOrMaster = false;
  let adminOrMaster = false;
  let masterOrEditor = false;
  let isMaster = false;


  if (
    user.currentUser?.privilege === "admin" ||
    user.currentUser?.privilege === "master" ||
    user.currentUser?.privilege === "editor"
  ) {
    adminEditorOrMaster = true;
  }

  if (
    user.currentUser?.privilege === "editor" ||
    user.currentUser?.privilege === "master"
  ) {
    masterOrEditor = true;
  }
  
  if (
    user.currentUser?.privilege === "admin" ||
    user.currentUser?.privilege === "master"
    ) {
      adminOrMaster = true;
    }
    
    if (
      user.currentUser?.privilege === "master" 
    ) {
      isMaster = true;
    }

    window?.addEventListener('error', (event) => {
      // console.error('Global error caught: ', event?.message);
      Toast.fire({
        icon: "info",
        title:
          "Ocurrió un error. Por favor, recargue la página.",
        timer: 3000, 
      });
    });
    
    window?.addEventListener('unhandledrejection', (event) => {
      // console.error('Unhandled rejection: ', event?.reason);
      Toast.fire({
        icon: "info",
        title:
          "Ocurrió un error. Por favor, recargue la página.",
        timer: 3000,
      });
    });
    

  return (
    <div className={`container-app app-${theme}`}>
      <Topbar toggleTheme={toggleTheme} theme={theme} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/expired-password" element={<UpdatePassword />} />
        <Route
          path="/"
          element={
            user.currentUser?.accessToken ? (
              <Outlet />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
          <Route index element={<Home />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/pass/:id" element={<ChangePass />} />
          <Route path="/admin/audit" element={<Audit/>} />
          {adminEditorOrMaster && (
            <>
            <Route path="/admin/lists" element={<Lists />}/>
            <Route path="/company/register" element={<RegisterCompany />} />
            <Route path="/branchoffice/:id" element={<EditOffice />} />
            </>
          ) }
          {adminOrMaster && (
            <>
            <Route path="/users/register" element={<RegisterUser />} />
            <Route path="/users/edit/:id" element={<EditUserAdmin />} />
            <Route path="/users/activity" element={<UserActivity />} />
            <Route path="/users/configPass" element={<ConfigPassword />} />
            </>
          )}
          {masterOrEditor && (
            <>
            <Route  path="/supplier/register" element={<RegisterSupplier />} />
            <Route  path="/itemBill/register" element={<RegisterBill />} />
            <Route path="/uploadBill" element={<UploadBill />} />
            <Route  path="/supplierCompany/:idcompany" element={<AssignSupplierCompany />} />
            <Route  path="/supplier/:id" element={<EditSupplier />} />
            <Route path="/itemBill/edit/:id" element={<EditItemBill />} />
            </>
          )}
          {isMaster && (
            <Route path="/supplierOffice/edit/:id" element={<EditSupplierOffice />} />
          )}

        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;