import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditUser from "../../components/EditUser/EditUser";
import { getUser } from "../../redux/apiCalls/editUserCall";
import { getAllcompanies } from "../../redux/apiCalls/companiesCall";
import { Toast } from "../../helpers/alert";

const EditUserAdmin = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.user.currentUser);
  const userEdit = useSelector((state) => state.userEdit);

  useEffect(() => {
    getUser(dispatch, id, user?.accessToken).then((res) => {
      if (res?.error) {
        Toast.fire({
          icon: "info",
          title: res?.message,
        });
      }
    });
    getAllcompanies(dispatch, user?.accessToken);
  }, []);

  return (
    <>
      <EditUser userEdit={userEdit.currentUser} userLog={user} />
    </>
  );
};

export default EditUserAdmin;
