import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import {
  createItemBill,
  getAllItemsBill,
} from "../../redux/apiCalls/itemBills";
import CreatableSelect from "react-select/creatable";
import FloatingHelpIcon from "../../components/HelpSidebar/FloatingHelpIcon";

const RegisterBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const suppliers = useSelector((state) => state.supplier.supplier);
  const itemsBill = useSelector((state) => state.itemsBill.itemsBill);
  const [errors, setErrors] = useState({});
  const [classes, setClasses] = useState([]); // Array para almacenar clases
  const [concepts, setConcepts] = useState([]); // Array para almacenar conceptos
  const [units, setUnits] = useState([]);
  const [inputs, setInputs] = useState({
    proveedor: "",
    class: "",
    lineItemText: "",
    unit: "",
  });

  let sortedSuppliers = suppliers
    ? [...suppliers].sort((a, b) => a.nombre.localeCompare(b.nombre))
    : [];

  useEffect(() => {
    getAllItemsBill(dispatch, user?.accessToken);
  }, [dispatch]);

  useEffect(() => {
    const uniqueClasses = [...new Set(itemsBill.map((item) => item.class))];
    setClasses(uniqueClasses);

    const uniqueConcepts = [
      ...new Set(itemsBill.map((item) => item.lineItemText)),
    ];
    setConcepts(uniqueConcepts);

    const uniqueUnits = [
      ...new Set(
        itemsBill
          ?.map((item) => item.consumptionUnit)
          .filter((unit) => unit !== null && unit !== "null")
      ),
    ].sort((a, b) => a.localeCompare(b));
    setUnits(uniqueUnits);
  }, [itemsBill]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleClassConceptChange = (newValue, actionMeta) => {
    const name = actionMeta.name;
    const value = newValue ? newValue.value : ""; // Obtener el valor seleccionado

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (inputs.proveedor) {
      // Buscar el primer concepto y clase correspondiente al proveedor seleccionado
      const conceptoPorProveedor = itemsBill.find(
        (item) => item.proveedor === inputs.proveedor
      );

      if (conceptoPorProveedor) {
        setInputs((prevInputs) => ({
          ...prevInputs,
          class: conceptoPorProveedor.class,
          lineItemText: conceptoPorProveedor.lineItemText,
        }));
      }
    }
  }, [inputs.proveedor, itemsBill]);

  function validar(inputs) {
    let errors = {};

    if (!inputs.proveedor) {
      errors.proveedor = "Por favor seleccione un proveedor válido";
    } else if (!inputs.class) {
      errors.class = "Por favor ingrese una clase";
    } else if (!inputs.lineItemText) {
      errors.lineItemText = "Por favor ingrese un concepto";
    }
    return errors;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = validar(inputs);
    setErrors(errors);

    Swal.fire({
      title: "¿Está seguro que desea agregar un nuevo concepto?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed && !Object.keys(errors).length) {
        createItemBill(dispatch, inputs, user.accessToken)
          .then((res) => {
            if (res?.error) {
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            } else {
              setInputs({
                class: "",
                lineItemText: "",
                unit: "",
                consumptionUnit: "",
                costUnit: ""
              });
              navigate(`/itemBill/register`);
              Toast.fire({
                icon: "success",
                title: `Se creó con éxito.`,
              });
            }
          })
          .catch((err) => {
            console.error("error", err);
          });
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo agregar el nuevo concepto. ${Object.values(
            errors
          )}`,
        });
      }
    });
  };

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=itemsBill`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Agregar concepto de factura</h2>
      <form>
        <div className="form-group">
          <label htmlFor="proveedor">Proveedor: </label>
          <select
            id="proveedor"
            name="proveedor"
            value={inputs?.proveedor}
            onChange={handleInputChange}
          >
            <option value={""}>Selecciona un proveedor</option>
            {sortedSuppliers &&
              sortedSuppliers.map((s) => (
                <option value={s.nombre} key={s.id}>
                  {s.nombre}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="class">Clase: </label>
          <CreatableSelect
            id="class"
            name="class"
            value={{ label: inputs.class, value: inputs.class }}
            onChange={handleClassConceptChange}
            options={classes.map((cls) => ({ label: cls, value: cls }))}
            placeholder="Ingrese clase. Por ejemplo: Impuestos"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lineItemText">Concepto: </label>
          <CreatableSelect
            id="lineItemText"
            name="lineItemText"
            value={{ label: inputs.lineItemText, value: inputs.lineItemText }}
            onChange={handleClassConceptChange}
            options={concepts.map((concept) => ({
              label: concept,
              value: concept,
            }))}
            placeholder="Ingrese concepto. Por ejemplo: Imp. Valor Agregado 27%"
          />
        </div>
        <div className="form-group">
          <label htmlFor="consumptionUnit">Unidad de consumo: </label>
          <select
            id="consumptionUnit"
            name="consumptionUnit"
            value={inputs.consumptionUnit}
            onChange={handleInputChange}
          >
            <option value={"null"}>No corresponde consumo</option>
            {units &&
              units.map((u) => (
                <option key={u} value={u}>
                  {u}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="costUnit">Unidad de costo: </label>
          <select
            id="costUnit"
            name="costUnit"
            value={inputs.costUnit}
            onChange={handleInputChange}
          >
            <option value={"null"}>No corresponde costo</option>
            <option value={"AR"}>AR</option>
            <option value={"USD"}>USD</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Crear
          </button>
        </div>
      </form>
      <FloatingHelpIcon infoHelp={"itemBill"}/>
    </div>
  );
};

export default RegisterBill;
