import React from "react";
import { FaCogs, FaInfoCircle, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import User from "../Cards/User";

const UsersList = ({ users, user }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">USUARIOS</p>

          <div className="btn-users">
            <button
              className="adduser"
              onClick={() => navigate("/users/register")}
              title="Crear nuevo usuario"
            >
              <i>
                <FaUserPlus />
              </i>
              Nuevo usuario
            </button>
            <button
              className="adduser"
              onClick={() => navigate("/users/configPass")}
              title="Políticas de contraseña"
            >
              <i>
                <FaCogs />
              </i>
              Políticas de clave
            </button>
            <button
              className="adduser"
              onClick={() => navigate("/users/activity")}
              title="Actividad de usuarios"
            >
              <i>
                <FaInfoCircle />
              </i>
              Actividad de usuarios
            </button>
          </div>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="user-row">
            <th>ID</th>
            <th>USUARIO</th>
            <th>EMAIL</th>
            <th>PRIVILEGIO</th>
            <th>ESTADO</th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((u) => (
            <User key={u.id_user} user={u} userLog={user} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UsersList;
