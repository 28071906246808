import React from "react";

const SelectList = ({ tableSelected, setTableSelected, user }) => {
  const handleChange = (e) => {
    setTableSelected(e.target.value);
  };

  return (
    <div className="pick">
      <div
        className="group-btnradio"
        role="group"
        aria-label="Vertical radio toggle button group"
      >
        {user?.privilege !== "editor" ? (
          <>
            <input
              type="radio"
              className="btn-check check-u"
              value="users"
              name="vbtn-radio"
              id="vbtn-radio1"
              checked={tableSelected === "users"}
              onChange={handleChange}
            />
            <label
              className="btn btn-pick btn-outline-secondary"
              htmlFor="vbtn-radio1"
            >
              Usuarios
            </label>
          </>
        ) : null}
        <>
          <input
            type="radio"
            className="btn-check check-c"
            value="companies"
            name="vbtn-radio"
            id="vbtn-radio2"
            checked={tableSelected === "companies"}
            onChange={handleChange}
          />
          <label
            className="btn btn-pick btn-outline-secondary"
            htmlFor="vbtn-radio2"
          >
            Sucursales
          </label>
          {user.privilege !== "admin" ? (
            <>
              <input
                type="radio"
                className="btn-check check-c"
                value="suppliers"
                name="vbtn-radio"
                id="vbtn-radio3"
                checked={tableSelected === "suppliers"}
                onChange={handleChange}
              />
              <label
                className="btn btn-pick btn-outline-secondary"
                htmlFor="vbtn-radio3"
              >
                Proveedores
              </label>
              <input
                type="radio"
                className="btn-check check-c"
                value="supplierOffice"
                name="vbtn-radio"
                id="vbtn-radio4"
                checked={tableSelected === "supplierOffice"}
                onChange={handleChange}
              />
              <label
                className="btn btn-pick btn-outline-secondary"
                htmlFor="vbtn-radio4"
              >
                Proveedores asignados
              </label>
              <input
                type="radio"
                className="btn-check check-c"
                value="itemsBill"
                name="vbtn-radio"
                id="vbtn-radio5"
                checked={tableSelected === "itemsBill"}
                onChange={handleChange}
              />
              <label
                className="btn btn-pick btn-outline-secondary"
                htmlFor="vbtn-radio5"
              >
                Items de factura
              </label>
            </>
            
          ) : null}
        </>
      </div>
    </div>
  );
};

export default SelectList;
