import { publicRequest } from "../../requestMeth";
import { editItemBillFailure, editItemBillStart, editItemBillSuccess } from "../reducers/editItemBills";
import { itemsBillFailure, itemsBillStart, itemsBillSuccess } from "../reducers/itemBills";


export const getAllItemsBill = async (dispatch, token, supplier) => {
  dispatch(itemsBillStart());
  try {
    const res = await publicRequest.get(`/itemsBill?supplier=${supplier}`, {
      headers: { token: `Bearer ${token}` }
    });
    dispatch(itemsBillSuccess(res.data))
    return res.data
  } catch (error) {
    dispatch(itemsBillFailure([]));
  }
};


export const createItemBill = async (dispatch, payload, token) => {
  dispatch(itemsBillStart());
  try {
    const res = await publicRequest.post(`/itemsBill`, payload, {
      headers: { token: `Bearer ${token}` }
    });
  } catch (error) {
    dispatch(itemsBillFailure({}))
    return {error: true, message: "No se pudo crear el concepto, intentelo nuevamente."};
  }  
};

export const deleteItemBill = async (dispatch, id, token) =>{
  dispatch(itemsBillFailure());
  try {
    const res = await publicRequest.delete(`/itemsBill/${id}`, {
      headers: { token: `Bearer ${token}` }
  });
      dispatch(itemsBillSuccess(res.data));
  } catch (error) {
      dispatch(itemsBillFailure());
  }
};

export const updateItemBill = async (dispatch, id, payload, token) => {
  dispatch(editItemBillStart());
  try {
      const res = await publicRequest.put(`/itemsBill/edit/${id}`, payload, {
          headers: { token: `Bearer ${token}` }
      });
      dispatch(editItemBillSuccess(res.data));
  } catch (error) {
      dispatch(editItemBillFailure({}));
      return {error: true, message: "No se pudo actualizar el concepto, intentelo nuevamente."};
  }
};