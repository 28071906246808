import { utils, writeFile } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";

export default function CompanyExcel({ companies }) {

  const downloadExcel = () => {
    if (companies && Array.isArray(companies)) {
      const wb = utils.book_new();

      if (Array.isArray(companies)) {
        

        const dataAsArray = companies.map(item => ({
          ID: item.id_company,
          Código : item.branch_code,
          Nombre: item.name !== "null" && item.name !== "" ? item.name : "N/A",
          Provincia: item.state !== "null" && item.state !== "" ? item.state : "N/A",
          Dirección: item.address !== "null" && item.address !== "" ? item.address : "N/A",
          M2: item.m2 !== "null" && item.m2 !== "" ? item.m2 : "N/A",
          "Contacto Responsable Suc": item.manager_contact !== "null" && item.manager_contact !== "" ? item.manager_contact : "N/A", 
          Estado: item.status,
        }));
      

        const ws = utils.json_to_sheet(dataAsArray);

        const columnWidths = dataAsArray.map(item => item.Nombre.length);
        const maxColumnWidth = Math.max(...columnWidths);

        ws['!cols'] = [
            { wch: 15 },
            { wch: 15 },
            { wch: maxColumnWidth + 2 },
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
        ];
        utils.book_append_sheet(wb, ws);
      }

      writeFile(wb, `ReporteSucursal.xlsx`);
    }
  }

  return (
    <>
      <b onClick={downloadExcel} >
        <FaFileDownload /> Reporte por sucursal
      </b>
    </>
  );
}
