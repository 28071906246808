import "./Login.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/apiCalls/loginCall";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../helpers/alert";
import logo from "../../assets/logo.png";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({
    username: "",
    pass: "",
  });

  function validate(input) {
    let errors = {};
    if (!input.username) {
      errors.username = "Ingrese su nombre de usuario";
    }
    if (!input.pass) {
      errors.pass = "Ingresa tu contraseña";
    }
    return errors;
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.username === "" || input.pass === "") {
      Toast.fire({
        icon: "info",
        title: "Credenciales invalidas",
      });
      setInput({ username: "", pass: "" });
    } else {
      await login(dispatch, input);
      if (user.currentUser?.passwordExpired === true) {
        navigate("/expired-password");
      }
      user.error && setInput({ username: "", pass: "" });
    }
  };

  useEffect(() => {
    (() => {
      if (user.currentUser?.passwordExpired === true) {
        navigate("/expired-password");
      } else if (user.error && !user.currentUser && user.messageError) {
        Toast.fire({
          icon: "info",
          title: user?.messageError || "Error desconocido",
        });
      } else {
        user.currentUser &&
          Toast.fire({
            icon: "success",
            title: "Inicio exitoso",
          });
        const expirationDate = new Date(
          Date.now() +
            2 * 24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60 * 1000
        );
        localStorage.setItem("expirationDate", expirationDate.toISOString());
        user.currentUser && navigate("/");
      }
    })();
  }, [user]);

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <div className="text-center mb-4">
            <img src={logo} width="100px" />
            <h3 className="card-title">Iniciar sesión</h3>
          </div>
          <form>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Usuario
              </label>
              <input
                type="email"
                className="form-control login"
                id="email"
                placeholder="usuario"
                value={input.username}
                name="username"
                onChange={(e) => handleChange(e)}
              />
              <br />
              {errors.username && (
                <span className="error-msg">
                  <small>{errors.username}</small>
                </span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Contraseña
              </label>
              <input
                type="password"
                className="form-control login"
                id="password"
                placeholder="********"
                value={input.pass}
                name="pass"
                onChange={(e) => handleChange(e)}
              />
              <br />
              {errors.username && (
                <span className="error-msg">
                  <small>{errors.pass}</small>
                </span>
              )}
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary login"
                onClick={handleSubmit}
                disabled={user.isFetching}
              >
                {user.isFetching ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Iniciar sesión"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
