import React from "react";  
import summaryTemplate from '../../assets/corpSummary.png'
import energyDash from '../../assets/energyDash.png'
import financeTemplate from '../../assets/finance.png'
import sustainabilityTemplate from '../../assets/sustainabilitydash.png'


const TemplatesSelect = ({addImageToBoard}) => {
  return (
    <>
      <div className="select-Temp" onClick={() => {addImageToBoard("templateA")}}>
        <img
          src={energyDash}
          width="80px"
          height="80px" 
          alt="Dash template"
        />
        <p>
          REPORTE DE ENERGÍA
        </p>
      </div>
      <div className="select-Temp" onClick={() =>{addImageToBoard("templateB")}}>
        <img
          src={financeTemplate}
          width="80px"
          height="80px"
          alt="Dash template"
        />
        <p>
          REPORTE ECONÓMICO
        </p>
      </div>
      <div className="select-Temp" onClick={() => {addImageToBoard("templateC")}}>
        <img
          src={sustainabilityTemplate}
          width="80px"
          height="80px" 
          alt="Dash template"
        />
        <p>
          REPORTE SOSTENIBILIDAD
        </p>
      </div>
      <div className="select-Temp" onClick={() => {addImageToBoard("templateD")}}>
        <img
          src={summaryTemplate}
          width="80px"
          height="80px" 
          alt="Dash template"
        />
        <p>
          REPORTE CORPORATIVO
        </p>
      </div>
    </>
  );
};

export default TemplatesSelect;
