import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const TableConsumpAndCosts = ({ title, data, type, info }) => {
  const { theme } = useContext(ThemeContext);
  const locationTotals = {};

  data?.forEach((item) => {
    const consumo = parseFloat(item?.consumption);
    const costo = parseFloat(item?.cost);

    if ((info === "consumption" && !isNaN(consumo)) || (info !== "consumption" && ((!isNaN(consumo) && consumo > 0) || (!isNaN(costo) && costo > 0)))) {

      if (!locationTotals[item?.locationName] && item?.locationName) {
        locationTotals[item?.locationName] = {
          consumoTotal: consumo > 0 && item?.units !== "N/A" ? consumo : 0,
          costoTotal: !isNaN(costo) && costo > 0 ? costo : 0,
        };
      } else if (item?.locationName) {
        locationTotals[item?.locationName].consumoTotal +=
          consumo > 0 && item?.units !== "N/A" ? consumo : 0;
        locationTotals[item?.locationName].costoTotal +=
          !isNaN(costo) && costo > 0 ? costo : 0;
      }
    }
  });

  const sortedLocations = Object.keys(locationTotals).sort(
    (a, b) => {
      if(info === "consumption"){
        return locationTotals[b].consumoTotal - locationTotals[a].consumoTotal
      } else{
        return locationTotals[b].costoTotal - locationTotals[a].costoTotal
      }
    }
  );

  const totalConsumo = data?.reduce((sum, item) => {
    const consumo = parseFloat(item.consumption);
    return (!isNaN(consumo) && item.units !== "N/A") ? sum + consumo : sum;
  }, 0);

  const totalCosto = data?.reduce((sum, item) => {
    const costo = parseFloat(item.cost);
    return !isNaN(costo) ? (sum += costo) : sum;
  }, 0);

  const formattedTotalCost = totalCosto?.toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedTotalCons = totalConsumo?.toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div style={{ overflowX: "auto" }}>
      <div
        style={{ maxHeight: "350px", overflowY: "auto", position: "relative" }}
      >
        <p className="title-consumpCost">{title}</p>
        <table
          className={`table ${
            theme === "dark" ? "table-striped" : "table-dark table-striped"
          }`}
        >
          <thead>
            <tr>
              <th scope="col">Sitio</th>
              <th scope="col">Consumo</th>
              {info !== "consumption" ? <th scope="col">Costo</th> : ""}
            </tr>
          </thead>
          <tbody>
            {sortedLocations.map((locationName, index) => (
              <tr key={`${locationName}_${index}`}>
                <td>{locationName}</td>
                <td>
                  {locationTotals[locationName]?.consumoTotal !== undefined
                    ? locationTotals[locationName].consumoTotal?.toLocaleString("es-AR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : null}{" "}
                  {type}
                </td>
                {info !== "consumption" ? (
                  <td>
                    AR${" "}
                    {locationTotals[locationName]?.costoTotal !== undefined
                      ? locationTotals[locationName].costoTotal
                          .toLocaleString("es-AR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : null}
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {info !== "consumption" ? (
        <div
          style={{
            position: "sticky",
            bottom: "0",
            left: "0",
            right: "0",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "8px",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            zIndex: "1", // Asegura que la fila de totales esté por encima de la tabla
          }}
        >
          <div>Total</div>
          <div>
            {formattedTotalCons} {type}
          </div>
          <div>AR$ {formattedTotalCost}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TableConsumpAndCosts;
