import React from 'react';
import MapChart from '../MapChart';
import MapWaterChart from '../MapWaterChart';
import LoadScriptWrapper from './LoadScriptWrapper';

const MapsContainer = ({ CO2 }) => {
  return (
    <LoadScriptWrapper>
      <MapChart CO2={CO2} />
      <MapWaterChart waterConsumption={CO2} />
    </LoadScriptWrapper>
  );
};

export default MapsContainer;
