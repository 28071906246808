import { createSlice } from "@reduxjs/toolkit";

const billReportSlice = createSlice({
  name: "billReport",
  initialState: {
    billsReport: null,
    isFetching: false,
    error: false,
    powerReport: null,
    powerIsFetching: false,
    powerError: false,
    energyReport: null,
    energyIsFetching: false,
    energyError: false
  },
  reducers: {
    billsReportStart: (state) => {
      state.isFetching = true;
    },
    billsReportSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.billsReport = action.payload
    },
    billsReportFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    powerReportStart: (state) => {
      state.powerIsFetching = true;
    },
    powerReportSuccess: (state, action) => {
      state.powerIsFetching = false;
      state.powerError = false;
      state.powerReport = action.payload
    },
    powerReportFailure: (state) => {
      state.powerIsFetching = false;
      state.powerError = true;
    },
    energyReportStart: (state) => {
      state.energyIsFetching = true;
    },
    energyReportSuccess: (state, action) => {
      state.energyIsFetching = false;
      state.energyError = false;
      state.energyReport = action.payload
    },
    energyReportFailure: (state) => {
      state.energyIsFetching = false;
      state.energyError = true;
    },
    logoutbillsReport: (state) => {
      state.billsReport = null;
      state.isFetching = false;
      state.error = false;
      state.powerIsFetching = false;
      state.powerError = false;
      state.powerReport = null;
    },
  },
});


export const { billsReportStart, billsReportSuccess, billsReportFailure, powerReportStart, powerReportFailure, powerReportSuccess, 
  energyReportFailure, energyReportStart, energyReportSuccess, logoutbillsReport } = billReportSlice.actions;
export default billReportSlice.reducer;