import XLSX from "sheetjs-style";
import { useDispatch, useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";
import { Toast } from "../../../helpers/alert";
import { getPowerReport } from "../../../redux/apiCalls/billsReportCall";

export default function AuditPowerReport({ filters }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);
  const supplierSelected =
    filters?.supplierSelected !== "allSuppliers"
      ? filters?.supplierSelected
      : "";

  const downloadExcel = () => {
    Toast.fire({
      icon: "info",
      title: "Se está generando el reporte. Pronto comenzará la descarga.",
    });

    getPowerReport(dispatch, user?.accessToken, filters)
      .then((res) => {
        const wb = XLSX.utils.book_new();
        const monthsName = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        const headerDates = [
          `AUDITORÍA DE POTENCIA - ${supplierSelected} ${filters?.year}`,
        ];

         const classOrder = [
          "Potencia Contratada",
          "Potencia Demandada",
          "Potencia Excedida",
          "Coseno fi",
          "Tangente fi",
          "Total factura"
        ];
        const aggregatedData = {};

        Object.keys(res).forEach((month) => {
          const monthData = res[month];
          if (Array.isArray(monthData)) {
            monthData.forEach((item) => {
              const site = item["nombre del sitio"];

              if (!aggregatedData[site]) {
                aggregatedData[site] = {
                  facturasConsideradas: new Array(12).fill("N/A"),
                  otrosDatos: {},
                };
              }

              const monthIndex = Object.keys(res).indexOf(month);
              aggregatedData[site].facturasConsideradas[monthIndex] =
                item["act"] ?? "N/A";

              const classConcepts = {};

              Object.keys(item).forEach((key) => {
                if (
                  ![
                    "nombre del sitio",
                    "proveedor",
                    "mes",
                    "año",
                    "act",
                  ].includes(key)
                ) {
                  const [concept, cls] = key.split(" - ");

                  if (!classConcepts[cls]) {
                    classConcepts[cls] = new Set();
                  }
                  classConcepts[cls].add(concept);
                }
              });

              Object.keys(classConcepts).forEach((cls) => {
                if (classConcepts[cls].size === 1) {
                  const concept = [...classConcepts[cls]][0];
                  const key = `${cls}`;

                  if (!aggregatedData[site].otrosDatos[key]) {
                    aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                      "N/A"
                    );
                  }
                  if (monthIndex !== -1) {
                    aggregatedData[site].otrosDatos[key][monthIndex] =
                      item[`${concept} - ${cls}`] ?? "N/A";
                  }
                } else {
                  classConcepts[cls].forEach((concept) => {
                    const key = `${concept} - ${cls}`;

                    if (!aggregatedData[site].otrosDatos[key]) {
                      aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                        "N/A"
                      );
                    }
                    if (monthIndex !== -1) {
                      aggregatedData[site].otrosDatos[key][monthIndex] =
                        item[key] ?? "N/A";
                    }
                  });
                }
              });
              
               // Coseno fi
              if (!item["Coseno fi - Coseno fi"] && !item["Cos fi - Coseno fi"] && !item["Coseno Fi - Coseno fi"] && item?.proveedor !== "Edenor T3" && item?.proveedor !== "Edenor T1" && 
                item?.proveedor !== "Edesur T3" && item?.proveedor !== "Edesur T1" ) {
                aggregatedData[site].otrosDatos["Coseno fi"] = new Array(12).fill("N/A");
            }

            // Tangente fi
            if (!item["Tangente fi - Tangente fi"] && !item["Tg fi - Tangente fi"] && item?.proveedor !== "Edenor T2" && 
              item?.proveedor !== "Edenor T1" && item?.proveedor !== "Edesur T2" && item?.proveedor !== "Edesur T1" ) {
                aggregatedData[site].otrosDatos["Tangente fi"] = new Array(12).fill("N/A");
            }

            });
          }
        });
        

        const ws = XLSX.utils.aoa_to_sheet([
          ["", "", ""].concat(headerDates),
          [],
          ["SUCURSALES", "CLASES"].concat(monthsName),
        ]);

        ws["!cols"] = [
          { width: 30 },
          { width: 30 },
          ...Array(monthsName.length).fill({ width: 12 }),
        ];

        let rowIndex = 4;

        Object.keys(aggregatedData).forEach((site) => {
          const data = aggregatedData[site];

       

          const sortedKeys = Object.keys(data.otrosDatos).sort((a, b) => {
            const getClass = (key) => {
              const parts = key.split(" - ");
              return parts.length === 2 ? parts[1]?.trim() : parts[0]?.trim();
            };

            const classA = getClass(a);
            const classB = getClass(b);

            const indexA = classOrder.findIndex((order) =>
              classA.includes(order)
            );
            const indexB = classOrder.findIndex((order) =>
              classB.includes(order)
            );

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
          });

          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [site, "Facturas consideradas"].concat(data.facturasConsideradas),
              ...sortedKeys.map((field) => [
                site,
                field,
                ...data.otrosDatos[field],
              ]),
            ],
            { origin: { r: rowIndex, c: 0 } }
          );
          
          sortedKeys.forEach((field) => {
            
              const values = data.otrosDatos[field];
              // Coloring logic for "Coseno fi"
              if (field.toLowerCase() === "coseno fi") {
                  // Loop through each value in "Coseno fi"
                  values.forEach((value, valueIndex) => {
                      const columnIndex = 2 + valueIndex;
                      const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                      const cellRef = XLSX.utils.encode_cell({ r: baseRow, c: columnIndex });
                      const facturasConsideradasActual =
                      data.facturasConsideradas[valueIndex];
                    const facturasConsideradasAnterior =
                      data.facturasConsideradas[valueIndex - 1];
    
                    // Si alguna de las facturas consideradas es "N/A", saltar el coloreado
                    if (
                      facturasConsideradasActual === "N/A" ||
                      facturasConsideradasAnterior === "N/A"
                    ) {
                      return;
                    }
                          // Set the style for cells regardless of whether the value is "N/A" or a valid number
                          if (!ws[cellRef]) ws[cellRef] = {};
          
                          // Apply the coloring based on the value
                          if (value === 'N/A') {
                              ws[cellRef].s = {
                                  fill: { fgColor: { rgb: "ff5252" } },  // Red background for "N/A"
                                  font: { name: "Arial", color: { rgb: "FFFFFF" } },  // White text
                              };
                          }
                  });
              }
              
              if (field.toLowerCase() === "tangente fi") {
                  // Loop through each value in "Tangente fi"
                  values.forEach((value, valueIndex) => {
                      const columnIndex = 2 + valueIndex;
                      const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                      const cellRef = XLSX.utils.encode_cell({ r: baseRow, c: columnIndex });
                      const facturasConsideradasActual =
                      data.facturasConsideradas[valueIndex];
                    const facturasConsideradasAnterior =
                      data.facturasConsideradas[valueIndex - 1];
    
                    // Si alguna de las facturas consideradas es "N/A", saltar el coloreado
                    if (
                      facturasConsideradasActual === "N/A" ||
                      facturasConsideradasAnterior === "N/A"
                    ) {
                      return;
                    }

                          // Set the style for cells regardless of whether the value is "N/A" or a valid number
                          if (!ws[cellRef]) ws[cellRef] = {};
          
                          // Apply the coloring based on the value
                          if (value === 'N/A') {
                              ws[cellRef].s = {
                                  fill: { fgColor: { rgb: "ff5252" } },  // Red background for "N/A"
                                  font: { name: "Arial", color: { rgb: "FFFFFF" } },  // White text
                              };
                          }
                      
                  });
              }
         

          // Lógica de coloreado para "Potencia Demandada"

            if (field.toLowerCase().includes("potencia demandada")) {
              const values = data.otrosDatos[field];

              values.forEach((value, valueIndex) => {
                const facturasConsideradasActual =
                  data.facturasConsideradas[valueIndex];
                const facturasConsideradasAnterior =
                  data.facturasConsideradas[valueIndex - 1];

                // Si alguna de las facturas consideradas es "N/A", saltar el coloreado
                if (
                  facturasConsideradasActual === "N/A" ||
                  facturasConsideradasAnterior === "N/A"
                ) {
                  return;
                }

                if (valueIndex > 1) {
                  const prevValue = values[valueIndex - 1];
                  const twoMonthsAgoValue = values[valueIndex - 2] || "N/A";
                  const oneMonthAgoValue = values[valueIndex - 1] || "N/A";

                  if (
                    value !== "N/A" &&
                    oneMonthAgoValue !== "N/A" &&
                    twoMonthsAgoValue !== "N/A" &&
                    value === twoMonthsAgoValue * 2
                  ) {
                    const columnIndex = 2 + valueIndex;
                    const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                    const cellRef = XLSX.utils.encode_cell({
                      r: baseRow,
                      c: columnIndex,
                    });

                    if (!ws[cellRef]) ws[cellRef] = {};
                    if (!ws[cellRef].s) {
                      ws[cellRef].s = {
                        fill: { fgColor: { rgb: "ff5252" } },
                        font: {
                          name: "Arial",
                          color: { rgb: "FFFFFF" },
                        },
                      };
                    }
                  }
                }
              });
            }
        // Lógica de coloreado para "Potencia Excedida"
        const epsilon = 0.02;
          if (field.toLowerCase().includes("potencia excedida")) {
            const exceededValues = data.otrosDatos[field];
            const contractedValues = data.otrosDatos["Potencia Contratada"] || [];
            const demandedValues = data.otrosDatos["Potencia Demandada"] || [];

            exceededValues.forEach((exceededValue, valueIndex) => {
              

              const contractedValue = contractedValues[valueIndex];
              const demandedValue = demandedValues[valueIndex];

              if (
                exceededValue !== "N/A" &&
                contractedValue !== "N/A" &&
                demandedValue !== "N/A"
              ) {
                const exceededValueParsed = parseFloat(exceededValue);
                const contractedValueParsed = parseFloat(contractedValue);
                const demandedValueParsed = parseFloat(demandedValue);

                let calculatedExceeded = 0;

                // Lógica especial para EDESUR
                if (
                  supplierSelected.toLowerCase() === "edesur" &&
                  demandedValueParsed >= contractedValueParsed
                ) {
                  calculatedExceeded =
                    (demandedValueParsed - contractedValueParsed) * 1.5010458962;
                } else {
                  calculatedExceeded = demandedValueParsed - contractedValueParsed;
                }

                // Este código resalta la celda solo si la diferencia es significativa y no es 0
                if (Math.abs(calculatedExceeded - exceededValueParsed) > epsilon && exceededValueParsed !== 0) {
                  const columnIndex = 2 + valueIndex;
                  const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                  const cellRef = XLSX.utils.encode_cell({
                    r: baseRow,
                    c: columnIndex,
                  });

                  if (!ws[cellRef]) ws[cellRef] = {};
                  if (!ws[cellRef].s) {
                    ws[cellRef].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: {
                        name: "Arial",
                        color: { rgb: "FFFFFF" },
                      },
                    };
                  }
                }
              }
            });
          }
            // Obtener valores de "Demanda Contratada Máxima - Potencia Contratada"
            const demandaContratadaMaximaKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda contratada máxima - potencia contratada")
            );
            const demandaContratadaMaximaValues = data.otrosDatos[demandaContratadaMaximaKey] || [];
        
            // Obtener valores de "Demanda Contratada Pico - Potencia Contratada"
            const demandaContratadaPicoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda contratada pico - potencia contratada")
            );
            const demandaContratadaPicoValues = data.otrosDatos[demandaContratadaPicoKey] || [];
        
            // Comparar los valores
            demandaContratadaMaximaValues.forEach((demandaMaximaValue, index) => {
              const demandaPicoValue = demandaContratadaPicoValues[index];
        
              // Verificar que ninguno de los valores sea "N/A"
              if (demandaMaximaValue !== "N/A" && demandaPicoValue !== "N/A") {
                // Comprobar si los valores son diferentes
                if (parseFloat(demandaMaximaValue) !== parseFloat(demandaPicoValue)) {
                  const columnIndex = 2 + index;
        
                  // Pintar la celda para "Demanda Contratada Máxima - Potencia Contratada"
                  const baseRowMaxima = rowIndex + 1 + sortedKeys.indexOf(demandaContratadaMaximaKey);
                  const cellRefMaxima = XLSX.utils.encode_cell({ r: baseRowMaxima, c: columnIndex });
        
                  if (!ws[cellRefMaxima]) ws[cellRefMaxima] = {};
                  if (!ws[cellRefMaxima].s) {
                    ws[cellRefMaxima].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
        
                  // Pintar la celda para "Demanda Contratada Pico - Potencia Contratada"
                  const baseRowPico = rowIndex + 1 + sortedKeys.indexOf(demandaContratadaPicoKey);
                  const cellRefPico = XLSX.utils.encode_cell({ r: baseRowPico, c: columnIndex });
        
                  if (!ws[cellRefPico]) ws[cellRefPico] = {};
                  if (!ws[cellRefPico].s) {
                    ws[cellRefPico].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
                }
              }
            });
           // Nueva validación para "Demanda Registrada Máxima - Potencia Demandada" vs "Demanda Registrada Pico - Potencia Demandada"}
  const demandaRegistradaMaximaKey = Object.keys(data.otrosDatos).find(key =>
    key.toLowerCase().includes("demanda registrada máxima - potencia demandada")
  );
  const demandaRegistradaMaximaValues = data.otrosDatos[demandaRegistradaMaximaKey] || [];

  const demandaRegistradaPicoKey = Object.keys(data.otrosDatos).find(key =>
    key.toLowerCase().includes("demanda registrada pico - potencia demandada")
  );
  const demandaRegistradaPicoValues = data.otrosDatos[demandaRegistradaPicoKey] || [];

  // Comparar los valores
  demandaRegistradaMaximaValues.forEach((demandaMaximaValue, index) => {
    const demandaPicoValue = demandaRegistradaPicoValues[index];

    // Verificar que ninguno de los valores sea "N/A"
    if (demandaMaximaValue !== "N/A" && demandaPicoValue !== "N/A") {
      // Comprobar si "Demanda Registrada Máxima" es menor o igual a "Demanda Registrada Pico"
      if (parseFloat(demandaMaximaValue) <= parseFloat(demandaPicoValue)) {
        const columnIndex = 2 + index;

        // Pintar la celda para "Demanda Registrada Máxima - Potencia Demandada"
        const baseRowMaximaDemandada = rowIndex + 1 + sortedKeys.indexOf(demandaRegistradaMaximaKey);
        const cellRefMaximaDemandada = XLSX.utils.encode_cell({ r: baseRowMaximaDemandada, c: columnIndex });

        if (!ws[cellRefMaximaDemandada]) ws[cellRefMaximaDemandada] = {};
        if (!ws[cellRefMaximaDemandada].s) {
          ws[cellRefMaximaDemandada].s = {
            fill: { fgColor: { rgb: "ff5252" } },
            font: { name: "Arial", color: { rgb: "FFFFFF" } },
          };
        }

        // Pintar la celda para "Demanda Registrada Pico - Potencia Demandada"
        const baseRowPicoDemandada = rowIndex + 1 + sortedKeys.indexOf(demandaRegistradaPicoKey);
        const cellRefPicoDemandada = XLSX.utils.encode_cell({ r: baseRowPicoDemandada, c: columnIndex });

        if (!ws[cellRefPicoDemandada]) ws[cellRefPicoDemandada] = {};
        if (!ws[cellRefPicoDemandada].s) {
          ws[cellRefPicoDemandada].s = {
            fill: { fgColor: { rgb: "ff5252" } },
            font: { name: "Arial", color: { rgb: "FFFFFF" } },
          };
        }
      }
    }
  });

   // Nueva validación para "Demanda Excedida Máxima - Potencia Excedida" vs "Demanda Excedida Pico - Potencia Excedida"
   const demandaExcedidaMaximaKey = Object.keys(data.otrosDatos).find(key =>
    key.toLowerCase().includes("demanda excedida máxima - potencia excedida")
  );
  const demandaExcedidaMaximaValues = data.otrosDatos[demandaExcedidaMaximaKey] || [];

  const demandaExcedidaPicoKey = Object.keys(data.otrosDatos).find(key =>
    key.toLowerCase().includes("demanda excedida pico - potencia excedida")
  );
  const demandaExcedidaPicoValues = data.otrosDatos[demandaExcedidaPicoKey] || [];

  // Comparar los valores
  demandaExcedidaMaximaValues.forEach((demandaExcedidaMaximaValue, index) => {
    const demandaExcedidaPicoValue = demandaExcedidaPicoValues[index];

    // Verificar que ninguno de los valores sea "N/A"
    if (demandaExcedidaMaximaValue !== "N/A" && demandaExcedidaPicoValue !== "N/A") {
      // Comprobar si "Demanda Excedida Máxima" es menor o igual a "Demanda Excedida Pico"
      if (parseFloat(demandaExcedidaMaximaValue) <= parseFloat(demandaExcedidaPicoValue)) {
        const columnIndex = 2 + index;

        // Pintar la celda para "Demanda Excedida Máxima - Potencia Excedida"
        const baseRowExcedidaMaxima = rowIndex + 1 + sortedKeys.indexOf(demandaExcedidaMaximaKey);
        const cellRefExcedidaMaxima = XLSX.utils.encode_cell({ r: baseRowExcedidaMaxima, c: columnIndex });

        if (!ws[cellRefExcedidaMaxima]) ws[cellRefExcedidaMaxima] = {};
        if (!ws[cellRefExcedidaMaxima].s) {
          ws[cellRefExcedidaMaxima].s = {
            fill: { fgColor: { rgb: "ff5252" } },
            font: { name: "Arial", color: { rgb: "FFFFFF" } },
          };
        }

        // Pintar la celda para "Demanda Excedida Pico - Potencia Excedida"
        const baseRowExcedidaPico = rowIndex + 1 + sortedKeys.indexOf(demandaExcedidaPicoKey);
        const cellRefExcedidaPico = XLSX.utils.encode_cell({ r: baseRowExcedidaPico, c: columnIndex });

        if (!ws[cellRefExcedidaPico]) ws[cellRefExcedidaPico] = {};
        if (!ws[cellRefExcedidaPico].s) {
          ws[cellRefExcedidaPico].s = {
            fill: { fgColor: { rgb: "ff5252" } },
            font: { name: "Arial", color: { rgb: "FFFFFF" } },
          };
        }
      }
    }
  });
        
          if (field.toLowerCase().includes("potencia contratada pico")) {
            const picoValues = data.otrosDatos[field];
            
            // Verificamos que la clave exacta esté presente para "Potencia Contratada Fuera de Pico"
            const fueraPicoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("potencia contratada fuera de pico")
            );

            const fueraPicoValues = data.otrosDatos[fueraPicoKey] || [];

            picoValues.forEach((picoValue, valueIndex) => {

              const fueraPicoValue = fueraPicoValues[valueIndex];

              if (picoValue !== "N/A" && fueraPicoValue !== "N/A" && parseFloat(picoValue) <= parseFloat(fueraPicoValue)  && supplierSelected.toLowerCase() !== "edet") {
                const columnIndex = 2 + valueIndex;

                // Pinta la celda para "Potencia Contratada Pico"
                const baseRowPico = rowIndex + 1 + sortedKeys.indexOf(field);
                const cellRefPico = XLSX.utils.encode_cell({ r: baseRowPico, c: columnIndex });

                if (!ws[cellRefPico]) ws[cellRefPico] = {};
                if (!ws[cellRefPico].s) {
                  ws[cellRefPico].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }

                // Pinta la celda para "Potencia Contratada Fuera de Pico"
                const baseRowFueraPico = rowIndex + 1 + sortedKeys.indexOf(fueraPicoKey);
                const cellRefFueraPico = XLSX.utils.encode_cell({ r: baseRowFueraPico, c: columnIndex });

                if (!ws[cellRefFueraPico]) ws[cellRefFueraPico] = {};
                if (!ws[cellRefFueraPico].s) {
                  ws[cellRefFueraPico].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }
              } 
              else if (
                picoValue !== "N/A" && 
                fueraPicoValue !== "N/A" && 
                parseFloat(picoValue) < parseFloat(fueraPicoValue) && // Cambié < a <=
                supplierSelected.toLowerCase() === "edet"
              ) {
                const columnIndex = 2 + valueIndex;
              
                // Pinta la celda para "Potencia Contratada Pico"
                const baseRowPico = rowIndex + 1 + sortedKeys.indexOf(field);
                const cellRefPico = XLSX.utils.encode_cell({ r: baseRowPico, c: columnIndex });
              
                if (!ws[cellRefPico]) ws[cellRefPico] = {};
                if (!ws[cellRefPico].s) {
                  ws[cellRefPico].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }
              
                // Pinta la celda para "Potencia Contratada Fuera de Pico"
                const baseRowFueraPico = rowIndex + 1 + sortedKeys.indexOf(fueraPicoKey);
                const cellRefFueraPico = XLSX.utils.encode_cell({ r: baseRowFueraPico, c: columnIndex });
              
                if (!ws[cellRefFueraPico]) ws[cellRefFueraPico] = {};
                if (!ws[cellRefFueraPico].s) {
                  ws[cellRefFueraPico].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }
              }
            });
          }

          // Nueva validación para Potencia Demandada Fuera de Pico vs Potencia Demandada Pico
          if (field.toLowerCase().includes("potencia registrada fuera de pico")) {
            const fueraPicoDemandadaValues = data.otrosDatos[field];

            // Buscamos la clave correspondiente para "Potencia Registrada Pico"
            const picoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("potencia registrada pico")
            );

            const picoDemandadaValues = data.otrosDatos[picoKey] || [];

            fueraPicoDemandadaValues.forEach((fueraPicoDemandadaValue, valueIndex) => {

              const picoDemandadaValue = picoDemandadaValues[valueIndex];

              if (fueraPicoDemandadaValue !== "N/A" && picoDemandadaValue !== "N/A" && parseFloat(fueraPicoDemandadaValue) > parseFloat(picoDemandadaValue)) {
                const columnIndex = 2 + valueIndex;

                // Pinta la celda para "Potencia Demandada Fuera de Pico"
                const baseRowFueraPicoDemandada = rowIndex + 1 + sortedKeys.indexOf(field);
                const cellRefFueraPicoDemandada = XLSX.utils.encode_cell({ r: baseRowFueraPicoDemandada, c: columnIndex });

                if (!ws[cellRefFueraPicoDemandada]) ws[cellRefFueraPicoDemandada] = {};
                if (!ws[cellRefFueraPicoDemandada].s) {
                  ws[cellRefFueraPicoDemandada].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }

                // Pinta la celda para "Potencia Demandada Pico"
                const baseRowPicoDemandada = rowIndex + 1 + sortedKeys.indexOf(picoKey);
                const cellRefPicoDemandada = XLSX.utils.encode_cell({ r: baseRowPicoDemandada, c: columnIndex });

                if (!ws[cellRefPicoDemandada]) ws[cellRefPicoDemandada] = {};
                if (!ws[cellRefPicoDemandada].s) {
                  ws[cellRefPicoDemandada].s = {
                    fill: { fgColor: { rgb: "ff5252" } },
                    font: { name: "Arial", color: { rgb: "FFFFFF" } },
                  };
                }
              }
            });
          }
          if (supplierSelected?.toLowerCase()?.includes("edemsa")) {
            if (field.toLowerCase().includes("potencia registrada resto")) {
              const restoDemandadaValues = data.otrosDatos[field];
          
              // Buscamos las claves correspondientes para "Potencia Registrada Pico" y "Potencia Registrada Valle"
              const picoKey = Object.keys(data.otrosDatos).find(key =>
                key.toLowerCase().includes("potencia registrada pico")
              );
              const valleKey = Object.keys(data.otrosDatos).find(key =>
                key.toLowerCase().includes("potencia registrada valle")
              );
          
              const picoDemandadaValues = data.otrosDatos[picoKey] || [];
              const valleDemandadaValues = data.otrosDatos[valleKey] || [];
          
              restoDemandadaValues.forEach((restoDemandadaValue, valueIndex) => {
                const picoDemandadaValue = picoDemandadaValues[valueIndex];
                const valleDemandadaValue = valleDemandadaValues[valueIndex];
          
                if (restoDemandadaValue !== "N/A" && picoDemandadaValue !== "N/A" && valleDemandadaValue !== "N/A" && 
                    parseFloat(restoDemandadaValue) <= parseFloat(picoDemandadaValue) && 
                    parseFloat(restoDemandadaValue) <= parseFloat(valleDemandadaValue)) {
          
                  const columnIndex = 2 + valueIndex;
          
                  // Pinta la celda para "Potencia Demandada Resto"
                  const baseRowRestoDemandada = rowIndex + 1 + sortedKeys.indexOf(field);
                  const cellRefRestoDemandada = XLSX.utils.encode_cell({ r: baseRowRestoDemandada, c: columnIndex });
          
                  if (!ws[cellRefRestoDemandada]) ws[cellRefRestoDemandada] = {};
                  if (!ws[cellRefRestoDemandada].s) {
                    ws[cellRefRestoDemandada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
          
                  // Pinta las celdas para "Potencia Demandada Pico" y "Potencia Demandada Valle"
                  const baseRowPicoDemandada = rowIndex + 1 + sortedKeys.indexOf(picoKey);
                  const cellRefPicoDemandada = XLSX.utils.encode_cell({ r: baseRowPicoDemandada, c: columnIndex });
          
                  const baseRowValleDemandada = rowIndex + 1 + sortedKeys.indexOf(valleKey);
                  const cellRefValleDemandada = XLSX.utils.encode_cell({ r: baseRowValleDemandada, c: columnIndex });
          
                  if (!ws[cellRefPicoDemandada]) ws[cellRefPicoDemandada] = {};
                  if (!ws[cellRefPicoDemandada].s) {
                    ws[cellRefPicoDemandada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
          
                  if (!ws[cellRefValleDemandada]) ws[cellRefValleDemandada] = {};
                  if (!ws[cellRefValleDemandada].s) {
                    ws[cellRefValleDemandada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
                }
              });
            }
            // Validación para "Potencia Contratada"
            const potenciaContratadaValues = data.otrosDatos["Potencia Contratada"];

            // Verificamos si todos los valores de "Potencia Contratada" son iguales en todos los meses
            const firstValue = potenciaContratadaValues.find(value => value !== "N/A"); // El primer valor que no sea "N/A"
            const allContratadaEqual = potenciaContratadaValues.every(value => value === firstValue || value === "N/A");

            // Si no todos los valores son iguales, pintamos las celdas con el color de advertencia
            if (!allContratadaEqual) {
              potenciaContratadaValues.forEach((value, valueIndex) => {
                if (value !== "N/A" && value !== firstValue) { // Pintar solo si el valor es distinto al primero
                  const columnIndex = 2 + valueIndex;  // Calculamos el índice de columna basado en el mes
                  const baseRowContratada = rowIndex + 1 + sortedKeys.indexOf("Potencia Contratada");  // Obtenemos la fila para "Potencia Contratada"
                  const cellRefContratada = XLSX.utils.encode_cell({ r: baseRowContratada, c: columnIndex });

                  // Si la celda no tiene estilo, aplicamos el estilo de advertencia
                  if (!ws[cellRefContratada]) ws[cellRefContratada] = {};
                  if (!ws[cellRefContratada].s) {
                    ws[cellRefContratada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },  // Color de fondo rojo
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },  // Fuente Arial en blanco
                    };
                  }
                }
              });
            }
          }
          
        });
          rowIndex += 1 + sortedKeys.length;
        });

        XLSX.utils.book_append_sheet(wb, ws, `Potencia ${filters?.year}`);
        XLSX.writeFile(
          wb,
          `AUDITORÍA DE POTENCIA - ${supplierSelected} ${filters?.year}.xlsx`
        );
      })
      .catch((err) => {
        console.error(err);
        Toast.fire({
          icon: "error",
          title: "Error generando el reporte",
        });
      });
  };


  return (
    <>
      <button onClick={downloadExcel}>
        <FaFileDownload /> Descargar potencia por mes
      </button>
    </>
  );
}
