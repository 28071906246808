import "./CorporateSummary.css";
import React, { useEffect, useState } from "react";
import BulletChart from "../../Charts/BulletChart";
import BarChart from "../../Charts/BarChart";
import { provinces } from "../../../helpers/infoFilter";
import DownloadExcel from "../../Reports/ReportsExcel";
import { FaBars } from "react-icons/fa";
import BillsExcel from "../../Reports/ReportsBillsExcel";
import { useSelector } from "react-redux";
import SostentabilidadExcel from "../../Reports/ReportSostentabilidad";
import TableCorporate from "../TableComponent/TableCorporate";
import LineChart from "../../Charts/LineChart";
import LineChartWater from "../../Charts/LineChartWater";


const sumConsumptionsByLocation = (dataWater, offices) => {
  const consumptionByLocationAndUtility = {};

  // Función para armar la estructura del array, cada sucursal es un objeto
  dataWater?.forEach((item) => {
    const { locationName, consumption, utilityType } = item;
    const consumptionValue = Number(consumption);

    if (!isNaN(consumptionValue) && locationName && utilityType) {
      if (!consumptionByLocationAndUtility[locationName]) {
        consumptionByLocationAndUtility[locationName] = {};
      }
      
      if (!consumptionByLocationAndUtility[locationName][utilityType]) {
        consumptionByLocationAndUtility[locationName][utilityType] = 0;
      }

      consumptionByLocationAndUtility[locationName][utilityType] += consumptionValue;
    }
  });

  // Mapeo de los datos para agregar dirección, provincia y unidad según la ubicación
  const allConsumption = Object.entries(consumptionByLocationAndUtility).flatMap(
    ([locationName, utilityData]) => {
      const officeFound = offices?.find((o) => o?.name === locationName);

      return Object.entries(utilityData).map(([utilityType, consumption]) => {
        const itemFound = dataWater?.find((item) => item?.locationName === locationName && item?.utilityType === utilityType);
        const units = itemFound?.units?.toUpperCase()?.trim();

        return {
          locationName,
          utilityType,
          consumption,
          class: itemFound?.class,
          units: (units === "KWH" || units === "M3") ? units : null,
          medicion: itemFound?.utilityType || null, // Agrega la unidad a partir de data o usa una cadena vacía si no está presente
          address: officeFound ? officeFound?.address : null, // Agrega la dirección si se encuentra la oficina
          province: officeFound ? officeFound?.state : null, // Agrega la provincia si se encuentra la oficina
        };
      });
    }
  );

  return allConsumption;
};


const CorporateSummary = ({ data, filterOptions, filterClass, co2}) => {
  const offices = useSelector((state) => state.companies.companies);
  const dataFetching = useSelector((state) => state.data.isFetching);
  const co2Fetching = useSelector((state) => state.data.isFetchingCo2);
   const co2dataFetching = useSelector((state) => state.data.co2Data);
  const [co2Consumption, setCo2Consumption] = useState([]);
  const [totalCO2, setTotalCO2] = useState(0);
  let filteredTotal = [];
  let filteredConsump = [];
  const countBills = [...new Set(data?.map((item) => item.Bill_ID))];
  const activeCompanies = offices.filter(company => company.status === "ACTIVA");
  const uniqueLocationNames = [...new Set(activeCompanies.map(company => company?.name))];
  
  
  if (filterClass?.class?.length === 0) {
    filteredTotal = data?.filter(
      (item) =>
        item.cost !== null &&
        !isNaN(parseFloat(item.cost)) &&
        isFinite(item.cost) &&
        item.cost !== " " &&
        item.lineItemText === "Total Factura"
    );
    filteredConsump = data?.filter(
      (item) =>
        item?.consumption !== null &&
        !isNaN(parseFloat(item?.consumption)) &&
        isFinite(item?.consumption) &&
        item?.consumption !== " " &&
        (item?.class === "Energía Consumida" || item?.class === "Energia Consumida" 
        || item?.class === "Agua Consumida" || item?.class === "Gas Cons. a 9300 Kcal/m3"
        )
    );
  } else {
    filteredTotal = data?.filter(
      (item) =>
        item.cost !== null &&
        !isNaN(parseFloat(item.cost)) &&
        isFinite(item.cost) &&
        item.cost !== " "
    );
    filteredConsump = data?.filter(
      (item) =>
        item?.consumption !== null &&
        !isNaN(parseFloat(item?.consumption)) &&
        isFinite(item?.consumption) &&
        item?.consumption !== " "  &&
        item?.class !== "Coseno fi" && 
        item?.class !== "Tangente fi" &&
        item?.class !== "IVA"
    );
  }

  const totalCost = filteredTotal?.reduce((acc, item) => {
    const costValue = parseFloat(item.cost);
    if (costValue !== -1) {
      acc += costValue 
    }
    return acc;
  }, 0);

  const formattedTotalCost = totalCost?.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const stateCosts = {};
  const locationCosts = {};

  filteredTotal?.forEach((item) => {
    let state = item.state?.trim() || ''; 
    const cost = parseFloat(item.cost);
    if (!isNaN(cost)) {
      if (!stateCosts[state]) {
        stateCosts[state] = 0;
      }
      stateCosts[state] += cost;
    }
  });
  
  filteredTotal?.forEach((obj) => {
    const { locationName, cost } = obj;

    const costNumber = parseFloat(cost);

    if (!isNaN(costNumber) && cost !== "" && locationName ) {
      if (locationCosts.hasOwnProperty(locationName)) {
        locationCosts[locationName] += costNumber;
      } else {
        locationCosts[locationName] = costNumber;
      }
    }
  });

  const dataElectricity = data?.filter((d) => {
    return (
      d?.lineItemText !== "Total Factura"
      );
  });


  const nameProvinces = filterOptions?.provinces
    ?.map((value) => {
      const province = provinces?.find((p) => p.value === value);
      return province ? province?.name : value;
    })
    .join(", ");


    const formatInvoiceDataForExcel = (invoices) => {

      return invoices?.map((invoice) => {
        const formattedInvoice = {
          "nombre del sitio": invoice?.locationName,
          utilidad: invoice?.utilityType,
          proveedor: invoice?.supplier,
          nroCuenta: invoice?.AccountNumber,
          factura: invoice?.Bill_ID,
          lecturaActual: invoice?.readingDate,
          lecturaAnterior: invoice?.previousDate,
          emision: invoice?.invoiceDate,
          vencimiento: invoice?.dueDate,
          costo: invoice?.cost,
          consumo: invoice?.consumption,
          lineItemText: invoice?.lineItemText,
          class: invoice?.class,
          m2: invoice?.m2,
          units: invoice?.units
   
        };
 
        return formattedInvoice;
      });
    };

  const consumoSostentabilidad = {};

// Obtener el mes y año actual
const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

// Obtener el mes y año hace un año
const lastYearDate = new Date(currentDate);
lastYearDate.setFullYear(currentYear - 1);
const lastYearMonth = 0; // Comienza desde enero del año pasado
const lastYearYear = lastYearDate.getFullYear();

// Función para obtener el nombre del mes a partir de su número
const getMonthName = (monthNumber) => {
  const months = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];
  return months[monthNumber];
};

const monthsToFetch = [];
for (let year = lastYearYear; year <= currentYear; year++) {
  const startMonth = (year === lastYearYear) ? lastYearMonth : 0;
  const endMonth = (year === currentYear) ? currentMonth : 11;

  for (let month = startMonth; month <= endMonth; month++) {
    monthsToFetch.push({ year, month });
  }
}

monthsToFetch.forEach(({ year, month }) => {
  const monthName = getMonthName(month);
  const monthDataKey = `${monthName} ${year}`;
  const monthData = co2 && co2.groupedByMonth ? co2.groupedByMonth[monthDataKey] : undefined;

  // Inicializar consumoSostentabilidad[monthDataKey] si no está definido
  if (!consumoSostentabilidad[monthDataKey]) {
    consumoSostentabilidad[monthDataKey] = [];
  }

  // Agregar datos existentes para el mes actual
  if (monthData && monthData?.length > 0) {
    monthData.forEach(data => {
      consumoSostentabilidad[monthDataKey].push({
        office: data?.office,
        consumoTotalMes: parseFloat(data?.consumoTotalMes),
        mesProrrateado: data?.mesProrrateado,
        unidad: data?.unidad || "",
        tiposConsumo: data?.tiposConsumo || [],
        days: data?.diasCalculados
      });
    });
  }

  offices?.forEach(office => {
    if (!consumoSostentabilidad[monthDataKey].some(item => item?.office === office?.name)) {
      consumoSostentabilidad[monthDataKey].push({
        office: office?.name,
        consumoTotalMes: 0.00,
        mesProrrateado: monthDataKey,
        unidad: "",
        tiposConsumo: [],
      });
    }
  });
});

    

    const areDatesOnly =
    filterOptions?.startDate &&
    filterOptions?.endDate &&
    filterOptions?.date?.length === 0 &&
    filterOptions?.provinces?.length === 0 &&
    filterOptions?.companies?.length === 0 &&
    filterOptions?.utilities?.length === 0 &&
    filterOptions?.class?.length === 0;
  
  // Obtener el startDate y restar un día
  const startDate = new Date(filterOptions.startDate);
  startDate.setDate(startDate.getDate() - 1);
  
  // Obtener el mes de startDate (se suma 1 porque getMonth devuelve valores de 0 a 11)
  const startMonth = startDate.getMonth() + 1;
  
  // Obtener el mes actual y sumarle 1 día
  currentDate.setDate(currentDate.getDate() + 1);
  
  
  // Condición para mostrar el botón de descarga
  const showDownloadButton = startMonth !== currentMonth || !areDatesOnly;

  useEffect(() => {
    if (data && data?.length > 0) {
      const dataWater = data?.filter((d) => {
        return (
          (d?.class === "Energia Consumida" ||
           d?.class === "Gas Cons. a 9300 Kcal/m3"  ||
           d?.class === "Agua Consumida") &&
           (d?.consumption >= 0)
        );
      });

      const waterOffice = sumConsumptionsByLocation(dataWater, offices);
      // Calcular el CO2 y agregarlo a waterOffice
      const waterWithCO2 = waterOffice?.map((office) => {
        let multiplicador = 1; // Valor predeterminado

        if (office.medicion === "ELECTRICIDAD"  && office?.class === "Energia Consumida" ) {
          multiplicador = 0.31; // Factor de conversión para CO2 en electricidad (kg CO2/kWh)
        } else if (office.medicion === "AGUA"  && office?.class === "Agua Consumida") {
          multiplicador = 0.12132; // Factor de conversión para CO2 en agua
        } else if (office.medicion === "GAS NATURAL"  && office?.class === "Gas Cons. a 9300 Kcal/m3") {
          multiplicador = 2.17; // Factor de conversión para CO2 en gas
        }
        let CO2 = office?.consumption * multiplicador;
        CO2 = CO2 / 1000000
        return { ...office, CO2 };
      });
  
      setCo2Consumption(waterWithCO2);
      let totalCO2 = waterWithCO2.reduce((acc, office) => acc + office.CO2, 0);
      // totalCO2 =  totalCO2 / 1000000
      setTotalCO2(totalCO2);
    }
  }, [data, filterOptions]);

  return (
    <>
      {dataFetching && (
        <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: "9999", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem" }}>
              <span className="visually-hidden">Cargando...</span>
            </div>
            <p style={{ marginTop: "10px" }}>Cargando...</p>
          </div>
        </div>
      )}
      <div className="template-bar" style={{ opacity: dataFetching ? "0.5" : "1" }}>
        <div className="template-title">
          <p className="title">RESUMEN CORPORATIVO</p>
          <ul className="cont-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                REPORTES
                <FaBars />
              </a>
              <ul className="dropdown-menu" aria-label="Board">
              { showDownloadButton && (
                    <button>
                      {data ? (
                        <BillsExcel data={formatInvoiceDataForExcel(data)} date={filterOptions}/>
                      ) : null}
                    </button>
                )}
                <li>
                {co2Fetching || !co2dataFetching? (
                <div>
                      <button className="btn-reportDisable" disabled>Generando reporte prorrateo...</button> 
                      </div>
                  ) : (
                    <div>
                        <SostentabilidadExcel data={consumoSostentabilidad} filter={filterOptions?.companies !== undefined ? filterOptions?.companies : []} />
                      </div>
                  )}
                </li> 
                <li style={{ width: "95%" }}>
                  <button>
                    <DownloadExcel date ={filterOptions}  />
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="template-filters">
          <p>
          {
          filterOptions?.startDate || filterOptions?.endDate ? 
            `Fecha: ${
              filterOptions?.startDate ? 
                new Date(new Date(filterOptions?.startDate).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            } - ${
              filterOptions?.endDate ? 
                new Date(new Date(filterOptions?.endDate).getTime() - (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            }` 
          : ""
        }
          </p>
          <p>
            {filterOptions.provinces?.length > 0
              ? `Provincias: ${nameProvinces}`
              : null}
          </p>
          <p>
            {filterOptions.companies?.length > 0
              ? `Sucursales: ${filterOptions?.companies}`
              : null}
          </p>
          <p>
            {filterOptions.utilities?.length > 0
              ? `Servicio: ${filterOptions?.utilities}`
              : null}
          </p>
          <p>
            {filterClass?.class?.length > 0
              ? `Conceptos: ${filterClass?.class}`
              : null}
          </p>
        </div>
      </div>
      <div className="parent-corpSum" id="div1-corpSum">
        <div className="div1-corpSum panel firstWidget">
          <h4 className="text-value">{countBills?.length}</h4>
          <label className="text-title">Facturas procesadas</label>
        </div>
        <div className="div3-corpSum panel firstWidget">
          <h4 className="text-value">${formattedTotalCost}</h4>
          <label className="text-title">Gasto total en el período</label>
        </div>
        <div className="div4-corpSum panel firstWidget">
          <h4 className="text-value">{uniqueLocationNames?.length}</h4>
          <label className="text-title">Sucursales activas</label>
        </div>
        <div className="div5-corpSum panel firstWidget">
          <h4 className="text-value">{totalCO2?.toLocaleString('es-AR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          })} Ton CO2</h4>
          <label className="text-title">Emisión CO2 en el período</label>
        </div>
        <div className="div6-corpSum panel table-responsive" id="div6-corpSum">
          <BulletChart type={"costsOfficeCorporate"} data={locationCosts} />
        </div>
        <div className="div10-corpSum panel">
          <LineChart />
        </div>
        <div className="div11-corpSum panel">
          <LineChartWater />
        </div>
        <div className="div9-corpSum panel" id="div8-corpSum">
          <TableCorporate
            data={dataElectricity}
            co2={co2Consumption}
            totalCO2={totalCO2}
            title={"Consumos y emisión de CO2 por sucursal"}
          />
        </div>
        <div className="div7-corpSum panel table-responsive">
          <BarChart data={filteredConsump} type={"consumption"} filterClass={filterClass?.class}/>
        </div>
        <div className="div8-corpSum panel table-responsive">
          <BarChart data={data} type={"finance"}/>
        </div>
      </div>
    </>
  );
};

export default CorporateSummary;
