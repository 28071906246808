import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    messageError: null,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.messageError = null;
    },

    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
      state.messageError = null;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.messageError = action.payload;
    },
    sessionSuccess: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        exp: action.payload.exp,
        accessToken: action.payload.newToken,
        lastActivity: action.payload.lastActivity,
      };
    },
    logoutStart: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
      state.messageError = null;
    },
    fetchingComplete: (state) => {
      state.isFetching = false;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  sessionSuccess,
  logoutStart,
  fetchingComplete
} = loginSlice.actions;
export default loginSlice.reducer;
