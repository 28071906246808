import "./LineItemText.css";
import React, { useEffect, useState } from "react";

function validar(otherCharges) {
  let errors = {};
  const regex = /^[0-9;.,\-\s]+$/;

  if (otherCharges && !regex.test(otherCharges)) {
    errors.otherCharges =
      "Los valores deben ser numéricos. En caso de querer agregar varios separarlos con ; (punto y coma). No ingresar letras.";
  }

  return errors;
}

const LineItemText = ({ itemsBill, setInputs, inputs, errors, setErrors, extractedData, supplierName  }) => {
  const [showCharge, setShowCharge] = useState(false);

// Agrega un identificador único a cada elemento en itemsBillFiltered y inputs.lineItemCost, valida que el proveedor seleccionado sea igual al proveedor de los conceptos
const itemsBillFiltered = itemsBill?.filter((item) => {
  const isNotTotalFactura = item?.lineItemText !== "Total Factura";
  const isSupplierMatch = supplierName?.includes('FUSIONSOLAR')
    ? (item?.proveedor)?.toUpperCase() === supplierName?.toUpperCase()
    : true;
  return isNotTotalFactura && isSupplierMatch;
}).map((item, index) => ({ ...item, id: `item_${index}` }));

const updatedLineItemCost = itemsBillFiltered.map((item) => ({
  ...item,
  id: `item_${item.id}`, // Asegúrate de que el identificador sea único
}));

  const totalCost = inputs.lineItemCost?.reduce((acc, item) => {
    if (item.lineItemText !== "Total Factura") {
      const costWithoutComma = typeof item.cost === 'string' ? item.cost.replace(",", ".") : item.cost;
      const costValue = parseFloat(costWithoutComma);
  
      if (!isNaN(costValue)) {
        if (costValue === -1) {
          return acc; // Ignora el valor -1
        } else {
          return costValue >= 0 ? acc + costValue : acc - Math.abs(costValue);
        }
      }
    }
    return acc;
  }, 0);


  useEffect(() => {
    if (totalCost !== undefined && !isNaN(totalCost)) {
      const totalFacturaIndex = inputs.lineItemCost.findIndex(
        (item) => item.lineItemText === "Total Factura"
      );

      if (totalFacturaIndex !== -1) {
        const updatedLineItemCost = [...inputs.lineItemCost];
        updatedLineItemCost[totalFacturaIndex] = {
          ...updatedLineItemCost[totalFacturaIndex],
          cost: totalCost.toFixed(2).toString(),
        };

        setInputs({
          ...inputs,
          lineItemCost: updatedLineItemCost,
        });
      } else {
        const unitTotal = itemsBill.find((i) => i.class === "Total factura");
        setInputs({
          ...inputs,
          lineItemCost: [
            ...inputs.lineItemCost,
            {
              lineItemText: "Total Factura",
              class: "Total factura",
              consumption: "-1",
              cost: totalCost.toString(),
              units: unitTotal?.unit,
            },
          ],
        });
      }
    }
  }, [totalCost]);
  
  const handleOtherCharge = (e, itemName, itemClass, unit) => {
    e.preventDefault();
    let values = e.target.value;
    setErrors(validar(values));
    values = values.trim().split(";");
    if (values[values.length - 1] === "") {
      values.pop();
    }
    if (values[0] === "") {
      values.shift();
    }

    const total = values.reduce((total, parte) => {
      const valueNumber = parseFloat(parte.replace(",", "."));

      if (!isNaN(valueNumber)) {
        return total + valueNumber;
      } else {
        //si no es número se ignora
        return total;
      }
    }, 0);

    const itemFoundIndex = inputs.lineItemCost.findIndex(
      (item) => item.lineItemText === itemName
    );

    if (itemFoundIndex !== -1) {
      //si existe le actualizo el valor
      const updatedLineItemCost = [...inputs.lineItemCost];
      updatedLineItemCost[itemFoundIndex] = {
        ...updatedLineItemCost[itemFoundIndex],
        cost: total?.toFixed(2).toString(),
      };

      setInputs({
        ...inputs,
        lineItemCost: updatedLineItemCost,
      });
    } else {
      setInputs({
        ...inputs,
        lineItemCost: [
          ...inputs.lineItemCost,
          {
            lineItemText: itemName,
            class: itemClass,
            cost: total?.toFixed(2).toString(),
            consumption: "-1",
            units: unit,
          },
        ],
      });
    }
  };

  // useEffect(() => {
  //   const updatedLineItemCost = itemsBillFiltered.map((item) => {
  //     const matchingItem = extractedData.consolidatedData?.find((data) => {
  //       const itemNombre = data.lineItemText.toLowerCase();
  //       const itemText = item.lineItemText.toLowerCase();
  //       return itemText.includes(itemNombre);
  //     });
  
  //     return {
  //       lineItemText: item.lineItemText,
  //       class: item.class,
  //       consumption: matchingItem?.consumption,
  //       units: item.consumptionUnit,
  //       cost: matchingItem?.cost,
  //     };
  //   });
  
  //   // Filter out objects with undefined cost or consumption
  //   const filteredLineItemCost = updatedLineItemCost.filter(
  //     (item) => item.cost !== undefined && item.consumption !== undefined
  //   );
  
  //   setInputs({
  //     ...inputs,
  //     lineItemCost: filteredLineItemCost,
  //   });
  // }, [extractedData]);
  

    const handleItemChange = (
      e,
      itemText,
      itemClass,
      costUnit,
      consumptionUnit
    ) => {
      let { name, value } = e.target;
      const updatedLineItemCost = [...inputs.lineItemCost];

      // Ajusta el valor si es necesario
      if (name === "consumption" && (itemClass === "Coseno fi" || itemClass === "Tangente fi") && value > 1) {
        value = 1;
      }

      // Convierte a minúsculas para comparación insensible a mayúsculas/minúsculas
      const itemTextLower = itemText.toLowerCase();

      const itemFoundIndex = updatedLineItemCost.findIndex(
        (item) => item.lineItemText.toLowerCase() === itemTextLower
      );

      if (itemFoundIndex !== -1) {
        const currentItem = updatedLineItemCost[itemFoundIndex];
        currentItem[name] = value; // Actualiza el valor correspondiente

        if ((!("consumption" in currentItem) || currentItem.consumption === "") && currentItem.cost === "") {
          // Elimina el objeto si ambos campos son vacíos
          updatedLineItemCost.splice(itemFoundIndex, 1);
        } else {
          // Si no, solo actualiza el objeto
          updatedLineItemCost[itemFoundIndex] = currentItem;
        }

        setInputs({
          ...inputs,
          lineItemCost: updatedLineItemCost,
        });
      } else if (value !== "") {
        // Si el objeto no se encuentra y el valor no está vacío, crea uno nuevo
        const newItem = {
          lineItemText: itemText,
          class: itemClass,
          units: consumptionUnit && consumptionUnit !== "null" ? consumptionUnit.trim() : costUnit.trim(),
          [name]: value,
        };

        setInputs({
          ...inputs,
          lineItemCost: [...inputs.lineItemCost, newItem],
        });
      }
    };



  return (
    <>
      <div>
        <table className="table table-bordered">
          <thead className="itemText-thead">
            <tr>
              <th>CONCEPTO</th>
              <th>CONSUMO</th>
              <th>COSTO</th>
            </tr>
          </thead>
          <tbody>
          {itemsBillFiltered?.map((item, index) => (
            <tr className="itemText-row" key={item.id}>
          <td>
            {item.lineItemText} - {item.class}
          </td>
          <td>
                {item.consumptionUnit && item.consumptionUnit !== "null" ? (
                      <div className="itemColumn">
                      <input
                        type="number"
                        id={`consumption_${item.id}`}
                        value={inputs?.lineItemCost.find((input) => {
                          const inputText = input?.lineItemText?.toLowerCase()?.trim();
                          const itemText = item?.lineItemText?.toLowerCase()?.trim();
                          return inputText === itemText;
                        })?.consumption ?? ""}
                        name="consumption"
                        onChange={(e) =>
                          handleItemChange(
                            e,
                            item?.lineItemText,
                            item?.class,
                            item?.costUnit,
                            item?.consumptionUnit
                          )
                        }
                        placeholder={`Ingrese consumo`}
                        />{" "}
                      {item.consumptionUnit}
                    </div>
                    
                  ) : (
                      "No aplica"
                  )}
              </td>

              <td>
                  {item.costUnit && item.costUnit !== "null" ? (
                      <div className="itemColumn">
                          <input
                              type="number"
                              id={`cost_${item.id}`}
                              value={inputs?.lineItemCost.find((input) => {
                                const inputText = input?.lineItemText?.toLowerCase()?.trim();
                                const itemText = item?.lineItemText?.toLowerCase()?.trim();
                                return inputText === itemText;
                               })?.cost ?? ""}
                              name="cost"
                              onChange={(e) =>
                                  handleItemChange(
                                      e,
                                      item?.lineItemText,
                                      item?.class,
                                      item?.costUnit,
                                      item?.consumptionUnit
                                  )
                              }
                              placeholder="Ingrese costo"
                          />{" "}
                          {item.costUnit}$
                      </div>
                  ) : (
                      "No aplica"
                  )}
              </td>

              </tr>
            ))}
            {showCharge ? (
              <>
                <tr className="itemText-row">
                  <td className="">Otros recargos - Otros</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                      <input
                        type="text"
                        id="cost"
                        name="cost"
                        onChange={(e) =>
                          handleOtherCharge(e, "Otros recargos", "Otros", "AR")
                        }
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                      />
                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                  <td className="">Intereses por mora - Intereses</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                      <input
                        type="text"
                        id="cost"
                        name="cost"
                        onChange={(e) =>
                          handleOtherCharge(
                            e,
                            "Intereses por mora",
                            "Intereses",
                            "AR"
                          )
                        }
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                      />
                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                <td className="">Otros impuestos y tasas - Impuestos</td>
                <td>
                  <div className="itemColumn"></div>
                </td>
                <td>
                  <div className="itemColumn">
                    <input
                      type="text"
                      id="cost"
                      name="cost"
                      onChange={(e) =>
                        handleOtherCharge(e, "Otros impuestos y tasas", "Impuestos", "AR")
                      }
                      placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                    />
                    AR$
                  </div>
                </td>
              </tr>
                {errors.otherCharges && (
                  <span className="error-message">{errors.otherCharges}</span>
                )}
              </>
            ) : null}
          </tbody>
        </table>
      </div>
      {!showCharge ? (
        <button
          className="btn-charges"
          type="button"
          onClick={() => setShowCharge(!showCharge)}
        >
          + Agregar recargos
        </button>
      ) : null}
      <div className="info-price">
        <label>EL TOTAL DE LA FACTURA ES: </label>
        <p>$ {totalCost?.toFixed(2)}</p>
      </div>
    </>
  );
};

export default LineItemText;