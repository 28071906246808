import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BulletChart = ({ type, data }) => {
  let labels = [];
  let dataValues = [];

  //si recibe costsOffice es para mostrar el gráfico de consumo por sucursal dentro del dash corporativo
  if (type === "costsOffice" || type === "costsOfficeCorporate") {
    const locationCostsArray = Object.entries(data).map(
      ([locationName, cost]) => ({
        locationName:  locationName === "undefined" ? null : locationName,
        cost
      })
    );

    
    let sortedLocationCostsArray = [...locationCostsArray].sort(
      (a, b) => b.cost - a.cost
      );

    if(type === "costsOfficeCorporate"){
      sortedLocationCostsArray = sortedLocationCostsArray?.slice(0,10)
    }

    const sortedLocationCosts = sortedLocationCostsArray?.reduce(
      (acc, { locationName, cost }) => {
        acc[locationName] = cost;
        return acc;
      },
      {}
    );

    // Verificar la existencia de datos
    if (sortedLocationCosts) {
      labels = Object.keys(sortedLocationCosts);
      dataValues = Object.values(sortedLocationCosts);
    }
  } else if (type === "waterCons") {
    // Filtrar los objetos dentro de data donde la propiedad units sea "m3"
    const filteredData = data?.filter((item) => (item?.units)?.toUpperCase() === "M3");
    // Ordenar el array de datos filtrados por consumo de agua de mayor a menor y seleccionar los 10 primeros
    const sortedWaterConsArray = filteredData
      ?.sort((a, b) => b.consumption - a.consumption)
      .slice(0, 10);

    // Extraer las etiquetas (nombres de las sucursales) y los valores de consumo de agua
    labels = sortedWaterConsArray?.map((item) => item.locationName);
    dataValues = sortedWaterConsArray?.map((item) => item.consumption);
  } else if (type === "CO2Cons") {
    //Acá ordeno (sort) y corto (slice) el array para mostrar solo las 10 con mayor consumo. Modificar o quitarlo si se quieren mostrar más
    const sortedCO2ConsArray = data?.sort((a, b) => b.CO2 - a.CO2).slice(0, 10);

    labels = sortedCO2ConsArray?.map((item) => item?.locationName);
    dataValues = sortedCO2ConsArray?.map((item) => (item?.CO2));
  }

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text:
          type === "costsOffice"
          ? "Gasto por sucursal"
          : type === "costsOfficeCorporate"
          ? "Top 10 sucursales con mayor gasto"
          : type === "waterCons"
          ? "Top 10 mayor consumo de agua (m3)"
          : type === "CO2Cons"
          ? "Top 10 mayor consumo de CO2"
          : "",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context?.parsed?.x;
            const formattedValue = value?.toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return label + formattedValue;
          },
        },
      },
    },
    scales: {
      x: {
        min: 0,
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            if (type === "costsOffice" || type === "costsOfficeCorporate") {
              return "$" + value?.toFixed(2);
            } else {
              return value?.toFixed(2);
            }
          },
        },
        min: dataValues && dataValues?.length > 1 ? Math.min(...dataValues) : 0,
        max: dataValues ? Math.max(...dataValues) : "",
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 8,
          },
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 0,
      },
    },
  };

  return (
    labels &&
    dataValues && (
      <>
        <div style={{ height: `${labels.length * 12}px`, minHeight: type === "costsOfficeCorporate" ? "300px" : "650px" }}>
          <Bar
            data={{
              labels,
              datasets: [
                {
                  label:
                    type === "costsOffice" || type === "costsOfficeCorporate"
                      ? "Gasto AR$ "
                      : type === "waterCons"
                      ? "Consumo m3: "
                      : "CO2Cons" 
                      ? "CO2: ": " ",
                  backgroundColor: "rgba(9, 64, 116, 0.2)",
                  borderColor: "rgba(9, 64, 116, 1)",
                  borderWidth: 1,
                  barThickness: 8,
                  maxBarThickness: 20,
                  data: dataValues,
                },
              ],
            }}
            options={options}
          />
        </div>
      </>
    )
  );
};

export default BulletChart;
