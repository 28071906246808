import React from 'react'

const ListsInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h4 className="offcanvas-title" id="offcanvasRightLabel">Listas</h4>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div>
          <h6>Usuarios</h6>
          <ul>
            <li>Listado de usuarios: Puedes ver, editar y eliminar. Recuerda que si eliminas un usuario no podrás recuperar la información de este.</li>
            <li>Nuevo usuario: Puedes crear un nuevo usuario.</li>
            <li>Políticas de clave: En esta sección puedes editar las políticas de clave, cuántos dígitos deben tener, validez, carácteres especiales.</li>
            <li>Actividad de usuarios: Podrás ver la actividad de inicio de sesión de los usuarios, a que hora se loguearon, si alguien intentó loguearse con una clave errónea o un usuario que no existe.</li>
          </ul>
        </div>
        <div>
          <h6>Sucursales</h6>
          <ul>
            <li>Listado de sucursales: Puedes ver el listado de sucursales.</li>
            <li>Asignar proveedor: Puedes asignarle un nuevo proveedor.</li>
            <li>Editar: Puedes editar la información general de la sucursal. También podrás ver y cambiar el estado de los proveedores que tiene asignados.</li>
            <li>Nueva sucursal: Podrás crear una nueva sucursal.</li>
            <li>Reporte por sucursal: Puedes descargar el listado de sucursales.</li>
          </ul>
        </div>
        <div>
          <h6>Proveedores</h6>
          <ul>
            <li>Listado de proveedores: Puedes ver el listado de proveedores y editarlos.</li>
            <li>Nuevo proveedor: Podrás crear un nuevo proveedor.</li>
            <li>Reporte por proveedor: Puedes descargar el listado de proveedores.</li>
          </ul>
        </div>
        <div>
          <h6>Proveedores asignados</h6>
          <ul>
            <li>Listado: Puedes ver las relaciones entre sucursales y proveedores. También podrás editar cada relación, cambiar número de cuenta, medidor, etc.</li>
            <li>Reporte por proveedor-sucursal: Puedes descargar todo el listado de relaciones proveedor-sucursal.</li>
          </ul>
          <p>Recuerda que para asignarle un proveedor a una sucursal debes hacerlo desde el listado de sucursales.</p>
        </div>
        <div>
          <h6>Items de factura</h6>
          <ul>
            <li>Listado de conceptos: Puedes ver el listado de conceptos, editarlos y eliminarlos. Cuenta con filtro por proveedor.</li>
            <li>Nuevo item: Podrás crear un nuevo concepto.</li>
            <li>Reporte por items: Puedes descargar el listado de conceptos.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ListsInfo