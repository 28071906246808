// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-dark{
  background-color: #1E3231;
  color: #fff;
}

.footer-light{
  background-color: #8d8d8d;
  color: #fff;
}


footer p {
  font-size: 14px;
  margin: auto;
}


`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;;AAGA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":["footer {\n  padding: 20px;\n  text-align: center;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n\n.footer-dark{\n  background-color: #1E3231;\n  color: #fff;\n}\n\n.footer-light{\n  background-color: #8d8d8d;\n  color: #fff;\n}\n\n\nfooter p {\n  font-size: 14px;\n  margin: auto;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
