import { utils, writeFile } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";
import { parse, format, isValid } from 'date-fns';
import { useSelector } from 'react-redux';

export default function BillsExcel({ data, date }) {
  const user = useSelector((state) => state.user?.currentUser);

  const downloadExcel = () => {
    if (data && Array.isArray(data)) {
      const wb = utils.book_new();

      if (Array.isArray(data)) {
        const totalCost = data?.reduce((sum, item) => sum + Number(item?.costo?.replace('$', '').trim()) || 0, 0);

        const classTotals = data?.reduce((acc, item) => {
          const className = item?.class || 'Other';
          acc[className] = (acc[className] || 0) + Number(item?.costo?.replace('$', '').trim()) || 0;
          return acc;
        }, {});

        const dataAsArray = data
          .filter(item => { 
            return item &&
                   item["nombre del sitio"] !== "" && item["nombre del sitio"] !== undefined &&
                   item?.utilidad !== "" && item?.utilidad !== undefined &&
                   item?.proveedor !== "" && item?.proveedor !== undefined &&
                   item?.factura !== "" && item?.factura !== undefined && 
                   item?.m2 !== "" && item?.m2 !== undefined && 
                   item?.class !== "" && item?.class !== undefined &&
                   item?.lineItemText !== "" && item?.lineItemText !== undefined &&
                   item?.emision !== "" && item?.emision !== undefined && 
                   item?.lecturaActual !== "" && item?.lecturaActual !== undefined && 
                   item?.lecturaAnterior !== "" && item?.lecturaAnterior !== undefined && 
                   item?.vencimiento !== "" && item?.vencimiento !== undefined 
          })
          .map(item => {
            const factura = item?.factura;
            const nroCuenta = parseInt(item?.nroCuenta) || 0;
            const emision = item?.emision;
            const vencimiento = item?.vencimiento;
            const lecturaActual = item?.lecturaActual;
            const lecturaAnterior = item?.lecturaAnterior;

            // Function to handle the 'Consumo KW' field assignment
            function handleConsumoKW(consumo) {
              if (consumo === null || consumo === undefined || isNaN(parseFloat(consumo))) {
                return "N/A";
              }
              const number = parseFloat(consumo);
              if (isNaN(number)) {
                return "N/A";
              }
              return number;
            }

            // Parse the month from the 'vencimiento' field
            const parsedDate = parse(vencimiento, 'dd-MMMM-yy', new Date());
            const monthNumber = isValid(parsedDate) ? format(parsedDate, 'M') : "Invalid date";

            const baseObject = {
              "nombre del sitio": item["nombre del sitio"],
              Utilidad: item?.utilidad,
              Proveedor: item?.proveedor,
              NroCuenta: nroCuenta,
              Factura: factura,
              M2: item?.m2,
              Clase: item?.class,
              Concepto: item?.lineItemText,
              "Lectura anterior": lecturaAnterior,
              "Lectura actual": lecturaActual,
              Emision: emision,
              Vencimiento: vencimiento,
              "Consumo (KW / kWh)": handleConsumoKW(item?.consumo),
              "Costo $": parseFloat(item?.costo?.replace('$', '').trim()) || 0,
            };

            if (user?.privilege === 'master' || user?.privilege === 'editor') {
              baseObject["Mes Vencimiento"] = monthNumber;
            }

            return baseObject;
          });

        dataAsArray.push({
          "nombre del sitio": "Costo Total",
          Utilidad: "",
          Proveedor: "",
          NroCuenta: "",
          Factura: "",
          M2: "",
          Clase: "",
          Concepto: "",
          "Lectura anterior": "",
          "Lectura actual": "",
          Emision: "",
          Vencimiento: "",
          "Consumo (KW / kWh)": "",
          "Costo $": totalCost.toFixed(2),
        });

        let summaryRowIndex = dataAsArray?.length + 2;
        Object.keys(classTotals).forEach(className => {
          dataAsArray.push({
            "nombre del sitio": "Total " + className,
            Utilidad: "",
            Proveedor: "",
            NroCuenta: "",
            Factura: "",
            M2: "",
            Clase: "",
            Concepto: "",
            "Lectura anterior": "",
            "Lectura actual": "",
            Emision: "",
            Vencimiento: "",
            "Consumo (KW / kWh)": "",
            "Costo $": classTotals[className].toFixed(2),
          });
          summaryRowIndex++;
        });

        dataAsArray.push({
          "nombre del sitio": "Fechas:",
          Utilidad: `desde ${date?.startDate}`,
          Proveedor: `hasta ${date?.endDate}`,
          NroCuenta: "",
          Factura: "",
          M2: "",
          Clase: "",
          Concepto: "",
          "Lectura anterior": "",
          "Lectura actual": "",
          Emision: "",
          Vencimiento: "",
          "Consumo (KW / kWh)": "",
          "Costo $": "",
        });

        const ws = utils.json_to_sheet(dataAsArray);

        const columnWidths = dataAsArray?.map(item => item["nombre del sitio"]?.length ? item["nombre del sitio"]?.length : null);
        const maxColumnWidth = Math.max(...columnWidths);

        ws['!cols'] = [
          { wch: maxColumnWidth + 2 },
          { wch: 15 },
          { wch: 15 },
        ];
        utils.book_append_sheet(wb, ws);
      }

      writeFile(wb, `ReporteFacturas_${date?.startDate}-${date?.endDate}.xlsx`);
    }
  }

  return (
      <b onClick={downloadExcel}>
        <FaFileDownload /> Reporte de facturas
      </b>
  );
}
