import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutUtility = ({ data = [], filterClass = [] }) => {
  
  //agrupo por servicio, para mostrar un gráfico por c/u
  const groupedData = data?.reduce((acc, item) => {
    const { utilityType, supplier, consumption, lineItemText } = item;
    if (supplier && lineItemText !== "Total Factura") {
      const consump = parseFloat(consumption || 0);
      if (!isNaN(consump) && isFinite(consump) && consump !== null && consump !== " ") {
        if (!acc[utilityType]) {
          acc[utilityType] = {};
        }
        const supplierKey = supplier?.trim()?.split(" ")[0]?.toUpperCase();
        if (acc[utilityType][supplierKey]) {
          acc[utilityType][supplierKey] += consump;
        } else {
          acc[utilityType][supplierKey] = consump;
        }
      }
    }
    return acc;
  }, {});
  
  const chartsData = Object.keys(groupedData)?.map((utilityType) => {
    const suppliers = groupedData[utilityType];
    const labels = Object.keys(suppliers);
    const dataValues = Object.values(suppliers);

    return {
      utilityType,
      data: {
        labels: labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: [
              "#c3c8cc", // electricidad
              "#70be70b6", // agua
              "#eee454d5",
              "#0927ce65", 
              "#1dd6a894",
              "#86d61d94",
              "#cad61d8a",
              "#d68c1d8a",
              "#d6291d8a",
              "#1d5ed68a",
              "#2ca1be8a"
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: Object.keys(groupedData)?.length === 1 ? true : false,
            position: "right",
            labels: {
              usePointStyle: true,
              font: {
                size: 8
              },
            },
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context?.label || '';
                const value = context?.parsed;
                const formattedValue = value ? value?.toLocaleString('es-AR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) : null;

                const unit = (utilityType === "GAS NATURAL" || utilityType === "AGUA") ? "m3" : (utilityType === "ELECTRICIDAD" && filterClass?.length === 0) ? "kWh" : data[0]?.units || '';
                return `${formattedValue} ${unit}`;
              }
            }
          },
          title: {
            display: true,
            text: utilityType === "ELECTRICIDAD" ? "Consumos por proveedor - Electricidad" : utilityType === "GAS NATURAL" ? "Consumos por proveedor - Gas" : utilityType === "AGUA" ? "Consumos por proveedor - Agua" : "Consumos",
            padding: {
              bottom: 10  
            },
          },
        },
        layout: {
          padding: 0,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        responsive:true,
        scales: {
          y: {
            display: false,
          },
        },
      },
    };
  });

  return (
    <div className="donuts-container">
      {chartsData?.length > 0 ? (
        chartsData?.map((chart, index) => (
          <div key={index} className="cont-donuts">
            <Doughnut data={chart?.data} options={chart?.options} style={{width: chartsData?.length === 1 ? '300px' : '100%' }}/>
          </div>
        ))
      ) : (
        <p>No hay datos disponibles</p>
      )}
    </div>
  );
};

export default DonutUtility;
