// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-user-container {
    background: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 3% auto;
    text-align: left;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

 
  input[type="text"],
  input[type="email"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }
  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .form-group .password-toggle-button {
    position: absolute;
    top: 70%;
    right: 5%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #333; 
  }
  
  @media (max-width: 480px) {
    .edit-user-container {
      max-width: 90%;
      padding: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/EditUser/EditUser.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,2CAA2C;IAC3C,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;;EAGA;;;IAGE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,2BAA2B;IAC3B,eAAe;IACf,WAAW;EACb;;EAEA;IACE;MACE,cAAc;MACd,aAAa;IACf;EACF","sourcesContent":[".edit-user-container {\n    background: #f8f8f8;\n    border-radius: 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    max-width: 500px;\n    margin: 3% auto;\n    text-align: left;\n  }\n  \n  h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n\n \n  input[type=\"text\"],\n  input[type=\"email\"],\n  select {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  button {\n    background: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background: #0056b3;\n  }\n  .form-group {\n    position: relative;\n    margin-bottom: 20px;\n  }\n  \n  .form-group label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 8px;\n  }\n  \n  .form-group .password-toggle-button {\n    position: absolute;\n    top: 70%;\n    right: 5%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #333; \n  }\n  \n  @media (max-width: 480px) {\n    .edit-user-container {\n      max-width: 90%;\n      padding: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
