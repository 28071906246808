import { publicRequest } from "../../requestMeth";
import { editSupplierOfficeSuccess, supplierCompanyFailure, supplierCompanyStart, supplierCompanySuccess, supplierFailure, supplierStart, supplierSuccess } from "../reducers/supplierCompanyReducer"


export const getAllsupplierCompany = async (dispatch, token) => {
  dispatch(supplierStart());
  try {
    const res = await publicRequest.get(`/supplierCompany`, {
      headers: { token: `Bearer ${token}` }
    });
    dispatch(supplierSuccess(res.data))
    return res.data
  } catch (error) {
    dispatch(supplierFailure([]));
  }
};


export const assignSupplierCompany = async (dispatch, payload, idcompany, token) => {
  dispatch(supplierCompanyStart());
  try {
    const res = await publicRequest.post(`/supplierCompany/${idcompany}`, payload, {
      headers: { token: `Bearer ${token}` }
    });
    dispatch(supplierCompanySuccess(res.data));
  } catch (error) {
    dispatch(supplierCompanyFailure(error.response.data));
    throw error.response.data;
  }  
};

export const updateSupplierCompany = async (dispatch, payload, token) => {
  dispatch(supplierCompanyStart());
  try {
    const res = await publicRequest.put(`/supplierCompany`, payload, {
      headers: { token: `Bearer ${token}` }
    });
    return res.data;
  } catch (error) {
    dispatch(supplierCompanyFailure());
    return error.response.data
  }  
};

export const editSupplierOffice = async (dispatch, id, payload, token) => {
    dispatch(supplierCompanyStart());
  try {
      const res = await publicRequest.put(`/supplierCompany/edit/${id}`, payload, {
          headers: { token: `Bearer ${token}` }
      });
      dispatch(editSupplierOfficeSuccess(res.data));
  } catch (error) {
    dispatch(supplierCompanyFailure({}));
    return {error: true, message: "No se pudo modificar la relación proveedor-sucursal. Intentelo nuevamente."};
  }
};