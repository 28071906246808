import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/apiCalls/profileCall";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading"; 
import phProfile from "../../assets/img.png";
import { FaArrowLeft, FaPencilAlt } from "react-icons/fa";
import { resetPass } from "../../redux/apiCalls/profileCall";
import { Toast } from "../../helpers/alert";
import FloatingHelpIcon from "../../components/HelpSidebar/FloatingHelpIcon";


const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const userData = useSelector((state) => state.profile);
  const [isLoading, setisLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(phProfile);

  useEffect(() => {
    getUser(dispatch, id, user.currentUser?.accessToken).then((res) =>{
      if(res?.error){
        Toast.fire({
          icon: "error",
          title: res?.message,
        });
      }else{
        setisLoading(false)
      }
    }
    );
  }, [id, dispatch]);

  useEffect(() => {
    if (userData?.currentUser?.image) {
      setImageUrl(userData?.currentUser?.image);
    }
  }, [userData?.currentUser]);

  const uploadPhoto = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "snxj6u1f"); 
    setisLoading(true);
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dcebaxh03/upload?folder=bsm",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    if (file.secure_url !== null || file.secure_url !== undefined) {
      setImageUrl(file.secure_url);
      let image = { image: file.secure_url };
      await resetPass({
        dispatch,
        id,
        image,
        token: user.currentUser.accessToken,
      }); 
      getUser(dispatch, id, user.currentUser?.accessToken).then((res) =>
      setisLoading(false)
    );
    }
  };

  if (user?.currentUser === null || isLoading )
    return <Loading />;
  return (
    <>
      <div className="cont-profileinfo">
        <div className="list-group list-group-profile">
          <div className="cont-back" onClick={() => navigate(`/`)}>
            <i>
              <FaArrowLeft />
            </i>
            <p>
              Escritorio
            </p>
          </div> 
          <div className="cont-imgprofile">
            <p>
              Información de usuario
            </p>
            <div className="selectimage">
              <img
                className="image-button-container"
                src={imageUrl}
                alt="Your profile pic"
              />
              <input
                type="file"
                id="fileUpload"
                style={{ display: "none" }}
                onChange={uploadPhoto}
              />
              <label htmlFor="fileUpload" className="overlay-button">
                <FaPencilAlt />
              </label>
            </div>   
          </div>
          <div className="ind-info">
            <label>
              Usuario:
            </label>
            <li className="list-group-item list-group-item-action">
              {userData?.currentUser?.user}
            </li>
          </div>
          <div className="ind-info">
            <label>
              Privilegio:
            </label>
            <li className="list-group-item list-group-item-action">
              {userData?.currentUser?.privilege}
            </li>
          </div>
          <div className="ind-info">
            <label>
              Email:
            </label>
            <li className="list-group-item list-group-item-action">
              {userData?.currentUser?.mail}
            </li>
          </div>
          <div className="ind-info">
            <label>
              Teléfono:
            </label>
            <li className="list-group-item list-group-item-action">
              {userData?.currentUser?.phone}
            </li>
          </div>
          {userData?.currentUser?.privilege === "viewer" ? (
            <div className="ind-info">
              <label>
                Sucursales asignadas:
              </label>
              <div className="list-companies">
                {(userData?.currentUser?.names_companies)?.map((c) => (
                  <li className="list-group-item list-group-item-action" key={c}>
                    {c}
                  </li>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <FloatingHelpIcon infoHelp={"account"}/>
    </>
  );
};

export default Profile;
