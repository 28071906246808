import React from 'react'

const UploadBillInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">Facturas</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div>
          <h6>Ver últimas facturas</h6>
          <p>Se abrirá una ventana emergente mostrando las últimas facturas cargadas, podrás eliminarlas.</p>
          <p>Por defecto se muestran las del día en curso, puedes modificar eso con el filtro de fecha que tienes a su lado.</p>
        </div>
        <div>
          <h6>Carga de factura</h6>
          <p>Debes ir completando los datos con la información de la factura. A continuación se destacan puntos importantes:</p>
          <ul>
            <li>Selecciona sucursal y proveedor. Una vez que los selecciones, debajo verás el número de cuenta correspondiente(*).</li>
            <li>ID de factura: Acá debes colocar el id de la factura y dar ENTER.
            <br/>
            En caso de que la factura no exista, puedes continuar completando los campos.
            <br/>
            En caso de que la factura exista, aparecerá debajo del campo a qué número de cuenta corresponde. Es importante que valides que este coincida con el que aparece arriba mencionado (*). Si no coinciden y de igual forma creas la factura, se mezclarán los conceptos.
            </li>
            <li>Lista de conceptos: Según el proveedor que fue seleccionado, se mostrará el listado de conceptos correspondiente.</li>
            <li>Agregar recargos: Aquí se te habilitarán campos para agregar otros cargos. Puedes agregar varios valores separandolos con el signo punto y coma (;)</li>
          </ul>
          <p>IMPORTANTE: Al crearse una factura siempre se priorizará la unidad de consumo del concepto, si seleccionas 'N/A' tendrá esa unidad, solo en caso de que le hayas dejado la opción 'No corresponde consumo' se tomará la unidad AR de costo.</p>
        </div>
      </div>
    </div>
  )
}

export default UploadBillInfo