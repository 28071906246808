import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { createSupplier } from "../../redux/apiCalls/createSupplierCall";

const RegisterSupplier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({
    nombre: "",
    codificacion: "",
    servicio: "",
    accesoFacturas: "",
    usuario: "",
    contraseña: "",
    contacto: "",
    email: "",
    telefono: "",
    observaciones: "",
    reserva2: "",
    reserva1: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };


  function validar(inputs) {
    let errors = {};

    if (!inputs.nombre) {
      errors.nombre = "Por favor ingrese un nombre válido";
    } else if (!inputs.servicio) {
      errors.servicio = "Por favor ingrese un servicio válido";
    } else if (!inputs.codificacion)
      errors.codificacion = "Por favor ingrese una codificacion.";
    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let errors = validar(inputs);
    setErrors(errors);

    Swal.fire({
      title: "¿Está seguro que desea crear un nuevo proveedor?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed && !Object.keys(errors).length) {
        createSupplier(dispatch, inputs, user.accessToken)
        .then((res) => {
          if(res?.error){
            Toast.fire({
              icon: "error",
              title: res?.message,
            });
          }
          navigate(`/admin/lists?show=suppliers`);
        })
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo crear el proveedor. ${Object.values(errors)}`,
        });
      }
    });
  };

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=suppliers`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Agregar proveedor</h2>
      <form>
        <div className="form-group">
          <label htmlFor="nombre">Nombre del proveedor: </label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            placeholder="Ingrese número del proveedor"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="codificacion">Codificación del proveedor: </label>
          <input
            type="text"
            id="codificacion"
            name="codificacion"
            placeholder="Ingrese codificacion"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="servicio">Servicio del proveedor: </label>
          <select
            id="servicio"
            name="servicio"
            value={inputs.servicio}
            onChange={handleInputChange}
          >
            <option value="">PROVEEDOR</option>
            <option value="AGUA">AGUA</option>
            <option value="ELECTRICIDAD">ELECTRICIDAD</option>
            <option value="GAS">GAS</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="accesoFacturas">Acceso a las facturas: </label>
          <input
            type="text"
            id="accesoFacturas"
            name="accesoFacturas"
            placeholder="Ingrese cómo acceder a las facturas"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="usuario">Usuario para acceder: </label>
          <input
            type="text"
            id="usuario"
            name="usuario"
            placeholder="Ingrese usuario para acceder a la factura"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contraseña">Contraseña para acceder: </label>
          <input
            type="password"
            id="contraseña"
            name="contraseña"
            placeholder="Ingrese contraseña para acceder"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contacto">Contacto</label>
          <input
            type="text"
            id="contacto"
            name="contacto"
            placeholder="Ingrese contacto"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Ingrese email"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono</label>
          <input
            type="text"
            id="telefono"
            name="telefono"
            placeholder="Ingrese teléfono"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="observaciones">Observaciones</label>
          <input
            type="text"
            id="observaciones"
            name="observaciones"
            placeholder="Ingrese observaciones"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserva1">Reserva 1</label>
          <input
            type="text"
            id="reserva1"
            name="reserva1"
            placeholder="Ingrese reserva 1"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserva2">Reserva 2</label>
          <input
            type="text"
            id="reserva2"
            name="reserva2"
            placeholder="Ingrese reserva 2"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterSupplier;