import "./EditOffice.css";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCompanyById,
  updateCompany,
} from "../../redux/apiCalls/companiesCall";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { provinces } from "../../helpers/infoFilter";
import Loading from "../../components/Loading/Loading";
import { updateSupplierCompany } from "../../redux/apiCalls/supplierCompanyCall";

const EditOffice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.user.currentUser);
  const companyEdit = useSelector((state) => state.companies.companyEdit);
  const [isLoading, setisLoading] = useState(true);
  const [inputs, setInputs] = useState({});
  const [deleteSupplier, setDeleteSupplier] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [address, setAddress] = useState("");

  useEffect(() => {
    getCompanyById(dispatch, id, user?.accessToken).then((res) => {
      setInputs({
        ...inputs,
        estado: res?.status,
        supplier: res?.supplier,
        ciudad: res?.state
      });
      if(!res?.name){
        Toast.fire({
          icon: "error",
          title: "No se encontró la sucursal. Por favor, intente recargar la página.",
        });
      }
      setisLoading(false);
    });
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name !== "direccion") {
      setInputs({
        ...inputs,
        [name]: value.toUpperCase(),
      });
    } else {
      //En caso de que esté en el input de dirección ingresa acá, esta es la lógica para mosstrar opciones de autocompletado
      const query = value;
      setAddress(query);
      if (query.length > 2) {
        const response = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            query
          )}&key=3cd64b507a554cb88bc3e04a3b781f7b`
        );
        const data = await response.json();
        setSuggestions(data.results);
      } else {
        setSuggestions([]);
      }
      setInputs({
        ...inputs,
        direccion: value.toUpperCase(),
      });
    }
  };

  const handleClickAddress = (addressFormated) => {
    setInputs({
      ...inputs,
      direccion: addressFormated,
    });
  };

  const handleEditSupplier = (e, idSupplier) => {
    const { name, value } = e.target;

    // Actualizar el estado del proveedor específico
    const updatedSupplier = inputs.supplier.map((s) =>
      s.id === Number(idSupplier) ? { ...s, [name]: value } : s
    );

    // Agregar el proveedor actualizado a la lista de proveedores eliminados
    setDeleteSupplier([...deleteSupplier, { id: idSupplier, status: value }]);

    // Actualizar el estado con el nuevo array de proveedores
    setInputs((prevInputs) => ({
      ...prevInputs,
      supplier: updatedSupplier,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¿Está seguro de editar esta sucursal?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        if (companyEdit?.supplier != inputs?.supplier) {
          updateSupplierCompany(dispatch, deleteSupplier, user.accessToken)
          .then((res)=>{
            if(res?.error){
              Toast.fire({
                icon: "info",
                title: res?.message,
              });
            }
          })
        }
        updateCompany(
          dispatch,
          companyEdit?.id_company,
          inputs,
          user.accessToken
        ).then((res) => {
          if(res?.error){
            Toast.fire({
              icon: "error",
              title: "No se pudo editar la sucursal. Por favor, intente nuevamente.",
            });
          }
          navigate(`/admin/lists?show=companies`);
        });
      } else {
        Toast.fire({
          icon: "info",
          title: "Cambios descartados",
        });
      }
    });
  };

  if (isLoading) return <Loading />;
  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=companies`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Editar sucursal</h2>
      <form>
        <div className="form-group">
          <label htmlFor="name">Nombre: </label>
          <input
            type="text"
            id="name"
            name="nombreSucursal"
            placeholder={companyEdit?.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="branch_code">Código: </label>
          <input
            type="text"
            id="branch_code"
            name="codigoSucursal"
            placeholder={companyEdit?.branch_code}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Dirección: </label>
          <input
            type="text"
            id="address"
            name="direccion"
            placeholder={companyEdit?.address}
            value={address}
            onChange={handleInputChange}
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setAddress(suggestion.formatted);
                    setSuggestions([]);
                    handleClickAddress(suggestion.formatted);
                  }}
                >
                  {suggestion.formatted}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="ciudad">Provincia: </label>
          <select
            id="ciudad"
            name="ciudad"
            value={inputs?.ciudad}
            onChange={handleInputChange}
          >
            {provinces?.map((p) => (
              <option key={p.name} value={p.value}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="m2">M²: </label>
          <input
            type="text"
            id="m2"
            name="m2"
            placeholder={companyEdit?.m2}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="manager_contact">Contacto del responsable: </label>
          <input
            type="text"
            id="manager_contact"
            name="ContactoResponsableSuc"
            placeholder={companyEdit?.manager_contact}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="estado">Estado</label>
          <select
            id="estado"
            name="estado"
            value={inputs?.estado || ""}
            onChange={handleInputChange}
          >
            <option value="ACTIVA">Habilitada</option>
            <option value="BAJA">Baja</option>
            <option value="EN OBRA, PAGA ELECTRICIDAD">
              En obra, paga electricidad
            </option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="estado">Proveedores</label>
          <div className="cont-supplier">
            {inputs.supplier?.map((s) => (
              <div key={s.id}>
                <div className="info-supplier">
                  {s.name}
                  <a
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${s.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${s.id}`}
                  >
                    <FaInfoCircle />
                  </a>
                </div>
                <div className="collapse" id={`collapse${s.id}`}>
                  <div className="card card-body">
                    <p>Proveedor: {s.name}</p>
                    <p>Número de cuenta: {s.accountNumber}</p>
                    <p>Número de contrato: {s.contractNumber}</p>
                    {user?.privilege !== "admin" ? (
                      <div>
                        <label htmlFor="estado">Estado</label>
                        <select
                          id="estado"
                          name="status"
                          value={s?.status || ""}
                          onChange={(e) => handleEditSupplier(e, s.id)}
                        >
                          <option value="ACTIVO">Habilitado</option>
                          <option value="BAJA">Baja</option>
                        </select>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Guardar Cambios
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditOffice;
