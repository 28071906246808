import { createSlice } from "@reduxjs/toolkit";

const loggersSlice = createSlice({
  name: "loggers",
  initialState: {
    loggers: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    loggersStart: (state) => {
      state.isFetching = true;
    },
    loggersSuccess: (state, action) => {
      state.loggers = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    loggersFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutInfologgers: (state) => {
      state.loggers = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  loggersStart,
  loggersSuccess,
  loggersFailure,
  logoutInfologgers,
} = loggersSlice.actions;

export default loggersSlice.reducer;
