import "./Audit.css"
import React, { useState } from 'react';
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AuditReport from "../../components/Reports/AuditReports/AuditReport";
import AuditPowerReport from "../../components/Reports/AuditReports/AuditPowerReport";
import AuditEnergyReport from "../../components/Reports/AuditReports/AuditEnergyReport";

const Audit = () => {
  const navigate = useNavigate();
  const suppliers = useSelector((state) => state.supplier.supplier);
  const [filters, setFilters] = useState({
    year: '',
    service: '',
    supplierSelected: '',
  });

  const years = ["2023", "2024"];

  // Crear opciones de años para react-select
  const yearOptions = years.map(year => ({
    value: year,
    label: year,
  }));

  // Agrupar proveedores por 1er palabra e ignorar may 
  const groupedSuppliers = suppliers?.reduce((acc, supplier) => {
    const firstWord = supplier?.nombre?.trim().split(' ')[0].toUpperCase();
    if (!acc[firstWord]) {
      acc[firstWord] = { nombre: firstWord };
    }
    return acc;
  }, {});
  

  let supplierOptions = Object.values(groupedSuppliers).map(supplier => ({
    value: supplier.nombre,
    label: supplier.nombre,
  }));
  
  supplierOptions = [
    ...supplierOptions
  ]

  const handleYearChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      year: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleSupplier = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      supplierSelected: selectedOption ? selectedOption.value : '',
    }));
  };


  return (
    <div className='users-list'>
      <div
        className="cont-back cont-back-users"
        onClick={() => {
          navigate(`/`);
        }}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Escritorio</p>
      </div>
      <div>
        <h5>AUDITORÍA</h5>
        <p className='users'>Selecciona año y proveedor para la descarga de reportes</p>
        <div className="audit-filters">
          <div className="audit-select">
            <Select
              defaultValue={null}
              isMulti={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              controlShouldRenderValue={true}
              placeholder="Año"
              options={yearOptions}
              components={{ DropdownIndicator: () => <FaChevronDown /> }}
              className={`filter-button-selected`}
              onChange={handleYearChange}
              value={yearOptions.find(option =>
                option.value === filters.year
              )}
            />
          </div>
          <div className="audit-select">
            <Select
              defaultValue={null}
              isMulti={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              controlShouldRenderValue={true}
              placeholder="Proveedor"
              options={supplierOptions}
              components={{ DropdownIndicator: () => <FaChevronDown /> }}
              className={`filter-button-selected`}
              onChange={handleSupplier}
              value={supplierOptions.find(option =>
                option.value === filters.supplierSelected
              )}
            />
          </div>
        </div>
        {filters.year && filters.supplierSelected ?
        (
            <div className="audit-contdownload" >
              <AuditReport filters={filters}/>
              <AuditPowerReport filters={filters}/>
              <AuditEnergyReport filters = {filters} />
            </div>
        )
        : ""}
      </div>
    </div>
  );
};

export default Audit;
