import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Toast } from '../../helpers/alert';
import { deleteSupplier } from '../../redux/apiCalls/supplierCall';

const SupplierRow = ({ supplier}) => {
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleDelete(e) {
    e.preventDefault();
    Swal.fire({
       title:`¿Está seguro de que desea deshabilitar el proveedor ${supplier.nombre}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Sí, deshabilitar el proveedor.",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
           await deleteSupplier(dispatch, supplier.id, user.accessToken);

            navigate("/admin/lists?show=companies");

        } catch (error) {
          Toast.fire({
            icon: "error",
            title: "No se pudo deshabilitar el proveedor.",
          });
        }
      }
    });
  }

    return (
      <tr className='user-row'>
        <td>{supplier.id}</td>
        <td>{supplier.nombre}</td>
        <td>{supplier.codificacion}</td>
        <td>{supplier.servicio}</td>
        <td>{supplier.accesoFacturas}</td>
        <td>{supplier.observaciones}</td>
        <td> 
        {supplier.estado === "Habilitado" ? (
          <p className="data-text enable enable-user">HABILITADO</p>
        ) : (
          <p className="data-text enable disable-user">DESHABILITADO</p>
        )}
            </td>
        <td className='cont-actions'>
        {user.privilege === "master" || user.privilege === "editor" ? (
              <>
              <div className="btnUser-edit btn-actions"
              idsupplier={supplier.id}
              onClick={() =>
                navigate(`/supplier/${supplier.id}`)
              }>
                  <FaEdit />
                  <p>Editar</p>
              </div>
              </>
          ) : (
            "No hay acciones permitidas"
          )}
        </td>
      </tr>
    );
  };

  export default SupplierRow;