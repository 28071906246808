import React from "react";
import html2canvas from "html2canvas";
import './Report.css';
import { FaFileDownload } from "react-icons/fa";
import { provinces } from "../../helpers/infoFilter";
import jsPDF from 'jspdf';

let nameProvinces;

const ReportSostentabilidadPdf = ({ filterOptions }) => {
  nameProvinces = filterOptions.provinces.map(value => {
    const province = provinces.find(p => p.value === value);
    return province ? province.name : value;
  }).join(", ");

  const captureDivAsImage = async (divId) => {
    const divToCapture = document.getElementById(divId);
    
    // Guardar estilos originales
    const originalStyles = {
      div5: divToCapture.querySelector('#div5-sustDash').style.cssText,
      div7: divToCapture.querySelector('#div7-sustDash').style.cssText,
    };

    // Aumentar el ancho de los divs 5 y 7 para ocupar los espacios de divs 8 y 4
    const div5 = divToCapture.querySelector('#div5-sustDash');
    const div7 = divToCapture.querySelector('#div7-sustDash');

    div5.style.width = '100%';
    div5.style.gridColumn = 'span 2';
    div7.style.width = '100%';
    div7.style.gridColumn = 'span 2';

    // Ocultar los elementos que deseas ocultar
    const elementsToHide = [
      document.getElementById('div8-sustDash'),
      document.getElementById('div4-sustDash')
    ];
    elementsToHide.forEach(element => {
      if (element) {
        element.style.display = 'none';
      }
    });

    const canvas = await html2canvas(divToCapture);

    // Restaurar los elementos después de la captura
    elementsToHide.forEach(element => {
      if (element) {
        element.style.display = '';
      }
    });

    // Restaurar estilos originales
    div5.style.cssText = originalStyles.div5;
    div7.style.cssText = originalStyles.div7;

    return canvas.toDataURL("image/png");
  };

  const handlePdfDownload = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    let yCoordinate = 10;

    pdf.text('SOSTENTABILIDAD', 10, yCoordinate);
    yCoordinate += 10;

    const additionalTextLines = [
      filterOptions.startDate ? `Fechas: ${filterOptions.startDate} - ${filterOptions.endDate}` : '',
      filterOptions.provinces.length > 0 ? `Provincias: ${nameProvinces}` : '',
      filterOptions.companies.length > 0 ? `Sucursales: ${filterOptions.companies.join(', ')}` : '',
      filterOptions.utilities.length > 0 ? `Servicio: ${filterOptions.utilities.join(', ')}` : ''
    ];

    additionalTextLines.forEach(line => {
      if (line) {
        pdf.setFontSize(8);
        pdf.text(line, 12, yCoordinate);
        yCoordinate += 5; // Adjust y-coordinate for each line
      }
    });

    const divsToCapture = [
      "divSostentabilidad",
    ];

    await Promise.all(divsToCapture.map(async (divId) => {
      const imageDataUrl = await captureDivAsImage(divId);
      const imgProps = pdf.getImageProperties(imageDataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Descontando márgenes
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imageDataUrl, 'PNG', 10, yCoordinate, pdfWidth, pdfHeight);
      yCoordinate += pdfHeight + 10; // Adjust y-coordinate for the image
    }));

    pdf.save("report_sostentabilidad.pdf");
  };

  return (
    <b onClick={handlePdfDownload}>
      <FaFileDownload />
      Descargar PDF
    </b>
  );
};

export default ReportSostentabilidadPdf;