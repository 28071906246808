// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-app{
  background-color: #e7e7e7;
  border-bottom: 1px solid #c9d2da;
  padding: 0;
}

.nav-link-custom{
  text-decoration: none;
  color: #515356;
  display: flex;
  align-items: center;
}

.nav-link-custom:hover {
  background: #cad4e0;
  color: #515356;  
}

.image-logo{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-logo p{
  color: #515356;
  font-size: 10px;
  margin: 0;
}

.dropdown-toggle .fa {
  display: inline-block;
  font: normal normal normal 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.img-profile {  
  border-radius: 50%;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".navbar-app{\n  background-color: #e7e7e7;\n  border-bottom: 1px solid #c9d2da;\n  padding: 0;\n}\n\n.nav-link-custom{\n  text-decoration: none;\n  color: #515356;\n  display: flex;\n  align-items: center;\n}\n\n.nav-link-custom:hover {\n  background: #cad4e0;\n  color: #515356;  \n}\n\n.image-logo{\n  margin-left: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.image-logo p{\n  color: #515356;\n  font-size: 10px;\n  margin: 0;\n}\n\n.dropdown-toggle .fa {\n  display: inline-block;\n  font: normal normal normal 14px;\n  font-size: inherit;\n  text-rendering: auto;\n  -webkit-font-smoothing: antialiased;\n}\n\n.img-profile {  \n  border-radius: 50%;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
