// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cont-pass{
    background: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 5% auto 7%;
    text-align: left;

}

.btn-change {
    color: #fff;
    background-color: #457b9d;
    border-color: #1E3231;
    border-radius: 0;
  }
  .btn-change:hover {
    color: #fff;
    background-color: #094074;
    border-color: #1E3231;
  }
  .btn-change:focus, .btn-change.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  .btn-change.disabled, .btn-change:disabled {
    background-color: #007bff;
    border-color: #007bff;
  }

.upd-pass-footer{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}

.list-group-pass li{
  width: 60%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Password/ConfigPassword.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,2CAA2C;IAC3C,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,yBAAyB;IACzB,qBAAqB;EACvB;EACA;IACE,+CAA+C;EACjD;EACA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;AAEF;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,6BAA6B;AACjC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".cont-pass{\n    background: #f8f8f8;\n    border-radius: 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    max-width: 500px;\n    margin: 5% auto 7%;\n    text-align: left;\n\n}\n\n.btn-change {\n    color: #fff;\n    background-color: #457b9d;\n    border-color: #1E3231;\n    border-radius: 0;\n  }\n  .btn-change:hover {\n    color: #fff;\n    background-color: #094074;\n    border-color: #1E3231;\n  }\n  .btn-change:focus, .btn-change.focus {\n    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);\n  }\n  .btn-change.disabled, .btn-change:disabled {\n    background-color: #007bff;\n    border-color: #007bff;\n  }\n\n.upd-pass-footer{\n    width: 50%;\n    margin: auto;\n    display: flex;\n    justify-content: space-evenly;\n}\n\n.list-group-pass li{\n  width: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
