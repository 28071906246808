import React from 'react'

const AccountInfo = () => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">Cuenta</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <p>Aquí podrás ver información de tu cuenta:</p>
            <ul>
                <li>Datos personales.</li>
                <li>El listado de sucursales asignadas, es decir, de las que podrás ver información.</li>
                <li>También puedes modificar tu imagen de perfil.</li>
            </ul>
        </div>
    </div>
  )
}

export default AccountInfo