import { utils, writeFile } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";

export default function SuppliersExcel({ suppliers }) {

  const downloadExcel = () => {
    if (suppliers && Array.isArray(suppliers)) {
      const wb = utils.book_new();

      if (Array.isArray(suppliers)) {
        

        const dataAsArray = suppliers.map(item => ({
          "Código Proveedor": item.id,
          Nombre: item.nombre !== "null" && item.nombre !== "" ? item.nombre : "N/A",
          Codificación: item.codificacion !== "null" && item.codificacion !== "" ? item.codificacion : "N/A",
          Servicio: item.servicio !== "null" && item.servicio !== "" ? item.servicio : "N/A",
          "Acceso facturas": item.accesoFacturas!== "null" && item.accesoFacturas !== "" ? item.accesoFacturas : "N/A",
          Usuario: item.usuario !== "null" && item.usuario !== "" ? item.usuario : "N/A",
          Contraseña: item.contraseña !== "null" && item.contraseña !== "" ? item.contraseña : "N/A",
          Contacto: item.contacto !== "null" && item.contacto !== "" ? item.contacto : "N/A",
          Email: item.email !== "null" && item.email !== "" ? item.email : "N/A",
          Telefono: item.telefono !== "null" && item.telefono !== "" ? item.telefono : "N/A",
          Observaciones: item.observaciones !== "null" && item.observaciones !== "" ? item.observaciones : "N/A",
          Reserva2: item.reserva2 !== "null" && item.reserva2 !== "" ? item.reserva2 : "N/A",
          Reserva1: item.reserva1 !== "null" && item.reserva1 !== "" ? item.reserva1 : "N/A",
          Estado: item.estado,
        }));
      

        const ws = utils.json_to_sheet(dataAsArray);

        const columnWidths = dataAsArray.map(item => item["Acceso facturas"].length);
        const maxColumnWidth = Math.max(...columnWidths);

        ws['!cols'] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: maxColumnWidth + 2 },
            { wch: 15 },
            { wch: 15 },
        ];
        utils.book_append_sheet(wb, ws);
      }

      writeFile(wb, `ReporteProveedor.xlsx`);
    }
  }

  return (
    <>
      <b onClick={downloadExcel} >
        <FaFileDownload /> Reporte por proveedor
      </b>
    </>
  );
}
