import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { createCompany } from "../../redux/apiCalls/companiesCall";
import { FaArrowLeft } from "react-icons/fa";
import { provinces } from "../../helpers/infoFilter";

const validar = (inputs) => {
  let errors = {};
  if (!inputs.codigoSucursal) {
    errors.codigoSucursal = "Por favor, ingrese código de sucursal.";
  } else if (!inputs.nombreSucursal) {
    errors.nombreSucursal = "Por favor, ingrese un nombre.";
  } else if (!inputs.direccion) {
    errors.direccion = "Por favor, ingrese dirección.";
  } else if (!inputs.ciudad) {
    errors.ciudad = "Por favor, seleccione una provincia.";
  }

  return errors;
};

const RegisterCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector((state) => state.companies.companies);
  const user = useSelector((state) => state.user.currentUser);
  const [errors, setErrors] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [address, setAddress] = useState("");
  const [inputs, setInputs] = useState({
    codigoSucursal: "",
    nombreSucursal: "",
    ciudad: "BA",
    direccion: "",
    m2: "",
    contactoResponsableSuc: "",
    estado: "ACTIVA",
  });

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name !== "direccion") {
      setErrors(
        validar({
          ...inputs,
          [name]: value.toUpperCase(),
        })
      );
      setInputs({
        ...inputs,
        [name]: value.toUpperCase(),
      });
    } else {
      //En caso de que esté en el input de dirección ingresa acá, esta es la lógica para mosstrar opciones de autocompletado
      const query = value;
      setAddress(query);
      if (query.length > 2) {
        const response = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            query
          )}&key=3cd64b507a554cb88bc3e04a3b781f7b`
        );
        const data = await response.json();
        setSuggestions(data.results);
      } else {
        setSuggestions([]);
      }
      setInputs({
        ...inputs,
        direccion: value.toUpperCase(),
      });
      setErrors(
        validar({
          ...inputs,
          [name]: value.toUpperCase(),
        })
      );
    }
  };

  const handleClickAddress = (addressFormated) => {
    setInputs({
      ...inputs,
      direccion: addressFormated,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const companyExists = companies.find(
      (u) =>
        u.name === inputs.nombreSucursal ||
        u.branch_code === inputs.codigoSucursal
    );

    Swal.fire({
      title: "¿Está seguro que desea crear una nueva sucursal?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed && !Object.keys(errors).length && !companyExists) {
        createCompany(dispatch, inputs, user.accessToken).then((res) => {
          if(res?.error){
            Toast.fire({
              icon: "error",
              title: res?.message
            });
          }
          navigate(`/admin/lists?show=companies`);
        });
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo crear la sucursal. ${
            companyExists
              ? "Ese nombre o código de sucursal ya existe."
              : Object.values(errors)
          }`,
        });
      }
    });
  };

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=companies`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Crear sucursal</h2>
      <form>
        <div className="form-group">
          <label htmlFor="name">Nombre: </label>
          <input
            type="text"
            id="name"
            name="nombreSucursal"
            placeholder="Ingrese nombre de sucursal"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="branch_code">Código: </label>
          <input
            type="text"
            id="branch_code"
            name="codigoSucursal"
            placeholder="Ingrese código de sucursal"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Dirección: </label>
          <input
            type="text"
            id="address"
            name="direccion"
            placeholder={inputs?.address}
            value={address}
            onChange={handleInputChange}
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setAddress(suggestion.formatted);
                    setSuggestions([]);
                    handleClickAddress(suggestion.formatted)
                  }}
                >
                  {suggestion.formatted}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="ciudad">Provincia: </label>
          <select
            id="ciudad"
            name="ciudad"
            value={inputs.ciudad}
            onChange={handleInputChange}
          >
            {provinces.map((p) => (
              <option key={p.name} value={p.value}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="m2">M²: </label>
          <input
            type="text"
            id="m2"
            name="m2"
            placeholder="Ingrese m2 de la sucursal"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="manager_contact">Contacto del responsable: </label>
          <input
            type="text"
            id="manager_contact"
            name="ContactoResponsableSuc"
            placeholder="Ingrese contacto de responsable de la sucursal"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="estado">Estado</label>
          <select
            id="estado"
            name="estado"
            value={inputs.estado}
            onChange={handleInputChange}
          >
            <option value="ACTIVA">Habilitada</option>
            <option value="BAJA">Baja</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterCompany;
