export const generateRegex = (options) => {
    let regexString = "^(?=.*[a-z])";
    let messageRegex = `Debería tener entre ${options?.length} y 15 dígitos`
    if (options?.mayus === 1) {
      regexString += "(?=.*[A-Z])";
      messageRegex += ", una mayúscula";
    }
    if (options?.numbers === 1) {
      regexString += "(?=.*\\d)";
      messageRegex += ", un número";
    }
    if (options?.characters === 1) {
      regexString += `(?=.*[!@#$%^&*()_+\\-=[\\]{};':"\\\\|,.<>/?])`;
      messageRegex += "y un carácter especial."
  
    }
    regexString += `.{${options?.length},15}$`
    regexString = new RegExp(regexString);
    return {regex: regexString, messageRegex: messageRegex}
  };
  