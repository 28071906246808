import React, { useContext } from "react";
import { provinces } from "../../../helpers/infoFilter";
import { ThemeContext } from "../../../context/ThemeContext";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
// Función para generar el encabezado de la tabla
function generateTableHeader(type) {
  switch (type) {
    case "provinces":
      return ["Provincias", "Gasto total"];
    case "commodity":
      return ["Nombre del servicio", "Gasto Servicio"];
    case "utilityspend":
      return ["Categorías", "Concepto", "Gasto Servicio"];
    case "consumCommodity":
      return ["Nombre del servicio", "Consumos"];
    case "consumUtility":
      return ["Categorías", "Concepto", "Consumos"];
    default:
      return [];
  }
}

const TableComponent = ({ type, stateCosts, data, info }) => {
  const { theme } = useContext(ThemeContext);
  let filteredData = [];
  let sortedData = {};
  const utilityTypes = new Set();
  const supplierTypes = new Set();
  let idCounter = 1;
  let totalCosts, totalCostsBySupplier;
  let classesGroup = [];
  const normalizeText = (text) => {
    if (typeof text !== 'string') return '';
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  

  const removeDuplicates = (array, keys) => {
    return array?.filter((item, index, self) =>
      index === self?.findIndex((t) => (
        keys.every(key => t[key] === item[key])
      ))
    );
  };
  
  

  if (info === "consumpItem") {
    classesGroup = data.reduce((acc, bill) => {
      const className = bill?.class ? normalizeText(bill?.class) : '';
      const uniqueClass = `${className}-${bill?.utilityType}`;
  
      if (
        !acc.includes(uniqueClass) &&
        bill?.units !== "N/A" &&
        bill?.consumption !== "N/A" &&
        bill?.consumption &&
        bill?.units !== "AR" &&
        bill?.utilityType &&
        bill?.class !== "Impuestos" &&
        bill?.class !== "IVA" &&
        bill?.class !== "Coseno fi" && 
        bill?.class !== "Tangente fi" && 
        bill?.class !== "Otros" && 
        bill?.class !== "Intereses" && 
        bill?.class !== "Cargo Fijo"
      ) {
        acc.push(uniqueClass);
        if (type !== "consumCommodity") {
          filteredData.push(bill);
        }
      }
      return acc;
    }, []);
filteredData = removeDuplicates(filteredData, ['class', 'utilityType']);

  }
  
  if (info === "financeItem") {
    classesGroup = data?.reduce((acc, bill) => {
      const className = bill?.class ? normalizeText(bill?.class) : '';
      const uniqueClass = `${className}-${bill?.utilityType}`;
  
      if (
        className !== "Total factura" &&
        !acc.includes(uniqueClass) &&
        bill?.cost !== "N/A" &&
        bill?.cost !== "0" &&
        bill?.cost !== undefined &&
        bill?.cost !== null &&
        bill?.utilityType
      ) {
        acc.push(uniqueClass);
        if (type !== "consumCommodity") {
     
          filteredData.push(bill);
        }
      }
      return acc;
    }, []);
  
    filteredData = removeDuplicates(filteredData, ['class', 'utilityType']);
  }
  
  
  
  if (type === "provinces") {
    for (const state in stateCosts) {
      if (stateCosts.hasOwnProperty(state)) {
        let value = parseFloat(stateCosts[state] || 0); // Convertir el valor a número
        value = isNaN(value) ? 0 : value; // Si el valor no es un número, establecerlo como 0
        const formattedValue = value?.toLocaleString("es-AR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        const provinceName = provinces.find(
          (province) => province.value === state
        );

        // Verificar si tanto provinceName como state son null o undefined
        if (
          provinceName === null ||
          provinceName === undefined ||
          state === null ||
          state === undefined
        ) {
          continue; // Saltar a la siguiente iteración del bucle
        }

        const province = {
          state: state !== undefined && state !== "" ? state : null,
          provinceName:
            provinceName?.name !== undefined && provinceName?.name !== ""
              ? provinceName?.name
              : null,
          value: value !== undefined && value !== "" ? value : null,
          formattedValue:
            formattedValue !== undefined && formattedValue !== ""
              ? formattedValue
              : null,
        };

        filteredData.push(province);
      }
    }
    filteredData.sort((a, b) => {
      return b.value - a.value;
    });
  } else {
    data?.length > 0 &&
      data?.forEach((item) => {
        if (
          item?.supplier?.trim().split(" ")[0] !== "" &&
          item?.utilityType?.trim() !== "" &&
          item?.supplier !== undefined &&
          item?.utilityType !== undefined &&
          !utilityTypes.has(item?.utilityType) &&
          !supplierTypes.has(item?.supplier?.trim().split(" ")[0]) &&
          ((info === "consumpItem" && type === "consumCommodity")  || info !== "consumpItem")
        ) {
          filteredData.push(item);
          utilityTypes.add(item?.utilityType);
          supplierTypes.add(item?.supplier?.trim().split(" ")[0]);
        }
      });
  filteredData = removeDuplicates(filteredData, ['class', 'utilityType']);

    const uniqueSuppliers = Array.from(supplierTypes);

    totalCostsBySupplier = uniqueSuppliers?.reduce((acc, supplier) => {
      const filteredData = data?.filter(
        (item) =>
          item?.supplier?.trim().split(" ")[0] === supplier &&
          item?.supplier !== undefined &&
          item?.utilityType !== undefined &&
          item?.supplier?.trim().split(" ")[0] !== "" &&
          item?.utilityType?.trim() !== ""
      );
      let filteredTotal = 0;
      let totalCost = 0;
      if (info === "consumpItem" ) {
        filteredTotal = filteredData?.filter(
          (item) =>
            item?.consumption !== null &&
            !isNaN(parseFloat(item?.consumption)) &&
            isFinite(item?.consumption) &&
            item?.consumption !== " " &&
            item?.lineItemText !== "Total Factura"
        );
        totalCost = filteredTotal?.reduce(
          (acc, item) => (acc += parseFloat(item?.consumption)),
          0
        );
      } else {
        filteredTotal = filteredData?.filter(
          (item) =>
            item.cost !== null &&
            !isNaN(parseFloat(item?.cost)) &&
            isFinite(item?.cost) &&
            item?.cost !== " " &&
            item?.lineItemText !== "Total Factura"
        );
        totalCost = filteredTotal?.reduce(
          (acc, item) => (acc += parseFloat(item?.cost)),
          0
        );
      }

      const formattedTotalCost = totalCost?.toLocaleString("es-AR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      acc[supplier] = formattedTotalCost;
      return acc;
    }, {});

    const dataArray = Object.entries(totalCostsBySupplier);

    // Ordenar el array en función de los valores de mayor a menor
    dataArray.sort((a, b) => {
      const valueA = parseFloat(a[1].replace(/\./g, "").replace(",", "."));
      const valueB = parseFloat(b[1].replace(/\./g, "").replace(",", "."));
      return valueB - valueA;
    });

    // Convertir el array ordenado de nuevo en un objeto
    dataArray.forEach(([key, value]) => {
      sortedData[key] = value;
    });
    
    const services = ["GAS NATURAL", "AGUA", "ELECTRICIDAD"];

    // Define los tipos de utilidad
    const utilityTypes1 = info === "consumpItem" || info === "financeItem" ? classesGroup : services;
    totalCosts = utilityTypes1?.reduce((acc, utilityType) => {
      const partesSeparadas = (info === "consumpItem"|| info === "financeItem") && utilityType.split("-");
      // && (info === "consumpItem" && item?.class === partesSeparadas[0])
      const filteredData =
      data?.length > 0 &&
      data?.filter(
        (item) => {
          const normalizedClass = item?.class ? normalizeText(item?.class) : '';
          const normalizedUtilityType = item?.utilityType ? normalizeText(item?.utilityType) : '';
          return (
            item?.utilityType &&
            (((info === "consumpItem" || info === "financeItem") &&
              normalizedUtilityType.trim() === normalizeText(partesSeparadas[1].trim()) &&
              normalizedClass === normalizeText(partesSeparadas[0])) ||
              ((info !== "consumpItem" || info !== "financeItem") &&
                normalizedUtilityType === normalizeText(utilityType.trim()))) &&
            item?.supplier !== undefined &&
            item?.utilityType !== undefined &&
            item?.supplier?.trim().split(" ")[0] !== "" &&
            item?.utilityType?.trim() !== "" 
          );
        }
      );
      const filteredTotal =
        filteredData?.length > 0 &&
        filteredData?.filter((item) => {
          const value = info === "consumpItem"  ? item?.consumption : item?.cost;
          return (
            value !== null &&
            !isNaN(parseFloat(value)) &&
            isFinite(value) &&
            value !== " " &&
            item.lineItemText !== "Total Factura"
          );
        });
      const totalCost =
        filteredData?.length > 0 &&
        filteredTotal?.reduce((acc, item) => {
          const value = info === "consumpItem" ? item?.consumption : item?.cost;
          acc += parseFloat(value);
          return acc;
        }, 0);
      const formattedTotalCost = totalCost?.toLocaleString("es-AR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      // Agrega el costo total al acumulador
      acc[utilityType] = formattedTotalCost;
      return acc;
    }, {});
  }


  if ((type === "consumUtility" && info === "consumpItem") || (type === "utilityspend" && info === "financeItem")) {
    filteredData = filteredData?.sort((a, b) => {
      const utilityA = a?.utilityType?.toUpperCase();
      const utilityB = b?.utilityType?.toUpperCase();
      return utilityA.localeCompare(utilityB);
    });

  } else {
    filteredData = filteredData?.sort((a, b) => {
      if (info === "consumpItem") {
        if (!a.consumption) return 1;
        if (!b.consumption) return -1;
        return parseFloat(b.consumption) - parseFloat(a.consumption);
      } else {
        if (!a.cost) return 1;
        if (!b.cost) return -1;
        return parseFloat(b.cost) - parseFloat(a.cost);
      }
    });
  }

  const tableHeader = generateTableHeader(type);


  return (
    <div style={{ overflowX: "auto" }}>
      <div
        style={{
          maxHeight: type === "provinces" ? "350px" : "250px",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <table
          className={`table ${
            theme === "dark" ? "table-striped" : "table-dark table-striped"
          }`}
        >
          <thead>
            <tr>
              <th scope="col"></th>
              {tableHeader?.map((header, index) => (
                <th key={index} scope="col">
                  {header === "Costo" ? (
                    <>
                      Costo
                      <i className="sort-arrow">
                        {totalCosts === "asc" ? <FaAngleUp /> : <FaAngleDown />}
                      </i>
                    </>
                  ) : (
                    header
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData
              ?.filter(item => normalizeText(item?.class)?.toLowerCase() !== "total factura")
              ?.map((item, index) => (
                <tr
                  key={
                    type === "provinces" ? item?.value + index : info === "consumpItem" || info === "financeItem" ? `${item.id}C${index}` : item?.id + index
                  }
                >
                  <th scope="row">{idCounter++}</th>
                  {tableHeader?.map((header, index) => (
                    <td
                      key={index}
                      title={header === "Provincias" ? item?.provinceName : ""}
                    >
                      {header === "Categorías"
                        ? item?.utilityType
                        : header === "Nombre del servicio"
                        ? item?.utilityType
                        : header === "Nombre del proveedor"
                        ? item?.supplier?.trim().split(" ")[0]
                        : header === "Gasto total"
                        ? "$" + item?.formattedValue
                        : header === "Provincias"
                        ? item?.provinceName
                        : header === "Gasto Total"
                        ? "AR$ " + totalCosts[item?.utilityType]
                        : header === "Gasto Proveedor"
                        ? "AR$ " + sortedData[item?.supplier?.trim().split(" ")[0]]
                        : header === "Gasto Servicio"
                        ? `AR$ ${totalCosts[item?.class + "-" + item?.utilityType] !== undefined 
                        ? totalCosts[item?.class + "-" + item?.utilityType]
                        : String(totalCosts[item?.utilityType] || 0)}`
                        : header === "Consumos"
                        ? totalCosts[item?.class + "-" + item?.utilityType] !== undefined
                          ? `${totalCosts[item?.class + "-" + item?.utilityType]} ${item?.units}`
                          : ""
                        : header === "Concepto"
                        ? item?.class
                        : null}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
