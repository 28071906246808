// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-supplierOffice-container {
    background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 65%;
  margin: 3% auto;
  text-align: left;

  }
  
  .edit-supplierOffice-container .cont-back{
    width: 9%;
  }
  
  .edit-supplierOffice-container form {
    margin: auto;
  }

  .info-supOffice{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .edit-supplierOffice-container input {
    border: 1px solid black;
    border-radius: 0;
  }

  @media (max-width: 500px) {
    .edit-supplierOffice-container {
      max-width: 90%;
      padding: 10px;
    }

    .edit-supplierOffice-container .cont-dates {
        display: block;
      }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/EditSupplierOffice/EditSupplierOffice.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB,mBAAmB;EACnB,2CAA2C;EAC3C,aAAa;EACb,cAAc;EACd,eAAe;EACf,gBAAgB;;EAEhB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE;MACE,cAAc;MACd,aAAa;IACf;;IAEA;QACI,cAAc;MAChB;EACJ","sourcesContent":[".edit-supplierOffice-container {\n    background: #f8f8f8;\n  border-radius: 10px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  max-width: 65%;\n  margin: 3% auto;\n  text-align: left;\n\n  }\n  \n  .edit-supplierOffice-container .cont-back{\n    width: 9%;\n  }\n  \n  .edit-supplierOffice-container form {\n    margin: auto;\n  }\n\n  .info-supOffice{\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 15px;\n  }\n\n  .edit-supplierOffice-container input {\n    border: 1px solid black;\n    border-radius: 0;\n  }\n\n  @media (max-width: 500px) {\n    .edit-supplierOffice-container {\n      max-width: 90%;\n      padding: 10px;\n    }\n\n    .edit-supplierOffice-container .cont-dates {\n        display: block;\n      }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
