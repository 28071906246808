import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ItemBill from "../Cards/ItemBill";
import { FaAngleDown, FaFileInvoice } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllItemsBill } from "../../redux/apiCalls/itemBills";
import ItemsExcel from "../Reports/ReportItemList";

const ItemsBill = ({ items }) => {
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const queryType = query.get("supplier");
  const suppliers = useSelector((state) => state.supplier.supplier);
  const user = useSelector((state) => state.user.currentUser);
  const [supplier, setSupplier] = useState(queryType || "");
  const navigate = useNavigate();
  const editedCompanyRef = useRef(null);
  let sortedSuppliers = suppliers ?  [...suppliers].sort((a, b) => a.nombre.localeCompare(b.nombre)) : [];

  // Suponiendo que recibes el ID del usuario editado desde la URL
  const searchParams = new URLSearchParams(window.location.search);
  const itemsBillId = searchParams.get("itemsBillId");

  useEffect(() => {
    if (itemsBillId) {
      const companyElement = document.getElementById(`itemsBill-${itemsBillId}`);
      if (companyElement) {
        companyElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [itemsBillId]);

  useEffect(() => {
    if (user.privilege === "editor" || user.privilege === "master") {
      getAllItemsBill(dispatch, user?.accessToken, supplier);
    }
  }, [supplier, user, dispatch]);

  useEffect(() => {
    setSupplier(queryType || "");
  }, [queryType]);

  return (
    <>
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">ITEMS DE FACTURA</p>
          <div className="btn-users">
            <>
              <button
                className="adduser"
                onClick={() => navigate("/itemBill/register")}
                title="Crear nuevo item de factura"
              >
                <i>
                  <FaFileInvoice />
                  <b>+</b>
                </i>
                Nuevo item
              </button>
            </>
            <button className="adduser">
              <ItemsExcel items={items} />
            </button>
          </div>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="user-row">
            <th>CONCEPTO</th>
            <th>CLASE</th>
            <th>UNIDAD DE CONSUMO</th>
            <th>UNIDAD DE COSTO</th>
            <th className="supplier-column">
              <select
                className="select-supplier"
                value={supplier}
                onChange={(e) => setSupplier(e.target.value)}
              >
                <option value={""}>PROVEEDORES</option>
                {sortedSuppliers &&
                  sortedSuppliers.map((s) => (
                    <option key={s.id} value={s.nombre.toLowerCase()}>
                      {s.nombre}
                    </option>
                  ))}
              </select>
            </th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((u) => (
            <ItemBill key={u.id} item={u} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ItemsBill;
