import { utils, writeFile } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";

export default function ProvedoorSucExcel({ supplierCompany }) {

  const downloadExcel = () => {
    if (supplierCompany && Array.isArray(supplierCompany)) {
      const wb = utils.book_new();

      if (Array.isArray(supplierCompany)) {
        
        const dataAsArray = supplierCompany.map(item => ({
          ID: item?.id,
          "ID Sucursal": item?.id_sucursal,
          "ID Proveedor": item?.id_proveedor,
          "NRO cuenta": parseFloat(item?.numeroCuenta) || "N/A", // Convertir NRO cuenta a número
          "NRO medidor": parseFloat(item?.numeroMedidor) || "N/A", // Convertir NRO medidor a número
          "NRO nis": item?.numeroNis ? item?.numeroNis : "N/A",
          "NRO contrato": item?.numeroContrato ? item?.numeroContrato : "N/A",
          "Frecuencia facturacion": item?.frecuenciaFact,
          Observacion: item?.observacion !== "null" && item?.observacion !== "" ? item?.observacion : "N/A",
          Reserva1: item?.reserva1 !== "null" && item?.reserva1 !== "" ? item?.reserva1 : "N/A",
          Reserva2: item?.reserva2 !== "null" && item?.reserva2 !== "" ? item?.reserva2 : "N/A",
          Estado: item?.estado
        }));
        
        const ws = utils.json_to_sheet(dataAsArray);


        ws['!cols'] = [
          { wch: 7 },
          { wch: 7 },
          { wch: 7 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];
        utils.book_append_sheet(wb, ws);
      }

      writeFile(wb, `ReporteProvSuc.xlsx`);
    }
  }

  return (
    <>
      <b onClick={downloadExcel} >
        <FaFileDownload /> Reporte por Proveedor-Sucursal
      </b>
    </>
  );
}
