import { createSlice } from "@reduxjs/toolkit";

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: null,
    companyEdit: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    companiesStart: (state) => {
      state.isFetching = true;
    },
    companiesSuccess: (state, action) => {
      state.companies = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    companiesFailure: (state, action) => {
      state.isFetching = false;
      state.companies = action.payload;
      state.error = true;
    },
    createCompanyStart: (state) => {
      state.isFetching = true;
    },
    createCompanySuccess: (state, action) => {
        state.isFetching = false;
        state.companies = action.payload
        state.error = false;
    },
    createCompanyFailure: (state) => {
        state.currentCompany = null;
        state.isFetching = false;
        state.error = true;
    },
    updateStart: (state) => {
      state.currentCompany = null;
      state.isFetching = true;
      state.error = false;
    },
    updateSuccess: (state, action) => {
        state.isFetching = false;
        state.companies = action.payload
        state.error = false;
    },
    updateFailure: (state) => {
        state.currentCompany = null;
        state.isFetching = false;
        state.error = true;
    },
    deleteStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteSuccess: (state, action) => {
      state.companies = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    deleteFailure: (state, action) => {
      state.companies = action.payload;
      state.isFetching = false;
      state.error = true;
    },
    companyEditStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    companyEditSuccess: (state, action) => {
      state.companyEdit = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    companyEditFailure: (state, action) => {
      state.companyEdit = action.payload;
      state.isFetching = false;
      state.error = true;
    },
    logoutInfoCompanies: (state) => {
      state.companies = null;
      state.companyEdit = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  companiesStart,
  companiesSuccess,
  companiesFailure,
  createCompanyFailure,
  createCompanyStart,
  createCompanySuccess,
  updateFailure,
  updateStart,
  updateSuccess,
  deleteFailure,
  deleteStart,
  deleteSuccess,
  companyEditFailure,
  companyEditStart,
  companyEditSuccess,
  logoutInfoCompanies,
} = companiesSlice.actions;

export default companiesSlice.reducer;