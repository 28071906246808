import React, { useEffect, useRef } from "react";
import { FaBuilding } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Office from "../Cards/Office";
import CompanyExcel from "../Reports/ReportOfficesList";

const OfficesList = ({ companies, user }) => {
  const navigate = useNavigate();
  const editedCompanyRef = useRef(null);

  // Suponiendo que recibes el ID del usuario editado desde la URL
  const searchParams = new URLSearchParams(window.location.search);
  const editedCompanyId = searchParams.get("editedCompanyId");

  useEffect(() => {
    if (editedCompanyId) {
      const companyElement = document.getElementById(
        `company-${editedCompanyId}`
      );
      if (companyElement) {
        companyElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [editedCompanyId]);

  return (
    <>
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">SUCURSALES</p>
          <div className="btn-users">
            <>
              <button
                className="adduser"
                onClick={() => navigate("/company/register")}
                title="Crear nueva sucursal"
              >
                <i>
                  <FaBuilding />
                  <b>+</b>
                </i>
                Nueva sucursal
              </button>
              <button className="adduser">
                <CompanyExcel companies={companies} />
              </button>
            </>
          </div>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className="user-row">
            <th>CÓDIGO</th>
            <th>NOMBRE</th>
            <th>PROVINCIA</th>
            <th>DIRECCIÓN</th>
            <th>M2</th>
            <th>ESTADO</th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {companies?.length > 0 && companies?.map((u) => (
            <Office key={u.id_company} company={u} userLog={user} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OfficesList;
