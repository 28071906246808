
import { passPoliciesStart, passPoliciesSuccess, passPoliciesFailure } from "../reducers/passPoliciesReducer";
import { publicRequest } from "../../requestMeth";


export const getPassPolicies = async (dispatch, token) => {
    dispatch(passPoliciesStart());
    try {
        const res = await publicRequest.get(`/master/passwordPolicies`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(passPoliciesSuccess(res.data));
    } catch (error) {
        dispatch(passPoliciesFailure());
    }
};

export const updatePassPolicies = async (dispatch, payload, token) => {
    dispatch(passPoliciesStart());
    try {
        const res = await publicRequest.put(`/master/passwordPolicies`, payload, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(passPoliciesSuccess(res.data));
    } catch (error) {
        dispatch(passPoliciesFailure());
    }
};