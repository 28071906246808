import "./Topbar.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import phProfile from "../../assets/img.png";
import logo from "../../assets/logo.png";
import { logoutUser } from "../../redux/apiCalls/loginCall";
import { userSuccess } from "../../redux/reducers/profileReducer";

const Topbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user.currentUser);
  const userData = useSelector((state) => state.profile);

  const handleLogout = () => {
    dispatch(userSuccess(null));
    localStorage.removeItem("downloadData");
    localStorage.removeItem("selectedCompanies");
    localStorage.removeItem("selectedDevices");
    localStorage.removeItem("board");
    logoutUser(dispatch, user?.accessToken, {user:user?.user});
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-app">
      <a
        className="navbar-brand image-logo"
        href="#"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="Logo bill system" width="100" height="45" />
        <p className="nav-item">ENERGY BILL MANAGER</p>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {user && location.pathname !== "/expired-password" ? (
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaBars />
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                {user?.privilege === "editor" || user?.privilege === "master" ? (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => navigate(`/uploadBill`)}
                    >
                      Cargar factura
                    </a>
                  </li>
                ) : null}
                {user?.privilege === "admin" ||
                user?.privilege === "master" ||
                user?.privilege === "editor" ? (
                  <li>
                    <a
                      onClick={() =>
                        navigate(
                          user?.privilege === "editor"
                            ? "/admin/lists?show=companies"
                            : "/admin/lists?show=users"
                        )
                      }
                      className="dropdown-item"
                      href="#"
                    >
                      Administrar
                    </a>
                  </li>
                ) : null}
                <li>
                  <a
                    onClick={() => navigate("/admin/audit")}
                    className="dropdown-item"
                    href="#"
                  >
                    Auditoría
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => navigate(`profile/${user.id_user}`)}
                  >
                    Tu cuenta
                  </a>
                </li>
                <li>
                    <a
                      onClick={() => navigate(`pass/${user.id_user}`)}
                      className="dropdown-item"
                      href="#"
                    >
                    Contraseña
                    </a>
                  </li>
                <li>
                  <a onClick={handleLogout} className="dropdown-item" href="#">
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ) : null}
    </nav>
  );
};

export default Topbar;
