import { publicRequest } from "../../requestMeth"; 
import { createSupplierFailure, createSupplierStart, createSupplierSuccess } from "../reducers/createSupplierReducer";


export const createSupplier = async (dispatch, payload, token) => {
    dispatch(createSupplierStart());
    try {
        const res = await publicRequest.post("/supplier/register", payload,{
            headers: { token: `Bearer ${token}` }
        });
        dispatch(createSupplierSuccess(res.data));
    } catch (error) {
        dispatch(createSupplierFailure({}));
        return error.response.data;
    }
};