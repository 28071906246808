import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2'; 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: undefined,
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          const value = context?.parsed?.y
          if (label) {
            label += ': CO2 ';
          }
          label += value?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          return label;
        }
      }
    },
    title: {
      display: true,
      text: 'Comparativa anual de emisiones de CO2',
    },
  },
};


const BarChartAgua = ({ generalDataCO2 }) => {

  const utilityColors = {
    AGUA: '#92a9c5',
  };

  const dataMemo = useMemo(() => {
    let usagePerMonthCurrentYear = {};
    let usagePerMonthLastYear = {};
    let labels = [];

    if (generalDataCO2 && generalDataCO2.consByMonthCurrentYear && generalDataCO2.consByMonthLastYear) {
      labels = Object.keys(generalDataCO2.consByMonthCurrentYear);
      
      for (let month in generalDataCO2.consByMonthCurrentYear) {
        usagePerMonthCurrentYear[month] = parseFloat(generalDataCO2.consByMonthCurrentYear[month]);
      }

      for (let month in generalDataCO2.consByMonthLastYear) {
        usagePerMonthLastYear[month] = parseFloat(generalDataCO2.consByMonthLastYear[month]);
      }
    }

    let datasets = [
      {
        label: 'Año actual',
        data: labels.map(month => usagePerMonthCurrentYear[month] || 0) ,
        borderColor: utilityColors.AGUA,
        backgroundColor: utilityColors.AGUA,
      },
      {
        label: 'Año pasado',
        data: labels.map(month => usagePerMonthLastYear[month] || 0),
        borderColor: '#ff6384',
        backgroundColor: '#ff6384',
      },
    ];

    return {
      labels: labels,
      datasets: datasets,
    };
  }, [generalDataCO2]); // data ya no es necesario en las dependencias

  return (
    <div style={{ height: '250px', width: '100%' }}>
      <Bar data={dataMemo} options={options} />
    </div>
  );
};

export default BarChartAgua;
