// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-sustDash {
  width: 95%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(2, 1fr) repeat(2, 2fr); */
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}
.div1-sustDash {
   grid-area: 1 / 1 / 3 / 3;
}
.div2-sustDash {
   grid-area: 1 / 5 / 3 / 5;
}
.div3-sustDash {
   grid-area: 1 / 3 / 3 / 5;
}
.div4-sustDash {
   grid-area: 3 / 1 / 4 / 3;
}
.div5-sustDash {
   grid-area: 3 / 3 / 4 / 6;
}
.div6-sustDash {
   grid-area: 4 / 1 / 5 / 3;
}
.div7-sustDash {
   grid-area: 4 / 3 / 5 / 6;
}

/* .custom-icon {
  background-color: rgba(140, 198, 214, 0.573);
  border: 2px solid rgb(0, 167, 213);
  border-radius: 50%;
} */`, "",{"version":3,"sources":["webpack://./src/components/Home/DashSustainability/DashSustainability.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,qCAAqC;EACrC,uDAAuD;EACvD,qBAAqB;EACrB,iBAAiB;AACnB;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;AACA;GACG,wBAAwB;AAC3B;;AAEA;;;;GAIG","sourcesContent":[".parent-sustDash {\n  width: 95%;\n  margin: auto;\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  /* grid-template-rows: repeat(2, 1fr) repeat(2, 2fr); */\n  grid-column-gap: 10px;\n  grid-row-gap: 5px;\n}\n.div1-sustDash {\n   grid-area: 1 / 1 / 3 / 3;\n}\n.div2-sustDash {\n   grid-area: 1 / 5 / 3 / 5;\n}\n.div3-sustDash {\n   grid-area: 1 / 3 / 3 / 5;\n}\n.div4-sustDash {\n   grid-area: 3 / 1 / 4 / 3;\n}\n.div5-sustDash {\n   grid-area: 3 / 3 / 4 / 6;\n}\n.div6-sustDash {\n   grid-area: 4 / 1 / 5 / 3;\n}\n.div7-sustDash {\n   grid-area: 4 / 3 / 5 / 6;\n}\n\n/* .custom-icon {\n  background-color: rgba(140, 198, 214, 0.573);\n  border: 2px solid rgb(0, 167, 213);\n  border-radius: 50%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
