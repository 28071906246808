import "./EditSupplierOffice.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import {
  editSupplierOffice,
  getAllsupplierCompany,
} from "../../redux/apiCalls/supplierCompanyCall";

const EditSupplierOffice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const suppliersCompany = useSelector(
    (state) => state.supplierCompany.supplierCompany
  );
  const currentItem = suppliersCompany?.find((item) => item.id === Number(id));
  const [inputs, setInputs] = useState({
    numeroCuenta: currentItem?.numeroCuenta || "",
    numeroMedidor: currentItem?.numeroMedidor || "",
    numeroNis: currentItem?.numeroNis || "",
    numeroContrato: currentItem?.numeroContrato || "",
    frecuenciaFact: currentItem?.frecuenciaFact || "",
    observacion: currentItem?.observacion || "",
    reserva1: currentItem?.reserva1 || "",
    reserva2: currentItem?.reserva2 || "",
    estado: currentItem?.estado || "",
  });

  useEffect(() => {
    getAllsupplierCompany(dispatch, user.accessToken);
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Está seguro que desea editar la relación sucursal-proveedor?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        editSupplierOffice(dispatch, id, inputs, user?.accessToken).then(
          (res) => {
            if(res?.error){
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            }
            navigate(`/admin/lists?show=supplierOffice&editedSupOfficeId=${id}`);
          }
        );
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo editar.`,
        });
      }
    });
  };

  return (
    <div className="edit-supplierOffice-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=supplierOffice`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Editar datos de proveedor asignado</h2>
      <form>
        <div className="info-supOffice">
          <label>Sucursal: {currentItem?.locationName}</label>
          <label>Proveedor: {currentItem?.supplierName}</label>
        </div>
        <div className="form-group cont-dates">
          <div className="dateform">
            <label htmlFor="numeroCuenta">Número de cuenta:</label>
            <input
              type="text"
              id="numeroCuenta"
              name="numeroCuenta"
              placeholder={currentItem?.numeroCuenta}
              value={inputs.numeroCuenta}
              onChange={handleInputChange}
            />
          </div>
          <div className="dateform">
            <label htmlFor="numeroMedidor">Número de medidor:</label>
            <input
              type="text"
              id="numeroMedidor"
              name="numeroMedidor"
              placeholder={currentItem?.numeroMedidor}
              value={inputs.numeroMedidor}
              onChange={handleInputChange}
            />
          </div>
          <div className="dateform">
            <label htmlFor="numeroNis">Número de NIS:</label>
            <input
              type="text"
              id="numeroNis"
              name="numeroNis"
              value={inputs.numeroNis}
              placeholder={currentItem?.numeroNis}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group cont-dates">
          <div className="dateform">
            <label htmlFor="consumptionUnit">Número de contrato: </label>
            <input
              type="text"
              id="numeroContrato"
              name="numeroContrato"
              value={inputs.numeroContrato}
              placeholder={currentItem?.numeroContrato}
              onChange={handleInputChange}
            />
          </div>
          <div className="dateform">
            <label htmlFor="frecuenciaFact">Frecuencia de facturación: </label>
            <select
              id="frecuenciaFact"
              name="frecuenciaFact"
              onChange={handleInputChange}
              value={inputs.frecuenciaFact}
            >
              <option value="MENSUAL">MENSUAL</option>
              <option value="BIMESTRE">BIMESTRE</option>
              <option value="TRIMESTRE">TRIMESTRE</option>
              <option value="CUATRIMESTRE">CUATRIMESTRE</option>
              <option value="SEMESTRE">SEMESTRE</option>
              <option value="ANUAL">ANUAL</option>
            </select>
          </div>
          <div className="dateform">
            <label htmlFor="observacion">Observación: </label>
            <input
              type="text"
              id="observacion"
              name="observacion"
              value={inputs.observacion}
              placeholder={currentItem?.observacion}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group cont-dates">
          <div className="dateform">
            <label htmlFor="reserva1">Reserva 1: </label>
            <input
              type="text"
              id="reserva1"
              name="reserva1"
              value={inputs.reserva1}
              placeholder={currentItem?.reserva1}
              onChange={handleInputChange}
            />
          </div>
          <div className="dateform">
            <label htmlFor="reserva2">Reserva 2: </label>
            <input
              type="text"
              id="reserva2"
              name="reserva2"
              value={inputs.reserva2}
              placeholder={currentItem?.reserva2}
              onChange={handleInputChange}
            />
          </div>
          <div className="dateform">
            <label htmlFor="estado">Estado</label>
            <select
              id="estado"
              name="estado"
              value={inputs?.estado}
              onChange={handleInputChange}
            >
              <option value="ACTIVO">HABILITADO</option>
              <option value="BAJA">BAJA</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Editar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSupplierOffice;
