
import { createSlice } from "@reduxjs/toolkit";

const supplierEditSlice = createSlice({
  name: "supplierEdit",
  initialState: {
    currentSupplier: null,
    isFetching: false,
    error: false
  },
  reducers: {
    supplierStart: (state) => {
      state.isFetching = true;
    },
    supplierSuccess: (state, action) => {
      state.isFetching = false;
      state.currentSupplier = action.payload;
    },
    supplierFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //Update
    updateStart: (state) => {
      state.currentSupplier = null;
      state.isFetching = true;
      state.error = false;
    },
    updateSuccess: (state, action) => {
      state.isFetching = false;
      state.currentSupplier = action.payload
      state.error = false;
    },
    updateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});


export const { supplierStart, supplierSuccess, supplierFailure, updateStart, updateSuccess, updateFailure, logoutEditSupplier } = supplierEditSlice.actions;
export default supplierEditSlice.reducer;