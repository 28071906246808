import React from 'react';
import { FaEdit, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { logoutUser } from '../../redux/apiCalls/loginCall';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../redux/apiCalls/usersCall';
import { Toast } from '../../helpers/alert';

const User = ({ user, userLog }) => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleDelete(e) {
    e.preventDefault();
    Swal.fire({
      title:`¿Está seguro de que desea eliminar el usuario ${user.user}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Sí, eliminar el usuario.",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteUser(dispatch, user.id_user, userLog.accessToken)
          .then((res) => {
            if(res?.error){
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            }else{
              if(userLog.id_user === user.id_user){
                logoutUser(dispatch, userLog.accessToken, {user:userLog?.user});
                navigate("/login");
              } else{
                navigate("/admin/lists?show=users");
              }
            }
          });
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: "No se pudo eliminar el usuario.",
          });
        }
      }
    });
  }
  
  return (
    <tr className='user-row'>
      <td>{user.id_user}</td>
      <td>{user.user}</td>
      <td>{user.mail}</td>
      <td>{user.privilege}</td>
      <td>
        { user.enable === "enable" ? (
      <p className="data-text enable enable-user">Habilitado</p>
    ) : (
      <p className="data-text enable disable-user">Desabilitado</p>
    )}
      </td>
        <td className='cont-actions'>
        { userLog.privilege === "master" || userLog.privilege === "admin" && user.privilege !== "admin" ? (
              <>
              <div className="btnUser-edit btn-actions" onClick={()=>navigate(`/users/edit/${user.id_user}`)}>
                  <FaEdit />
                  <p>Editar</p>
              </div>
              <div
                  onClick={(e) => handleDelete(e)}
                  className="btnUser-delete btn-actions"
              >
                  <FaTrashAlt />
                  <p>Eliminar</p>
              </div>
              </>
            ) : "No hay acciones permitidas"}
        </td>
    </tr>
  );
};

export default User;
