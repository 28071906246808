import React, { useContext } from 'react';
import './Footer.css';
import { ThemeContext } from '../../context/ThemeContext';

function Footer() {
  const { theme } = useContext(ThemeContext);

  return (
    <footer className={`footer-${theme}`}>
      <p>Copyright © 2024 - NABLA</p>
    </footer>
  );
}

export default Footer;
