import "./EditUser.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPassPolicies } from "../../redux/apiCalls/passPoliciesCall";
import { generateRegex } from "../../helpers/Regex";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { updateUser } from "../../redux/apiCalls/editUserCall";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";

function validar(password, passPolicies) {
  let errors = {};
  const passRegex = generateRegex(passPolicies);

  if (!passRegex.regex.test(password)) {
    errors.newPassword = passRegex.messageRegex;
  }

  return errors;
}

const EditUser = ({ userEdit, userLog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passPolicies = useSelector((state) => state.passPolicies);
  const users = useSelector((state) => state.users.users);
  const companies = useSelector((state) => state.companies.companies);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({
    user: userEdit?.user || "",
    mail: userEdit?.mail || "",
    phone: userEdit?.phone || "",
    contact: userEdit?.contact || "",
    privilege: userEdit?.privilege || "viewer",
    enable: userEdit?.enable || "enable",
    companies: userEdit?.id_companies || [],
  });
  const uniqueCompanies = companies ? [...companies].sort((a, b) =>
  a.name.localeCompare(b.name)) : [];

  useEffect(() => {
    getPassPolicies(dispatch, userLog?.accessToken);
  }, []);

  useEffect(() => {
    if (userEdit) {
      setInputs({
        user: userEdit?.user,
        mail: userEdit?.mail,
        phone: userEdit?.phone,
        contact: userEdit?.contact,
        privilege: userEdit?.privilege,
        enable: userEdit?.enable,
        companies: userEdit?.id_companies,
      });
    }
  }, [userEdit]);

  function handlePaswordChange(e) {
    setInputs({
      ...inputs,
      pass: e.target.value,
    });
    setErrors(validar(e.target.value, passPolicies.passPolicies));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setInputs({
        ...inputs,
        [name]: userEdit[name] || "", // restaura el valor original de `userEdit` si el valor es vacío
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  }
  
  const handleCompanySelectionChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) =>
      Number(option.value)
    );
    const selectedCompanyNames = new Set(inputs.companies); // Usamos un conjunto para evitar duplicados

    // Agregamos el nombre de la empresa seleccionada
    selectedOptions.forEach((id) => {
      const selectedCompany = companies.find(
        (company) => company.id_company === id
      );
      if (selectedCompany) {
        selectedCompanyNames.add(selectedCompany.name);
      }
    });

    const selectedCompanyIds = [];

    // Buscamos todas las empresas con el mismo nombre y agregamos sus id_company
    companies.forEach((company) => {
      if (selectedCompanyNames.has(company.name)) {
        selectedCompanyIds.push(company.id_company);
      }
    });
    let updateCompanies = [...inputs.companies];
    if (selectedCompanyIds.length > 1) {
      selectedCompanyIds.forEach((companyId) => {
        if (updateCompanies.includes(companyId)) {
          updateCompanies = updateCompanies.filter((c) => c !== companyId); // si la compañía está, se elimina
        } else {
          //en esta linea de abajo da el error pero updatecompanies es un array y companyid es un number
          updateCompanies.push(companyId);
        }
      });
      // updateCompanies = [...updateCompanies, ...inputs.companies]
    } else {
      updateCompanies = inputs.companies.includes(selectedCompanyIds[0])
        ? inputs.companies.filter((c) => c !== selectedCompanyIds[0]) // si la companie está, se elimina
        : [...inputs.companies, selectedCompanyIds[0]];
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      companies: updateCompanies,
    }));
  };

  const handleSelectAllCompanies = () => {
    const allCompanyIds = companies.map((company) => company.id_company);

    // Si "selectAll" está en inputs.companies, lo filtro
    const updatedCompanies = inputs.companies.includes("selectAll")
      ? inputs.companies.filter((id) => id !== "selectAll")
      : inputs.companies.filter((id) => !isNaN(id));
    if (updatedCompanies.length === allCompanyIds.length) {
      // si estan todas seleccionadas, se saca
      setInputs((prevInputs) => ({
        ...prevInputs,
        companies: [],
      }));
    } else {
      // si no todas las sucursales están seleccionadas, selecciona todas
      setInputs((prevInputs) => ({
        ...prevInputs,
        companies: allCompanyIds,
      }));
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const emailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

    if (inputs.mail && !emailPattern.test(inputs.mail)) {
      Toast.fire({
        icon: "error",
        title: "Formato de email no valido",
      });
      return;
    }

    const updatedFields = {};

    if (inputs.user !== userEdit.user) {
      updatedFields.user = inputs.user;
    }
    if (inputs.mail !== userEdit.mail) {
      updatedFields.mail = inputs.mail;
    }
    if (inputs.pass && !errors.newPassword) {
      updatedFields.pass = inputs.pass;
    }
    if (inputs.phone !== userEdit.phone) {
      updatedFields.phone = inputs.phone;
    }
    if (inputs.contact !== userEdit.contact) {
      updatedFields.contact = inputs.contact;
    }
    if (userLog.privilege === "admin" || userLog.privilege === "master") {
      if (inputs.privilege !== userEdit.privilege) {
        updatedFields.privilege = inputs.privilege;
      }
      if (inputs.enable !== userEdit.enable) {
        updatedFields.enable = inputs.enable;
      }
    }

    for (const key in updatedFields) {
      if (updatedFields[key] === "") {
        delete updatedFields[key];
      }
    }

    const updatedData = {
      ...updatedFields,
      company: inputs.companies,
    };

    if (Object.keys(updatedData).length === 0) {
      Toast.fire({
        icon: "info",
        title: "No se realizaron cambios",
      });
      return;
    }
    let userExists = users.find(
      (u) => u.mail === updatedFields.mail || u.user === updatedFields.user
    );

    if (!errors.newPassword) {
      Swal.fire({
        title: "¿Está seguro de que desea editar su perfil?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, hacer los cambios!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed && !Object.keys(errors).length && !userExists) {
          updateUser(
            dispatch,
            userEdit.id_user,
            updatedData,
            userLog.accessToken
          ).then((res) => {
            if(res?.error){
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            }
            navigate(`/admin/lists?show=users`);
          });
        } else {
          if (userExists) {
            //si existe un usuario con ese nuevo nombre elegido
            Toast.fire({
              icon: "info",
              title:
                "No se puede crear. El usuario o correo electrónico ya existe.",
            });
          } else {
            Toast.fire({
              icon: "info",
              title: "Cambios descartados",
            });
          }
        }
      });
    }
  }

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=users`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Usuarios</p>
      </div>
      <h2>Editar Usuario</h2>
      {userLog?.privilege === "master" ||
      (userLog?.privilege === "admin" && userEdit?.privilege !== "admin") ? (
        <form>
          <div className="form-group">
            <label htmlFor="user">Usuario</label>
            <input
              type="text"
              id="user"
              name="user"
              placeholder={userEdit?.user}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mail">Email</label>
            <input
              type="email"
              id="mail"
              name="mail"
              placeholder={userEdit?.mail}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mail">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id="pass"
              name="pass"
              placeholder={userEdit?.pass}
              onChange={handlePaswordChange}
            />
            <span
              className="password-toggle-button"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              aria-label={
                showPassword ? "Ocultar contraseña" : "Mostrar contraseña"
              }
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          {errors.newPassword && (
            <span className="error-pass">{errors.newPassword}</span>
          )}
          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder={userEdit?.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contacto</label>
            <input
              type="text"
              id="contact"
              name="contact"
              placeholder={userEdit?.contact}
              onChange={handleInputChange}
            />
          </div>
          {userLog?.privilege === "admin" || userLog?.privilege === "master" ? (
            <>
              <div className="form-group">
                <label htmlFor="privilege">Privilegio</label>
                <select
                  id="privilege"
                  name="privilege"
                  value={inputs?.privilege}
                  onChange={handleInputChange}
                >
                  {userLog.privilege === "admin" ? (
                    <option value="viewer">Viewer</option>
                  ) : userLog.privilege === "master" ? (
                    <>
                      <option value="admin">Admin</option>
                      <option value="viewer">Viewer</option>
                    </>
                  ) : null}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="enable">Estado</label>
                <select
                  id="enable"
                  name="enable"
                  value={inputs?.enable}
                  onChange={handleInputChange}
                >
                  <option value="enable">Habilitado</option>
                  <option value="disable">Desabilitado</option>
                </select>
              </div>
            </>
          ) : null}
          {userEdit?.privilege === "viewer" ? (
            <div className="form-group">
              <label htmlFor="companySelect">Selecciona las empresas:</label>
              <select
                id="companySelect"
                name="company"
                value={inputs.companies}
                onChange={handleCompanySelectionChange}
                multiple
              >
                <option value="selectAll" onClick={handleSelectAllCompanies}>
                  Seleccionar todas
                </option>
                {uniqueCompanies?.map((company) => (
                  <option key={company.id_company} value={company.id_company}>
                    {company.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div className="form-group">
            <button type="submit" onClick={handleSubmit}>
              Guardar Cambios
            </button>
          </div>
        </form>
      ) : (
        "No tiene permisos para editar este usuario"
      )}
    </div>
  );
};

export default EditUser;
