// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-supplier{
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    height: 30px;
    margin-top: 2%;
    color: #73879c !important;
    background-color: #c3c6c9 !important;
    border: 1px solid #a3a7aa;
    padding: 5px;
    font-size: 13px;
}

.info-supplier a{
    font-size: 16px;
    cursor: pointer;
    margin-top: -1%;
}

.info-supplier select{
    width: 30%;
    background-color: #fff;
    color: black;
}

.suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    background-color: #fff;
    width: 100%;
   }
   
   .suggestions-list li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
   }
   
   .suggestions-list li:hover {
    background-color: #f0f0f0;
   }
   `, "",{"version":3,"sources":["webpack://./src/pages/EditOffice/EditOffice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,oCAAoC;IACpC,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,WAAW;GACZ;;GAEA;IACC,aAAa;IACb,eAAe;IACf,6BAA6B;GAC9B;;GAEA;IACC,yBAAyB;GAC1B","sourcesContent":[".info-supplier{\n    display: flex;\n    justify-content: space-between;\n    max-width: 400px;\n    height: 30px;\n    margin-top: 2%;\n    color: #73879c !important;\n    background-color: #c3c6c9 !important;\n    border: 1px solid #a3a7aa;\n    padding: 5px;\n    font-size: 13px;\n}\n\n.info-supplier a{\n    font-size: 16px;\n    cursor: pointer;\n    margin-top: -1%;\n}\n\n.info-supplier select{\n    width: 30%;\n    background-color: #fff;\n    color: black;\n}\n\n.suggestions-list {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n    border: 1px solid #ccc;\n    border-top: none;\n    max-height: 200px;\n    overflow-y: auto;\n    position: absolute;\n    z-index: 100;\n    background-color: #fff;\n    width: 100%;\n   }\n   \n   .suggestions-list li {\n    padding: 10px;\n    cursor: pointer;\n    border-bottom: 1px solid #ccc;\n   }\n   \n   .suggestions-list li:hover {\n    background-color: #f0f0f0;\n   }\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
