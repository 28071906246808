import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { assignSupplierCompany } from "../../redux/apiCalls/supplierCompanyCall";
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa";
import { getAllsupplier } from "../../redux/apiCalls/supplierCall";

const validar = (inputs) => {
  let errors = {};

  if (!inputs.numeroCuenta) {
    errors.numeroCuenta = `Por favor, ingrese un número de cuenta. 
    Si la factura no posee número de cuenta, completar con número de medidor/NIS/contrato según corresponda por el proveedor.`;
  }

  return errors;
};

const AssignSupplierCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const suppliers = useSelector((state) => state.supplier.supplier);
  const { idcompany } = useParams();
  const user = useSelector((state) => state.user.currentUser);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({
    id_sucursal: idcompany,
    id_proveedor: "",
    numeroCuenta: "",
    numeroMedidor: "",
    numeroNis: "",
    numeroContrato: "",
    frecuenciaFact: "MENSUAL",
    observacion: "",
    reserva1: "",
    reserva2: "",
  });
  let sortedSuppliers = suppliers ?  [...suppliers].sort((a, b) => a.nombre.localeCompare(b.nombre)) : [];

  // useEffect(() => {
  //   getAllsupplier(dispatch, user.accessToken);
  // }, [dispatch, user.accessToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors(
      validar({
        ...inputs,
        [name]: value,
      })
    );
    setInputs({ ...inputs, [name]: value });
  };

  const handleSelectChange = (e) => {
    // Al seleccionar un proveedor, actualizar el id_proveedor en el estado
    const selectedSupplierId = e.target.value;
    // Convierte el valor a número
    const parsedSupplierId = parseInt(selectedSupplierId, 10);
    setInputs({ ...inputs, id_proveedor: parsedSupplierId });
  };

  const handleSelectClick = () => {
    getAllsupplier(dispatch, user.accessToken);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const supplierCompanyExists = supplierCompanies.find((u) => u.name === inputs.nombreSucursal || u.branch_code === inputs.codigoSucursal );

    Swal.fire({
      title: "¿Está seguro que desea agregar un nuevo proveedor?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, agregar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed && !Object.keys(errors).length) {
       try {
        assignSupplierCompany(dispatch, inputs, idcompany, user.accessToken)
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: `Relación proveedor-sucursal creada.`,
          });
          navigate(`/admin/lists?show=companies&editedCompanyId=${idcompany}`);
        })
        .catch((error) => {
          Toast.fire({
            icon: "info",
            title: error?.message,
          });
        });
       } catch (error) {
        Toast.fire({
          icon: "info",
          title: "No se pudo agregar la relación entre la sucursal y el proveedor.",
        });
       }
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo agregar la relación entre la sucursal y el proveedor. 
          ${Object.values(errors)
          }`,
        });
      }
    });
  };

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=companies`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Asignar Proveedor</h2>
      <form>
        <div className="form-group">
          <label htmlFor="name">Proveedor: </label>
          <select id="name" name="id_proveedor" onChange={handleSelectChange} onFocus={handleSelectClick}>
            <option>Seleccionar proveedor</option>
            {sortedSuppliers?.map((supplier) => {
              return (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.nombre}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">
            Numero de cuenta:{" "}
            <i
              className="icon-info"
              title="El número de cuenta es requerido. Se usará para relacionar las facturas. Si el proveedor no posee número de cuenta, completar con número de medidor/NIS/contrato según corresponda."
            >
              <FaInfoCircle />
            </i>
          </label>
          <input
            type="text"
            id="name"
            name="numeroCuenta"
            placeholder="Ingrese numero de cuenta"
            required={true}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="branch_code">Numero medidor: </label>
          <input
            type="text"
            id="branch_code"
            name="numeroMedidor"
            placeholder="Ingrese numero de medidor"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ciudad">Numero Nis: </label>
          <input
            type="text"
            id="ciudad"
            name="numeroNis"
            value={inputs.ciudad}
            onChange={handleInputChange}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="address">Numero de contrato: </label>
          <input
            type="text"
            id="address"
            name="numeroContrato"
            placeholder="Ingrese el numero de contrato"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="m2">Frecuencia de facturación: </label>
          <select id="m2" name="frecuenciaFact" onChange={handleInputChange}>
            <option value="MENSUAL">MENSUAL</option>
            <option value="BIMESTRE">BIMESTRE</option>
            <option value="TRIMESTRE">TRIMESTRE</option>
            <option value="CUATRIMESTRE">CUATRIMESTRE</option>
            <option value="SEMESTRE">SEMESTRE</option>
            <option value="ANUAL">ANUAL</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="manager_contact">Observacion: </label>
          <input
            type="text"
            id="manager_contact"
            name="observacion"
            placeholder="Ingrese observacion"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="estado">Reserva 1</label>
          <input
            type="text"
            name="reserva1"
            onChange={handleInputChange}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="estado">Reserva 2</label>
          <input
            type="text"
            name="reserva2"
            onChange={handleInputChange}
          ></input>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Agregar
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssignSupplierCompany;
