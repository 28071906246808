
import { userStart, userSuccess, userFailure, updateStart, updateSuccess, updateFailure } from "../reducers/profileReducer";
import { publicRequest } from "../../requestMeth";


export const getUser = async (dispatch, id, token) => {
    dispatch(userStart());
    try {
        const res = await publicRequest.get(`/profile/user/${id}`, {
            headers: { token: `Bearer ${token}` }
        });
        dispatch(userSuccess(res?.data));
    } catch (error) {
        dispatch(userFailure({}));
        return error?.response?.data
    }
};

export const resetPass = async ({dispatch, id, pass, image, token}) => {
    dispatch(updateStart());
    try {
        let res;
        if (pass) {
            res = await publicRequest.put(`/profile/user/edit/${id}`, pass, {
                headers: { token: `Bearer ${token}` }
            });
        } else if (image) {
            res = await publicRequest.put(`/profile/user/edit/${id}`, image, {
                headers: { token: `Bearer ${token}` }
            });
        } else {
            throw new Error("Neither pass nor image provided");
        }
        dispatch(updateSuccess(res.data));
    } catch (error) { 
        dispatch(updateFailure());
    }
};


