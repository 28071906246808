export const provinces = [
  { name: 'Buenos Aires', label: 'Buenos Aires', value: 'BA' },
  { name: 'CABA', label: 'CABA', value: 'CI' },
  { name: 'Catamarca', label: 'Catamarca', value: 'CA' },
  { name: 'Chaco', label: 'Chaco', value: 'CH' },
  { name: 'Chubut', label: 'Chubut', value: 'CT' },
  { name: 'Córdoba', label: 'Córdoba', value: 'CB' },
  { name: 'Corrientes',label: 'Corrientes', value: 'CR' },
  { name: 'Entre Ríos',label: 'Entre Ríos', value: 'ER' },
  { name: 'Formosa', label: 'Formosa', value: 'FR' },
  { name: 'Jujuy', label: 'Jujuy', value: 'JY' },
  { name: 'La Pampa', label: 'La Pampa', value: 'LP' },
  { name: 'La Rioja', label: 'La Rioja', value: 'LR' },
  { name: 'Mendoza', label: 'Mendoza', value: 'MZ' },
  { name: 'Misiones', label: 'Misiones', value: 'MI' },
  { name: 'Neuquén', label: 'Neuquén', value: 'NQ' },
  { name: 'Río Negro', label: 'Río Negro', value: 'RN' },
  { name: 'Salta', label: 'Salta', value: 'SA' },
  { name: 'San Juan', label: 'San Juan', value: 'SJ' },
  { name: 'San Luis', label: 'San Luis', value: 'SL' },
  { name: 'Santa Cruz', label: 'Santa Cruz', value: 'SC' },
  { name: 'Santa Fe', label: 'Santa Fe', value: 'SF' },
  { name: 'Santiago del Estero', label: 'Santiago del Estero', value: 'SE' },
  { name: 'Tierra del Fuego', label: 'Tierra del Fuego', value: 'TF' },
  { name: 'Tucumán', label: 'Tucumán', value: 'TU' }
];

  export const utilities = ['AGUA','ELECTRICIDAD', 'GAS NATURAL'];

  export const typesByUtility = {
    'ELECTRICIDAD': ['Energy', 'Reactive Power', 'Taxes', 'Network', 'Fixed Charge', 'Miscellaneous', 'Levies'],
    'AGUA': ['Network', 'Fixed Charge', 'Energy', 'Reactive Power', 'Miscellaneous', 'Levies'],
    'GAS': ['Energy', 'Taxes', 'Fixed Charge'],
  };

  export const monthsInOrder = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  export const unitsClass = {
    "Energía Consumida": "kWh",
    "Energia Consumida": "kWh",
    "Energia Reactiva": "Kvar",
    "Energía Reactiva": "Kvar",
    "Potencia Excedida":"KW",
    "Potencia Contratada":"KW",
    "Potencia Demandada":"KW",
    "Energia Inyectada":"kWh",
    "Coseno fi":"",
    "Tangente fi":"",
    "Agua Consumida": "m3",
    "Gas Consumido": "m3",
    "Gas Cons. a 9300 Kcal/m3": "m3"
  }
