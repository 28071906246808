import { createSlice } from "@reduxjs/toolkit";

const ItemBillEditSlice = createSlice({
  name: "ItemBillEdit",
  initialState: {
    editItemBill: null,
    isFetching: false,
    error: false
  },
  reducers: {
    editItemBillStart: (state) => {
      state.isFetching = true;
    },
    editItemBillSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.editItemBill = action.payload
    },
    editItemBillFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutEditItemBill: (state) => {
      state.editItemBill = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});


export const { editItemBillStart, editItemBillSuccess, editItemBillFailure, logoutEditItemBill } = ItemBillEditSlice.actions;
export default ItemBillEditSlice.reducer;