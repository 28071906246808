// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
  }
  
  .card {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-label {
    font-size: 16px;
  }
  
  .form-control {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 16px;
  }
  
  .btn-primary {
    background-color: #457b9d;
    color: #fff;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #2f6281;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,wCAAwC;EAC1C;;EAEA;IACE,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 95vh;\n  }\n  \n  .card {\n    width: 100%;\n    max-width: 400px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 0;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .card-title {\n    color: #333;\n    font-size: 24px;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .form-label {\n    font-size: 16px;\n  }\n  \n  .form-control {\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 1px solid #ccc;\n    border-radius: 0;\n    font-size: 16px;\n  }\n  \n  .btn-primary {\n    background-color: #457b9d;\n    color: #fff;\n    border: none;\n    border-radius: 0;\n    padding: 10px 20px;\n    font-size: 18px;\n    cursor: pointer;\n  }\n  \n  .btn-primary:hover {\n    background-color: #2f6281;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
