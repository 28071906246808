import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const MapChart = ({ CO2 }) => {
  const offices = useSelector((state) => state.companies.companies);
  const [markers, setMarkers] = useState([]);
  const [maxCO2, setMaxCO2] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const API_KEY = process.env.REACT_APP_MAPS_APIKEY;
  const sortedCO2ConsArray = CO2?.sort((a, b) => b?.CO2 - a?.CO2).slice(0, 10);

  useEffect(() => {
    const fetchMapData = async () => {
      let maxCO2 = 0;
      const markerPositions = new Map();

      const markerData = await Promise.all(
        sortedCO2ConsArray.map(async (data, index) => {
          const officeFound = offices?.find((o) => o?.name === data?.locationName);
          const { locationName, CO2: co2 } = data;
          const { address, state: province } = officeFound;
          const coordinates = await getCoordinates(address, province);

          maxCO2 = Math.max(maxCO2, co2);

          if (coordinates) {
            let key = `${coordinates.lat},${coordinates.lng}`;
            if (markerPositions.has(key)) {
              const variation = 0.00002 * (markerPositions.get(key) + 1);
              markerPositions.set(key, markerPositions.get(key) + 1);
              coordinates.lat += variation;
              coordinates.lng += variation;
            } else {
              markerPositions.set(key, 0);
            }

            return {
              position: { lat: coordinates.lat, lng: coordinates.lng },
              co2,
              locationName
            };
          } else {
            return null;
          }
        })
      );

      setMaxCO2(maxCO2);
      setMarkers(markerData.filter(Boolean));
    };
    fetchMapData();
  }, [CO2]);

  const getCoordinates = (address, province) => {
    return new Promise((resolve, reject) => {
      const fullAddress = `${address}, ${province}`;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${API_KEY}`;

      if(address){
        fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data?.status === 'OK' && data?.results[0]) {
            const { lat, lng } = data?.results[0]?.geometry?.location;
            resolve({ lat, lng });
          } else {
            console.error('Error obtaining coordinates:', address, data?.status);
            resolve(null);
          }
        })
        .catch(error => {
          console.error('Error fetching geocoding data:', error);
          reject(error);
        });
      } else{
        return null
      }
    });
  };

  const getColor = (co2) => {
    const scale = (co2 / maxCO2) * 100;
    return `hsl(${120 - scale * 1.2}, 100%, 50%)`;
  };

  return (
    <div>
      <p>Mapa de consumo de CO2</p>
      <GoogleMap
        mapContainerStyle={{ height: '600px', width: '100%' }}
        center={{ lat: -38.4161, lng: -63.6167 }}
        zoom={4}
      >
        {markers.map((marker, index) => (
          <Marker
            key={`marker-${index}`}
            position={marker.position}
            icon={{
              path: window?.google?.maps?.SymbolPath.CIRCLE,
              scale: 10,
              fillColor: getColor(marker.co2),
              fillOpacity: 0.6,
              strokeWeight: 0.4
            }}
            onClick={() => setSelectedMarker(marker)}
          />
        ))}
        {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <p>Ubicación: {selectedMarker.locationName}</p>
              <p>CO2: {selectedMarker.co2.toLocaleString()} ton</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <div
        style={{
          width: '100%',
          height: '10px',
          background: `linear-gradient(to right, hsl(120, 100%, 50%), ${getColor(maxCO2)})`,
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <p>0</p>
        <p>{maxCO2}</p>
      </div>
    </div>
  );
};

export default MapChart;
