import "./FilterComponent.css";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaChevronDown, FaFilter } from "react-icons/fa";
import Select from "react-select";
import { provinces, utilities } from "../../../helpers/infoFilter";
import { getAllcompanies } from "../../../redux/apiCalls/companiesCall";
import { useDispatch, useSelector } from "react-redux";
import { getAllItemsBill } from "../../../redux/apiCalls/itemBills";

function FilterComponent({
  filterOptions,
  setFilterOptions,
  filterClass,
  setFilterClass,
  board,
}) {
  const menuRefs = useRef([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const itemsBill = useSelector((state) => state.itemsBill.itemsBill);
  const suppliers = useSelector((state) => state.supplier.supplier);
  const companies = useSelector((state) => state.companies.companies);
  const [activeFilter, setActiveFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [classes, setClasses] = useState(null);

  const orderCompanies = (companies || [])
    .reduce((acc, c) => {
      const trimmedCompany = c.name.trim();
      if (trimmedCompany) {
        acc.push({ label: trimmedCompany, value: trimmedCompany });
      }
      return acc;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));

  let board1 = board?.board1[0]?.template;

  const handleClickOutside = (event) => {
    if (menuRefs.current) {
      menuRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (activeFilter === index) {
            setActiveFilter(null);
          }
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeFilter]);

  useEffect(() => {
    getAllItemsBill(dispatch, user?.accessToken);
  }, [dispatch]);

  useEffect(() => {
    getAllcompanies(dispatch, user?.accessToken);
  }, []);

  const handleFilterClick = (filterIndex) => {
    setActiveFilter(activeFilter === filterIndex ? null : filterIndex);
  };

  const handleProvinceChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterOptions((prev) => ({
      ...prev,
      provinces: selectedValues,
    }));
  };

  const handleUtilityChange = (e) => {
    const utility = e.target.value;
    const selectedUtilities = filterOptions.utilities;
  
    // Actualiza las utilidades seleccionadas
    let updatedFilterOptions = {
      ...filterOptions,
      utilities: selectedUtilities?.includes(utility)
        ? selectedUtilities?.filter((u) => u !== utility)
        : [...selectedUtilities, utility],
    };
  
    const suppliersSelected = suppliers?.filter((s) =>
      updatedFilterOptions.utilities.includes(s.servicio)
    );
  
    let uniqueClasses = [
      ...new Set(
        itemsBill
          ?.filter(
            (item) =>
              suppliersSelected?.some((supplier) =>
                item?.proveedor === supplier?.nombre
              ) &&
              item?.class !== "Total factura" &&
              item?.class !== "Total Factura"
          )
          ?.map((item) => item?.class)
      ),
    ]?.sort((a, b) => a.localeCompare(b));
  
    // Siempre verificar si "Gas Natural" está seleccionado para mantener "IVA"
    if (updatedFilterOptions?.utilities?.includes("GAS NATURAL")) {
      uniqueClasses = [...new Set([...uniqueClasses, "IVA"])];
    }
  
    setClasses(uniqueClasses);
    setFilterClass({
      class: filterClass.class.filter((c) => uniqueClasses.includes(c)),
    });
  
    // Si no hay utilidades seleccionadas, restablecer las clases
    if (updatedFilterOptions?.utilities?.length === 0) {
      updatedFilterOptions = {
        ...updatedFilterOptions,
        class: [],
      };
      setClasses(null);
    }
  
    setFilterOptions(updatedFilterOptions);
  };
  
  
  
  
  const handleTypeChange = (e) => {
    const type = e.target.value;
    const selectedTypes = filterClass?.class;
    if (selectedTypes?.includes(type)) {
      setFilterClass({
        ...filterClass,
        class: selectedTypes.filter((t) => t !== type),
      });
    } else {
      setFilterClass({
        ...filterClass,
        class: [...selectedTypes, type],
      });
    }
  };

  const handleLocationChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setFilterOptions((prev) => ({
      ...prev,
      companies: selectedValues,
    }));
  };

  const onChange = (date, isStart) => {
    let endFormat;
    let startFormat;
    let endOfMonth;

    if (date) {
      if (!isStart) {
        endOfMonth = new Date(date?.getFullYear(), date?.getMonth() + 1, 0);
        endFormat = `${endOfMonth?.getFullYear()}-${
          endOfMonth?.getMonth() + 1
        }-${endOfMonth?.getDate()}`;
        const startD = new Date(startDate);
        startFormat = `${startD?.getFullYear()}-${
          startD?.getMonth() + 1
        }-${startD?.getDate()}`;

        setFilterOptions({
          ...filterOptions,
          startDate: startFormat,
          endDate: endFormat,
        });
      }

      if (isStart) {
        startFormat = `${date?.getFullYear()}-${
          date?.getMonth() + 1
        }-${date?.getDate()}`;
        setStartDate(date);
      }

      setEndDate(endOfMonth);
    }
  };

  const renderDateFilterMenu = (index) => {
    return (
      <div
        ref={(el) => (menuRefs.current[index] = el)}
        className={`filter-menu-picker date-filter-menu ${
          activeFilter === index ? "active" : ""
        }`}
        style={{ top: activeFilter === index ? "60%" : "-9999px" }}
      >
        <div>
          <label htmlFor="startDate">Fecha inicio:</label>
          <DatePicker
            id="startDate"
            selected={startDate}
            onChange={(date) => onChange(date, true)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Selecciona mes inicio"
            minDate={new Date("2023-01-01")}
            maxDate={new Date(Date.now())}
          />
        </div>
        <div>
          <label htmlFor="endDate">Fecha fin:</label>
          <DatePicker
            id="endDate"
            selected={endDate}
            onChange={(date) => onChange(date, false)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date(Date.now())}
            placeholderText="Selecciona mes fin"
          />
        </div>
      </div>
    );
  };

  const renderFilterMenu = (filterName, options, index) => {
    const uniqueOptions = new Set(options);

    return (
      <div
        ref={(el) => (menuRefs.current[index] = el)}
        className={`filter-menu ${activeFilter === index ? "active" : ""}`}
        style={{ top: activeFilter === index ? "55%" : "-9999px" }}
      >
        {filterName === "Tipo" && !classes ? (
          <label>Primero selecciona un servicio</label>
        ) : (
          <>
            {[...uniqueOptions].map((option) => (
              <label key={filterName === "Provincia" ? option.name : option}>
                <input
                  type="checkbox"
                  value={filterName === "Provincia" ? option.value : option}
                  checked={
                    filterName === "Provincia"
                      ? filterOptions.provinces.includes(option.value)
                      : filterName === "Utilidad"
                      ? filterOptions.utilities.includes(option)
                      : filterName === "Ubicación"
                      ? filterOptions.companies?.includes(option)
                      : filterClass.class.includes(option)
                  }
                  onChange={
                    filterName === "Provincia"
                      ? handleProvinceChange
                      : filterName === "Utilidad"
                      ? handleUtilityChange
                      : filterName === "Ubicación"
                      ? handleLocationChange
                      : filterName === "Tipo"
                      ? handleTypeChange
                      : undefined
                  }
                />
                {filterName !== "Provincia" ? option : option.name}
              </label>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="filter-container">
      <div className="filter-button-container">
        <button
          className={`filter-button ${activeFilter === 0 ? "active" : ""}`}
          onClick={() => handleFilterClick(0)}
        >
          Período
          <i>
            <FaChevronDown />
          </i>
        </button>
        {renderDateFilterMenu(0)}
      </div>

      <div
        className="filter-button-container"
        onClick={() => handleFilterClick(1)}
      >
        <Select
          defaultValue={[]} // Valores predeterminados si es necesario
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          placeholder="Provincia"
          options={provinces}
          components={{ DropdownIndicator: () => <FaChevronDown /> }} // Personaliza el indicador del desplegable
          className={`filter-button-select ${
            activeFilter === 1 ? "active" : ""
          }`}
          onChange={handleProvinceChange} // Adjuntar el controlador aquí
          value={provinces.filter((option) =>
            filterOptions.provinces.includes(option.value)
          )}
        >
          Provincia
          <i>
            <FaChevronDown />
          </i>
        </Select>
      </div>

      <div
        className="filter-button-container filter-offices"
        onClick={() => handleFilterClick(2)}
      >
        <Select
          defaultValue={[]}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          placeholder="Sucursal"
          options={orderCompanies}
          components={{ DropdownIndicator: () => <FaChevronDown /> }}
          className={`filter-button-selected ${
            activeFilter === 2 ? "active" : ""
          }`}
          onChange={handleLocationChange} // Attach the handler here
          value={orderCompanies.filter((option) =>
            filterOptions.companies.includes(option.value)
          )}
        >
          Sucursal
          <i>
            <FaChevronDown />
          </i>
        </Select>
              
      </div>

      <div className="filter-button-container">
        <button
          className={`filter-button ${activeFilter === 3 ? "active" : ""}`}
          onClick={() => handleFilterClick(3)}
        >
          Servicio
          <i>
            <FaChevronDown />
          </i>
        </button>
        {renderFilterMenu("Utilidad", utilities, 3)}
      </div>

      {board1 !== "templateC" ? (
        <div className="filter-button-container">
          <button
            className={`filter-button ${activeFilter === 4 ? "active" : ""}`}
            onClick={() => handleFilterClick(4)}
          >
            Concepto
            <i>
              <FaChevronDown />
            </i>
          </button>
          {renderFilterMenu("Tipo", classes, 4)}
        </div>
      ) : null}
    </div>
  );
}

export default FilterComponent;
