import { createSlice } from "@reduxjs/toolkit";

const itemBillsSlice = createSlice({
  name: "itemsBill",
  initialState: {
    itemsBill: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    itemsBillStart: (state) => {
      state.isFetching = true;
    },
    itemsBillSuccess: (state, action) => {
      state.itemsBill = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    itemsBillFailure: (state,action) => {
      state.isFetching = false;
      state.itemsBill = action.payload;
      state.error = true;
    },
    logoutInfoItemsBill: (state) => {
      state.itemsBill = null;
      state.isFetching = false;
      state.error = false;
    }
  },
});

export const {
    itemsBillStart,
    itemsBillFailure,
    itemsBillSuccess,
    logoutInfoItemsBill
} = itemBillsSlice.actions;

export default itemBillsSlice.reducer;