import XLSX from "sheetjs-style";
import { useDispatch, useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";
import { Toast } from "../../../helpers/alert";
import { getPowerReport } from "../../../redux/apiCalls/billsReportCall";

export default function AuditPowerReport({ filters }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);
  const supplierSelected =
    filters?.supplierSelected !== "allSuppliers"
      ? filters?.supplierSelected
      : "";

  const downloadExcel = () => {
    Toast.fire({
      icon: "info",
      title: "Se está generando el reporte. Pronto comenzará la descarga.",
    });

    getPowerReport(dispatch, user?.accessToken, filters)
      .then((res) => {
        const wb = XLSX.utils.book_new();
        const monthsName = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        const headerDates = [
          `AUDITORÍA DE POTENCIA - ${supplierSelected} ${filters?.year}`,
        ];

        const aggregatedData = {};

        Object.keys(res).forEach((month) => {
          const monthData = res[month];
          if (Array.isArray(monthData)) {
            monthData.forEach((item) => {
              const site = item["nombre del sitio"];

              if (!aggregatedData[site]) {
                aggregatedData[site] = {
                  facturasConsideradas: new Array(12).fill("N/A"),
                  otrosDatos: {},
                };
              }

              const monthIndex = Object.keys(res).indexOf(month);
              aggregatedData[site].facturasConsideradas[monthIndex] =
                item["act"] ?? "N/A";

              const classConcepts = {};

              Object.keys(item).forEach((key) => {
                if (
                  ![
                    "nombre del sitio",
                    "proveedor",
                    "mes",
                    "año",
                    "act",
                  ].includes(key)
                ) {
                  const [concept, cls] = key.split(" - ");

                  if (!classConcepts[cls]) {
                    classConcepts[cls] = new Set();
                  }
                  classConcepts[cls].add(concept);
                }
              });

              Object.keys(classConcepts).forEach((cls) => {
                if (classConcepts[cls].size === 1) {
                  const concept = [...classConcepts[cls]][0];
                  const key = `${cls}`;

                  if (!aggregatedData[site].otrosDatos[key]) {
                    aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                      "N/A"
                    );
                  }
                  if (monthIndex !== -1) {
                    aggregatedData[site].otrosDatos[key][monthIndex] =
                      item[`${concept} - ${cls}`] ?? "N/A";
                  }
                } else {
                  classConcepts[cls].forEach((concept) => {
                    const key = `${concept} - ${cls}`;

                    if (!aggregatedData[site].otrosDatos[key]) {
                      aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                        "N/A"
                      );
                    }
                    if (monthIndex !== -1) {
                      aggregatedData[site].otrosDatos[key][monthIndex] =
                        item[key] ?? "N/A";
                    }
                  });
                }
              });
            });
          }
        });

        const ws = XLSX.utils.aoa_to_sheet([
          ["", "", ""].concat(headerDates),
          [],
          ["SUCURSALES", "CLASES"].concat(monthsName),
        ]);

        ws["!cols"] = [
          { width: 30 },
          { width: 30 },
          ...Array(monthsName.length).fill({ width: 12 }),
        ];

        let rowIndex = 4;

        Object.keys(aggregatedData).forEach((site) => {
          const data = aggregatedData[site];

          const classOrder = [
            "Potencia Contratada",
            "Potencia Demandada",
            "Potencia Excedida",
            "Coseno fi",
            "Tangente fi",
            "Total factura"
          ];

          const sortedKeys = Object.keys(data.otrosDatos).sort((a, b) => {
            const getClass = (key) => {
              const parts = key.split(" - ");
              return parts.length === 2 ? parts[1]?.trim() : parts[0]?.trim();
            };

            const classA = getClass(a);
            const classB = getClass(b);

            const indexA = classOrder.findIndex((order) =>
              classA.includes(order)
            );
            const indexB = classOrder.findIndex((order) =>
              classB.includes(order)
            );

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
          });

          XLSX.utils.sheet_add_aoa(
            ws,
            [
              [site, "Facturas consideradas"].concat(data.facturasConsideradas),
              ...sortedKeys.map((field) => [
                site,
                field,
                ...data.otrosDatos[field],
              ]),
            ],
            { origin: { r: rowIndex, c: 0 } }
          );

          sortedKeys.forEach((field, fieldIndex) => {
            if (field.toLowerCase().includes("potencia demandada")) {
              const values = data.otrosDatos[field];
              values.forEach((value, valueIndex) => {
                if (valueIndex > 1) {
                  const prevValue = values[valueIndex - 1];
                  const twoMonthsAgoValue = values[valueIndex - 2] || "N/A";
                  const oneMonthAgoValue = values[valueIndex - 1] || "N/A";

                  if (
                    value !== "N/A" &&
                    oneMonthAgoValue !== "N/A" &&
                    twoMonthsAgoValue !== "N/A" &&
                    value === twoMonthsAgoValue * 2
                  ) {
                    const columnIndex = 2 + valueIndex;
                    const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                    const cellRef = XLSX.utils.encode_cell({
                      r: baseRow,
                      c: columnIndex,
                    });

                    if (!ws[cellRef]) ws[cellRef] = {};
                    if (!ws[cellRef].s) {
                      ws[cellRef].s = {
                        fill: { fgColor: { rgb: "ff5252" } },
                        font: {
                          name: "Arial",
                          color: { rgb: "FFFFFF" },
                        },
                      };
                    }
                  }
                }
              });
            }
          });

         // Antes de hacer el coloreado en sortedKeys.forEach (revisa cada sección de coloreado)
sortedKeys.forEach((field, fieldIndex) => {
  if (field.toLowerCase().includes("potencia demandada")) {
      const values = data.otrosDatos[field];

      values.forEach((value, valueIndex) => {
          const facturasConsideradasActual = data.facturasConsideradas[valueIndex];
          const facturasConsideradasAnterior = data.facturasConsideradas[valueIndex - 1];

          // Nueva condición: si facturas consideradas son N/A para el mes actual o el anterior, no colorear
          if (facturasConsideradasActual === "N/A" || facturasConsideradasAnterior === "N/A") {
              return;
          }

          if (valueIndex > 1) {
              const prevValue = values[valueIndex - 1];
              const twoMonthsAgoValue = values[valueIndex - 2] || "N/A";
              const oneMonthAgoValue = values[valueIndex - 1] || "N/A";

              if (
                  value !== "N/A" &&
                  oneMonthAgoValue !== "N/A" &&
                  twoMonthsAgoValue !== "N/A" &&
                  value === twoMonthsAgoValue * 2
              ) {
                  const columnIndex = 2 + valueIndex;
                  const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                  const cellRef = XLSX.utils.encode_cell({
                      r: baseRow,
                      c: columnIndex,
                  });

                  if (!ws[cellRef]) ws[cellRef] = {};
                  if (!ws[cellRef].s) {
                      ws[cellRef].s = {
                          fill: { fgColor: { rgb: "ff5252" } },
                          font: {
                              name: "Arial",
                              color: { rgb: "FFFFFF" },
                          },
                      };
                  }
              }
          }
      });
  }
});


          const epsilon = 0.01;
          sortedKeys.forEach((field, fieldIndex) => {
            if (field.toLowerCase().includes("potencia excedida")) {
              const exceededValues = data.otrosDatos[field];
              const contractedValues = data.otrosDatos["Potencia Contratada"] || [];
              const demandedValues = data.otrosDatos["Potencia Demandada"] || [];

              exceededValues.forEach((exceededValue, valueIndex) => {
                const contractedValue = contractedValues[valueIndex];
                const demandedValue = demandedValues[valueIndex];

                if (
                  contractedValue !== undefined &&
                  demandedValue !== undefined &&
                  !isNaN(contractedValue) &&
                  !isNaN(demandedValue) &&
                  !isNaN(exceededValue)
                ) {
                  if (demandedValue >= contractedValue) {
                    const calculatedExceeded = demandedValue - contractedValue;

                    if (Math.abs(calculatedExceeded - parseFloat(exceededValue)) > epsilon) {
                      const columnIndex = 2 + valueIndex;
                      const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                      const cellRef = XLSX.utils.encode_cell({
                        r: baseRow,
                        c: columnIndex,
                      });

                      if (!ws[cellRef]) ws[cellRef] = {};
                      if (!ws[cellRef].s) {
                        ws[cellRef].s = {
                          fill: { fgColor: { rgb: "ff5252" } },
                          font: {
                            name: "Arial",
                            color: { rgb: "FFFFFF" },
                          },
                        };
                      }
                    }
                  }
                }
              });
            }
          });

          

          rowIndex += 1 + sortedKeys.length;
        });

        XLSX.utils.book_append_sheet(wb, ws, `Reporte ${supplierSelected}`);

        XLSX.writeFile(
          wb,
          `ReporteAuditoriaPotencia_Año_${filters?.year}_Proveedor_${supplierSelected}.xlsx`
        );
      })
      .catch((err) => {
        Toast.fire({
          icon: "error",
          title: `Hubo un error al intentar descargar el reporte. Intentelo nuevamente o pruebe otras opciones. ${err}`,
        });
      });
  };

  return (
    <>
      <button onClick={downloadExcel}>
        <FaFileDownload /> Descargar potencia por mes
      </button>
    </>
  );
}
