import financeDash from '../assets/financeDash.png'
import summaryDash from '../assets/summaryDash.png'

export const generateID = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 10);
  const id = `${timestamp}-${random}`;
  return id;
};

export const listWidgets = [
  {
    id: generateID(),
    img: financeDash,
  },
  {
    id: generateID(),
    img: summaryDash,
  }
]


export const compList = [
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-2/256/chart-line-thin-512.png",
    component: "LineChart",
    checked: false,
  },
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-2/256/chart-bar-thin-512.png",
    component: "BarChart",
    checked: false,
  },
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-2/256/chart-pie-thin-512.png",
    component: "PieChart",
    checked: false,
  },
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-1/256/chart-bar-horizontal-thin-512.png",
    component: "BulletChart",
    checked: false,
  },
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-3/256/lifebuoy-thin-512.png",
    component: "DoughnutChart",
    checked: false,
  },
  {
    id: generateID(),
    url: "https://cdn0.iconfinder.com/data/icons/phosphor-thin-vol-2/256/clipboard-text-thin-512.png",
    component: "ToDoList",
    checked: false,
  },
];


export const convertToArgentinaTime = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleString("es-AR", {
    timeZone: "America/Argentina/Buenos_Aires",
  });
};

export const findHighestBoardNumber = (board) => {
  const existingBoardNumbers = Object.keys(board).map((boardName) =>
    parseInt(boardName.replace("board", ""), 10)
  );
  return Math.max(...existingBoardNumbers);
};