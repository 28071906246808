import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/alert";
import { getAllItemsBill, updateItemBill } from "../../redux/apiCalls/itemBills";

const EditItemBill = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const supplier = useSelector((state) => state.supplier.supplier);
  const allItems = useSelector((state) => state.itemsBill.itemsBill);
  const currentItem = allItems.find((item) => item.id === Number(id));
  const [modifiedFields, setModifiedFields] = useState({});
  const [units, setUnits] = useState([]);
  const [inputs, setInputs] = useState({
    lineItemText: currentItem?.lineItemText || "",
    proveedor: currentItem?.proveedor.toUpperCase() || "",
    class: currentItem?.class || "",
    unit: currentItem?.unit || "",
    costUnit: currentItem?.costUnit || "",
    consumptionUnit: currentItem?.consumptionUnit || "",
  });
  const [errors, setErrors] = useState({
    lineItemText: "",
    proveedor: "",
    class: "",
  });
  let sortedSuppliers = supplier ? [...supplier].sort((a, b) => a.nombre.localeCompare(b.nombre)) : [];

  useEffect(() => {
    if (user.privilege === "editor" || user.privilege === "master") {
      getAllItemsBill(dispatch, user?.accessToken, "");
    }
  }, [dispatch, user]);

  useEffect(() => {
    const uniqueUnits = [
      ...new Set(
        allItems
          ?.map((item) => item.consumptionUnit)
          .filter((unit) => unit !== null && unit !== "null") || []
      ),
    ].sort((a, b) => a.localeCompare(b));
    setUnits(uniqueUnits);
  }, [allItems]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setModifiedFields((prevFields) => ({
      ...prevFields,
      [name]: true,
    }));

    validateField(name, value);
  };

  const validateField = (name, value) => {
    switch (name) {
      case "lineItemText":
        setErrors((prevErrors) => ({
          ...prevErrors,
          lineItemText: value.trim() === "" ? "Campo requerido" : "",
        }));
        break;
      case "proveedor":
        setErrors((prevErrors) => ({
          ...prevErrors,
          proveedor: value === "" ? "Selecciona un proveedor" : "",
        }));
        break;
      case "class":
        setErrors((prevErrors) => ({
          ...prevErrors,
          class: value.trim() === "" ? "Campo requerido" : "",
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const modifiedData = {};

    for (const key in modifiedFields) {
      if (modifiedFields[key]) {
        modifiedData[key] = inputs[key];
      }
    }
    const keys = Object.keys(modifiedData);
    const isValidKeys = keys.length <= 2 && keys.every(key => ['consumptionUnit', 'proveedor'].includes(key));

    const isConsumptionUnitNull = modifiedData['consumptionUnit'] === 'null';
    const isValid = isValidKeys && isConsumptionUnitNull;

    Swal.fire({
      title: "¿Está seguro que desea editar el concepto?",
      text:  isValid ? "ATENCIÓN: En caso que SOLO hayas modificado la unidad de consumo a 'No corresponde consumo' esto editará dicho concepto de todas las facturas asociadas a este proveedor. Les eliminará el consumo y les cambiará la unidad. Su usuario quedará registrado." : "",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
      input: "checkbox",
      inputValue: 0, // Valor inicial del checkbox (no marcado)
      inputPlaceholder: "Entiendo y quiero confirmar la modificación.",
      inputValidator: (result) => {
        return !result && "Debes confirmar tildando la casilla para proceder";
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const dataItem = {
          modifiedData,
          info: {
            ...inputs,
            oldConsumptionUnit: currentItem?.consumptionUnit
          },
          username: user?.user
        }

        updateItemBill(dispatch, id, dataItem, user.accessToken).then(
          (res) => {
            if (res?.error) {
              Toast.fire({
                icon: "error",
                title: res?.message,
              });
            }else{
              navigate(
                `/admin/lists?show=itemsBill&supplier=${currentItem?.proveedor?.toLowerCase()}&itemsBillId=${id}`
              );

            }
            
          }
        );
      } else {
        Toast.fire({
          icon: "info",
          title: `No se pudo editar el concepto.`,
        });
      }
    });
  };

  return (
    <div className="edit-user-container">
      <div
        className="cont-back"
        onClick={() => navigate(`/admin/lists?show=itemsBill`)}
      >
        <i>
          <FaArrowLeft />
        </i>
        <p>Volver</p>
      </div>
      <h2>Editar concepto</h2>
      <form>
        <div className="form-group">
          <label htmlFor="lineItemText">Concepto:</label>
          <input
            type="text"
            id="lineItemText"
            name="lineItemText"
            placeholder={currentItem?.lineItemText}
            value={inputs.lineItemText}
            onChange={handleInputChange}
          />
          <span className="error-message">{errors.lineItemText}</span>
        </div>
        <div className="form-group">
          <label htmlFor="proveedor">Proveedor:</label>
          <select
            id="proveedor"
            name="proveedor"
            value={inputs.proveedor}
            onChange={handleInputChange}
          >
            {sortedSuppliers &&
              sortedSuppliers.map((s) => (
                <option value={s.nombre.toUpperCase()} key={s.id}>
                  {s.nombre}
                </option>
              ))}
            <option value={inputs.proveedor} key="customSupplier">
              {inputs.proveedor}
            </option>
          </select>
          <span className="error-message">{errors.proveedor}</span>
        </div>
        <div className="form-group">
          <label htmlFor="class">Clase:</label>
          <input
            type="text"
            id="class"
            name="class"
            value={inputs.class}
            placeholder={currentItem?.class}
            onChange={handleInputChange}
          />
          <span className="error-message">{errors.class}</span>
        </div>
        <div className="form-group">
          <label htmlFor="consumptionUnit">Unidad de consumo: </label>
          <select
            id="consumptionUnit"
            name="consumptionUnit"
            value={inputs.consumptionUnit}
            onChange={handleInputChange}
          >
            <option value={"null"}>No corresponde consumo</option>
            {units &&
              units.map((u) => (
                <option key={u} value={u}>
                  {u}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="costUnit">Unidad de costo: </label>
          <select
            id="costUnit"
            name="costUnit"
            value={inputs.costUnit}
            onChange={handleInputChange}
          >
            <option value={"null"}>Sin costo</option>
            <option value={"N/A"}>N/A</option>
            <option value={"AR"}>AR</option>
            <option value={"USD"}>USD</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit" onClick={handleSubmit}>
            Editar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditItemBill;
