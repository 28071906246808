import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    usersStart: (state) => {
      state.isFetching = true;
    },
    usersSuccess: (state, action) => {
      state.users = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    usersFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE USER
    deleteStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteSuccess: (state, action) => {
      state.users = action.payload;
      state.isFetching = false;
      state.error = false;
    },
    deleteFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutInfousers: (state) => {
      state.users = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {
  usersStart,
  usersSuccess,
  usersFailure,
  deleteStart,
  deleteSuccess,
  deleteFailure,
  logoutInfousers,
} = usersSlice.actions;

export default usersSlice.reducer;
