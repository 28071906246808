import "./HelpSideBar.css"
import React from 'react'
import { FaQuestion, FaRegQuestionCircle } from 'react-icons/fa'
import HelpSidebar from "./TemplatesHelp/HelpSidebar"
import DashboardsInfo from "./TemplatesHelp/DashboardsInfo"
import AccountInfo from "./TemplatesHelp/AccountInfo"
import ChangePassInfo from "./TemplatesHelp/ChangePassInfo"
import ListsInfo from "./TemplatesHelp/ListsInfo"
import ItemBillInfo from "./TemplatesHelp/ItemBillInfo"
import UploadBillInfo from "./TemplatesHelp/UploadBillInfo"

const FloatingHelpIcon = ({infoHelp}) => {
  return (
    <div>
      <button className="btn floatingHelpIcon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">?</button>
      {
      infoHelp === "dashboards" 
      ? <DashboardsInfo/>
      : infoHelp === "account"
      ? <AccountInfo/>
      : infoHelp === "changePass"
      ? <ChangePassInfo/>
      : infoHelp === "lists"
      ? <ListsInfo/>
      : infoHelp === "itemBill"
      ? <ItemBillInfo/>
      : infoHelp === "uploadBill"
      ? <UploadBillInfo/>
      : <HelpSidebar/>
      }
    </div>
  )
}

export default FloatingHelpIcon