import "./DashboardSelected.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CorporateSummary from "./DashboardCorpSummary/CorporateSummary";
import FinanceDashboard from "./DashFinance/FinanceDashboard";
import { getAnualData, getCO2Data, getData, getGeneralData, getGeneralDataCO2 } from "../../redux/apiCalls/dataCall";
import { applyFilters } from "../../helpers/functions";
import { getAllsupplier } from "../../redux/apiCalls/supplierCall";
import DashSustainability from "./DashSustainability/DashSustainability";
import DashConsumption from "./DashConsumption/DashConsumption";
import FloatingHelpIcon from "../HelpSidebar/FloatingHelpIcon";
import { getAllsupplierCompany } from "../../redux/apiCalls/supplierCompanyCall";

const DashboardSelector = ({
  board,
  filterOptions,
  boardSelected,
  filterClass,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const data = useSelector((state) => state.data.currentData);
  const co2 = useSelector((state) => state.data.co2Data);
  const selectedBoard = board[boardSelected];
  const [filteredData, setFilteredData] = useState([]);

  const [dataLoaded, setDataLoaded] = useState({
    selectedBoardData: false,
    supplierData: false,
    supplierCompanyData: false,
    generalData: false,
    anualData: false,
    co2Data: false,
    generalDataCO2: false,
  });
  

  useEffect(() => {
    if (selectedBoard && selectedBoard.length > 0 && filterOptions.endDate) {
      getData(dispatch, user?.accessToken, {
        startDate: filterOptions.startDate,
        endDate: filterOptions.endDate,
        provinces: [],
        companies: [],
        utilities: [],
        class: [],
      }).then((res) => {
        applyFilters(filterOptions, filterClass, res, setFilteredData);
        setDataLoaded(prev => ({ ...prev, selectedBoardData: true }));
      });
    }
  }, [filterOptions.endDate, filterOptions.startDate]);

  useEffect(() => {
    
    applyFilters(filterOptions, filterClass, updatedData, setFilteredData);
  }, [filterOptions, filterClass]);
  

  useEffect(() => {
    getAllsupplier(dispatch, user.accessToken).then(() => {
      setDataLoaded(prev => ({ ...prev, supplierData: true }));
    });
  }, [dispatch]);
  

  useEffect(() => {
    getAllsupplierCompany(dispatch, user?.accessToken).then(() => {
      setDataLoaded(prev => ({ ...prev, supplierCompanyData: true }));
    });
  }, [dispatch]);
  

  useEffect(() => {
    getGeneralData(dispatch, user?.accessToken).then(() => {
      setDataLoaded(prev => ({ ...prev, generalData: true }));
    });
  }, []);
  

  useEffect(() => {
    if (
      dataLoaded.selectedBoardData
    ) {
      getAnualData(dispatch, user?.accessToken).then(() => {
        setDataLoaded(prev => ({ ...prev, anualData: true }));
      });
    }
  }, [dataLoaded.selectedBoardData]);
  

  useEffect(() => {
    if (
      dataLoaded.selectedBoardData &&
      dataLoaded.anualData
    ) {
      getCO2Data(dispatch, user?.accessToken).then(() => {
        setDataLoaded(prev => ({ ...prev, co2Data: true }));
      });
    }
  }, [dataLoaded.selectedBoardData, dataLoaded.anualData]);
  
  
  useEffect(() => {
      getGeneralDataCO2(dispatch, user?.accessToken).then(() => {
        setDataLoaded(prev => ({ ...prev, generalDataCO2: true }));
      });
  }, []);
  
  
     // Actualización de filteredData para incluir la clase 'IVA' y eliminar 'impuestos' si corresponde
  const updatedFilteredData = filteredData?.map(item => {
    if ((item?.lineItemText?.toUpperCase()?.includes("IVA") ||  item?.lineItemText?.toUpperCase()?.includes("I.V.A") || item?.lineItemText?.includes("Imp. Valor Agregado")) && !item?.lineItemText?.toUpperCase()?.includes('ACTIVA') && !item?.lineItemText?.toUpperCase()?.includes('REACTIVA')) {
      return {
        ...item,
        class: 'IVA'
      };
      
    }else if (item?.class?.toUpperCase() === "IMPUESTOS" || item?.class?.toUpperCase() === "IMPUESTO") {
      return {
        ...item,
        class: item?.class !== "IVA" ? item?.class : "" // Mantén "impuestos" solo si no es "IVA"
      };
    }
    return item;
  });
   // Actualización de filteredData para incluir la clase 'IVA' y eliminar 'impuestos' si corresponde
   const updatedData = data?.map(item => {
    if ((item?.lineItemText?.toUpperCase()?.includes("IVA") ||  item?.lineItemText?.toUpperCase()?.includes("I.V.A") || item?.lineItemText?.includes("Imp. Valor Agregado")) && !item?.lineItemText?.toUpperCase()?.includes('ACTIVA') && !item?.lineItemText?.toUpperCase()?.includes('REACTIVA')) {
      return {
        ...item,
        class: 'IVA'
      };
      
    }else if (item?.class?.toUpperCase() === "IMPUESTOS" || item?.class?.toUpperCase() === "IMPUESTO") {
      return {
        ...item,
        class: item?.class !== "IVA" ? item?.class : "" // Mantén "impuestos" solo si no es "IVA"
      };
    }
    return item;
  });
  let modalId;
  let idx;

  return (
    <div className="selector-container">
      {selectedBoard?.length
        ? selectedBoard?.map((template, index) => {
            modalId = `filter-chart-${index}`;
            idx = index;
            return (
              <div
                key={template.id_widget}
                style={{ position: "relative" }}
                className="cont-template"
              >
                {template.template === "templateA" ? (
                  <DashConsumption
                    data={updatedFilteredData}
                    co2={co2}
                    filterOptions={filterOptions}
                    filterClass={filterClass}
                  />
                ) : template.template === "templateB" ? (
                  <FinanceDashboard
                    data={updatedFilteredData}
                    co2={co2}
                    filterOptions={filterOptions}
                    filterClass={filterClass}
                  />
                ) : template.template === "templateC" ? (
                  <DashSustainability
                    data={updatedFilteredData}
                    co2={co2}
                    filterOptions={filterOptions}
                    filterClass={filterClass}
                  />
                ) : 
                  <CorporateSummary
                    data={updatedFilteredData}
                    co2={co2}
                    filterOptions={filterOptions}
                    filterClass={filterClass}
                  />
                }
              </div>
            );
          })
        : null}
        <FloatingHelpIcon infoHelp={"dashboards"}/>
    </div>
  );
};

export default DashboardSelector;
