import "./LineItemText.css";
import React, { useEffect, useState } from "react";

function validar(otherCharges) {
  let errors = {};
  const regex = /^[0-9;.,\-\s]+$/;

  if (otherCharges && !regex.test(otherCharges)) {
    errors.otherCharges =
      "Los valores deben ser numéricos. En caso de querer agregar varios separarlos con ; (punto y coma). No ingresar letras.";
  }

  return errors;
}

const LineItemText = ({ itemsBill, setInputs, inputs, errors, setErrors, extractedData, supplierName  }) => {
  const [showCharge, setShowCharge] = useState(false);
  const [displayValues, setDisplayValues] = useState({}); // Estado separado para los valores originales
// Agrega un identificador único a cada elemento en itemsBillFiltered y inputs.lineItemCost, valida que el proveedor seleccionado sea igual al proveedor de los conceptos
const itemsBillFiltered = itemsBill?.filter((item) => {
  const isNotTotalFactura = item?.lineItemText !== "Total Factura";
  const isSupplierMatch = supplierName?.includes('FUSIONSOLAR')
    ? (item?.proveedor)?.toUpperCase() === supplierName?.toUpperCase()
    : true;
  return isNotTotalFactura && isSupplierMatch;
}).map((item, index) => ({ ...item, id: `item_${index}` }));

const updatedLineItemCost = itemsBillFiltered.map((item) => ({
  ...item,
  id: `item_${item.id}`, // Asegúrate de que el identificador sea único
}));

  const totalCost = inputs.lineItemCost?.reduce((acc, item) => {
    if (item.lineItemText !== "Total Factura") {
      const costWithoutComma = typeof item.cost === 'string' ? item.cost.replace(",", ".") : item.cost;
      const costValue = parseFloat(costWithoutComma);
  
      if (!isNaN(costValue)) {
        if (costValue === -1) {
          return acc; // Ignora el valor -1
        } else {
          return costValue >= 0 ? acc + costValue : acc - Math.abs(costValue);
        }
      }
    }
    return acc;
  }, 0);


  useEffect(() => {
    if (totalCost !== undefined && !isNaN(totalCost)) {
      const totalFacturaIndex = inputs.lineItemCost.findIndex(
        (item) => item.lineItemText === "Total Factura"
      );

      if (totalFacturaIndex !== -1) {
        const updatedLineItemCost = [...inputs.lineItemCost];
        updatedLineItemCost[totalFacturaIndex] = {
          ...updatedLineItemCost[totalFacturaIndex],
          cost: totalCost.toFixed(2).toString(),
        };

        setInputs({
          ...inputs,
          lineItemCost: updatedLineItemCost,
        });
      } else {
        const unitTotal = itemsBill.find((i) => i.class === "Total factura");
        setInputs({
          ...inputs,
          lineItemCost: [
            ...inputs.lineItemCost,
            {
              lineItemText: "Total Factura",
              class: "Total factura",
              consumption: "-1",
              cost: totalCost.toString(),
              units: unitTotal?.unit,
            },
          ],
        });
      }
    }
  }, [totalCost]);

  // function validar(otherCharges) {
  //   let errors = {};
  //   // Expresión regular para validar números que permiten punto y coma antes de los signos
  //   const regex = /^(?:-?\d+(\.\d+)?)(; -?\d+(\.\d+)?)*$/;
  
  //   // Comprobar si los signos negativos no están precedidos por un punto y coma
  //   const hasInvalidNegative = /(?<!;)-\d+/.test(otherCharges); // Uso de lookbehind para verificar el signo negativo
  
  //   if (otherCharges && !regex.test(otherCharges) || hasInvalidNegative) {
  //     errors.otherCharges =
  //       "Los valores deben ser numéricos. En caso de querer agregar varios, sepáralos con ';' (punto y coma). No ingreses letras y asegúrate de que los signos negativos estén precedidos por un punto y coma.";
  //   }
  
  //   return errors;
  // }
  
  
  const handleOtherCharge = (e, itemName, itemClass, unit) => {
    e.preventDefault();
    let values = e.target.value;
    setErrors(validar(values));
    values = values.trim().split(";");
    if (values[values.length - 1] === "") {
      values.pop();
    }
    if (values[0] === "") {
      values.shift();
    }

    const total = values.reduce((total, parte) => {
      const valueNumber = parseFloat(parte.replace(",", "."));

      if (!isNaN(valueNumber)) {
        return total + valueNumber;
      } else {
        //si no es número se ignora
        return total;
      }
    }, 0);

    const itemFoundIndex = inputs.lineItemCost.findIndex(
      (item) => item.lineItemText === itemName
    );

    if (itemFoundIndex !== -1) {
      //si existe le actualizo el valor
      const updatedLineItemCost = [...inputs.lineItemCost];
      updatedLineItemCost[itemFoundIndex] = {
        ...updatedLineItemCost[itemFoundIndex],
        cost: total?.toFixed(2).toString(),
      };

      setInputs({
        ...inputs,
        lineItemCost: updatedLineItemCost,
      });
    } else {
      setInputs({
        ...inputs,
        lineItemCost: [
          ...inputs.lineItemCost,
          {
            lineItemText: itemName,
            class: itemClass,
            cost: total?.toFixed(2).toString(),
            consumption: "-1",
            units: unit,
          },
        ],
      });
    }
  };
  
  

  // useEffect(() => {
  //   const updatedLineItemCost = itemsBillFiltered.map((item) => {
  //     const matchingItem = extractedData.consolidatedData?.find((data) => {
  //       const itemNombre = data.lineItemText.toLowerCase();
  //       const itemText = item.lineItemText.toLowerCase();
  //       return itemText.includes(itemNombre);
  //     });
  
  //     return {
  //       lineItemText: item.lineItemText,
  //       class: item.class,
  //       consumption: matchingItem?.consumption,
  //       units: item.consumptionUnit,
  //       cost: matchingItem?.cost,
  //     };
  //   });
  
  //   // Filter out objects with undefined cost or consumption
  //   const filteredLineItemCost = updatedLineItemCost.filter(
  //     (item) => item.cost !== undefined && item.consumption !== undefined
  //   );
  
  //   setInputs({
  //     ...inputs,
  //     lineItemCost: filteredLineItemCost,
  //   });
  // }, [extractedData]);
  

  const handleItemChange = (e, itemText, itemClass, costUnit, consumptionUnit) => {
    const { name, value } = e.target;

    // Regex para permitir números, puntos, comas, punto y coma, y signos de + y -
    const regex = /^[0-9;.,\-\+\s]*$/;

    // Validar que el input no contenga letras, operaciones matemáticas, o caracteres no permitidos
    if (name === "cost" && !regex.test(value)) {
        setErrors({
            ...errors,
            cost: "Solo se permiten números y punto y coma (;), sin operaciones ni letras.",
        });
        return;
    } else {
        setErrors({
            ...errors,
            cost: "",
        });
    }

    const updatedLineItemCost = [...inputs.lineItemCost];
    const itemFoundIndex = updatedLineItemCost.findIndex(
        (item) => item.lineItemText === itemText
    );

    if (itemFoundIndex !== -1) {
        const currentItem = updatedLineItemCost[itemFoundIndex];
        currentItem[name] = value; // Actualiza el valor del input directamente, sin cálculos

        setInputs({
            ...inputs,
            lineItemCost: updatedLineItemCost,
        });
        // Actualiza solo el valor que se muestra (original ingresado por el usuario)
    setDisplayValues((prevValues) => ({
      ...prevValues,
      [itemText]: value,
  }));
    } else {
        const newItem = {
            lineItemText: itemText,
            class: itemClass,
            units: consumptionUnit && consumptionUnit !== "null" ? consumptionUnit.trim() : costUnit.trim(),
            [name]: value,
        };

        setInputs({
            ...inputs,
            lineItemCost: [...inputs.lineItemCost, newItem],
        });
        // Actualiza solo el valor que se muestra (original ingresado por el usuario)
    setDisplayValues((prevValues) => ({
      ...prevValues,
      [itemText]: value,
  }));
    }
};

  
  

const handleBlurItem = (e, itemText) => {
  const { value } = e.target;

  const updatedLineItemCost = [...inputs.lineItemCost];
  const itemFoundIndex = updatedLineItemCost.findIndex(
      (item) => item.lineItemText === itemText
  );

  // Cálculo de la suma si el valor contiene ";"
  if (value.includes(";")) {
      const values = value.split(";")
          .map((val) => parseFloat(val.replace(",", ".")))
          .filter((val) => !isNaN(val)); // Eliminar valores no válidos

      if (values.length > 0) {
          // Sumar todos los valores válidos
          const sum = values.reduce((acc, curr) => acc + curr, 0).toFixed(2);

          // Actualizar inputs con el valor calculado
          if (itemFoundIndex !== -1) {
              updatedLineItemCost[itemFoundIndex].cost = sum; // Actualiza el valor calculado en el estado inputs
          } else {
              updatedLineItemCost.push({
                  lineItemText: itemText,
                  cost: sum,
              });
          }

          setInputs({
              ...inputs,
              lineItemCost: updatedLineItemCost,
          });
      }
  }
};

  return (
    <>
      <div>
        <table className="table table-bordered">
          <thead className="itemText-thead">
            <tr>
              <th>CONCEPTO</th>
              <th>CONSUMO</th>
              <th>COSTO</th>
            </tr>
          </thead>
          <tbody>
          {itemsBillFiltered?.map((item, index) => (
            <tr className="itemText-row" key={item.id}>
          <td>
            {item.lineItemText} - {item.class}
          </td>
          <td>
                {item.consumptionUnit && item.consumptionUnit !== "null" ? (
                      <div className="itemColumn">
                      <input
                        type="number"
                        id={`consumption_${item.id}`}
                        value={inputs?.lineItemCost.find((input) => {
                          const inputText = input?.lineItemText?.toLowerCase()?.trim();
                          const itemText = item?.lineItemText?.toLowerCase()?.trim();
                          return inputText === itemText;
                        })?.consumption ?? ""}
                        name="consumption"
                        onChange={(e) =>
                          handleItemChange(
                            e,
                            item?.lineItemText,
                            item?.class,
                            item?.costUnit,
                            item?.consumptionUnit
                          )
                        }
                        placeholder={`Ingrese consumo`}
                        />{" "}
                      {item.consumptionUnit}
                    </div>
                    
                  ) : (
                      "No aplica"
                  )}
              </td>
                   <td>
                   {item.costUnit && item.costUnit !== "null" ? (
                    item.lineItemText?.includes("Subsidio Estado Nacional") &&
                    item.proveedor?.includes("EDEN T2 B1") ? (
                      // Input modificado para permitir múltiples valores con ";"
                      <div className="itemColumn">
                  <input
                    type="text"
                    id={`cost_${item.id}`}
                    value={displayValues[item.lineItemText] || ""} // Mostrar el valor ingresado original
                    name="cost"
                    onChange={(e) =>
                      handleItemChange(
                        e,
                        item.lineItemText,
                        item.class,
                        item.costUnit,
                        item.consumptionUnit
                      )
                    }
                    onBlur={(e) =>
                      handleBlurItem(e, item.lineItemText, item.class, item.costUnit, item.consumptionUnit)
                    }
                    placeholder="Ingrese costo; Ej: 121;-344"
                  />
                  {" "} {item.costUnit}$
                </div>
              ) : (
                // Input estándar para los demás casos
                <div className="itemColumn">
                  <input
                    type="number" // Se mantiene como número para entradas individuales
                    id={`cost_${item.id}`}
                    value={
                      inputs?.lineItemCost.find((input) => {
                        const inputText = input?.lineItemText?.toLowerCase()?.trim();
                        const itemText = item?.lineItemText?.toLowerCase()?.trim();
                        return inputText === itemText;
                      })?.cost ?? ""
                    }
                    name="cost"
                    onChange={(e) =>
                      handleItemChange(
                        e,
                        item?.lineItemText,
                        item?.class,
                        item?.costUnit,
                        item?.consumptionUnit
                      )
                    }
                    placeholder="Ingrese costo"
                  />
                  {" "} {item.costUnit}$
                </div>
              )
            ) : (
              "No aplica"
            )}
            </td>
            </tr>
          ))}
            {showCharge ? (
              <>
                <tr className="itemText-row">
                  <td className="">Otros recargos - Otros</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                      <input
                        type="text"
                        id="cost"
                        name="cost"
                        onChange={(e) =>
                          handleOtherCharge(e, "Otros recargos", "Otros", "AR")
                        }
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                      />
                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                  <td className="">Intereses por mora - Intereses</td>
                  <td>
                    <div className="itemColumn"></div>
                  </td>
                  <td>
                    <div className="itemColumn">
                      <input
                        type="text"
                        id="cost"
                        name="cost"
                        onChange={(e) =>
                          handleOtherCharge(
                            e,
                            "Intereses por mora",
                            "Intereses",
                            "AR"
                          )
                        }
                        placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                      />
                      AR$
                    </div>
                  </td>
                </tr>
                <tr className="itemText-row">
                <td className="">Otros impuestos y tasas - Impuestos</td>
                <td>
                  <div className="itemColumn"></div>
                </td>
                <td>
                  <div className="itemColumn">
                    <input
                      type="text"
                      id="cost"
                      name="cost"
                      onChange={(e) =>
                        handleOtherCharge(e, "Otros impuestos y tasas", "Impuestos", "AR")
                      }
                      placeholder="Ingrese costo, solo valor númerico. Ejemplo 9500,20"
                    />
                    AR$
                  </div>
                </td>
              </tr>
                {errors.otherCharges && (
                  <span className="error-message">{errors.otherCharges}</span>
                )}
              </>
            ) : null}
          </tbody>
        </table>
      </div>
      {!showCharge ? (
        <button
          className="btn-charges"
          type="button"
          onClick={() => setShowCharge(!showCharge)}
        >
          + Agregar recargos
        </button>
      ) : null}
      <div className="info-price">
        <label>EL TOTAL DE LA FACTURA ES: </label>
        <p>$ {totalCost?.toFixed(2)}</p>
      </div>
    </>
  );
};

export default LineItemText;