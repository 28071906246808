import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLoggers } from '../../redux/apiCalls/loggersCall';
import Activity from '../../components/Cards/Activity';
import { FaArrowLeft } from 'react-icons/fa';
import { parse, format } from 'date-fns';
import { es } from 'date-fns/locale';

const UserActivity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const loggers = useSelector((state) => state.loggers.loggers) || []; 

  const [currentPage, setCurrentPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(0); // Para rastrear el grupo de páginas

  const monthsPerPage = 6; // Limitar a 6 meses por grupo

  useEffect(() => {
    getLoggers(dispatch, user?.accessToken);
  }, [dispatch]);

  // Función para parsear las fechas de los loggers
  const parseDate = (dateString) => {
    // Parsear la fecha a un objeto Date
    return parse(dateString, 'dd/MM/yyyy, HH:mm:ss', new Date(), { locale: es });
  };

  // Función para agrupar los loggers por mes
  const groupByMonth = (loggers) => {
    return loggers.reduce((acc, logger) => {
      const date = parseDate(logger.date);
      const month = format(date, 'MMMM yyyy', { locale: es }); // Obtener mes y año como string

      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(logger);
      return acc;
    }, {});
  };

  // Agrupar los loggers por mes
  const monthlyLoggers = groupByMonth(loggers);
  const monthlyKeys = Object.keys(monthlyLoggers); // Obtener las claves para la paginación
  const totalPageGroups = Math.ceil(monthlyKeys.length / monthsPerPage); // Total de grupos de páginas

  // Calcular el grupo actual de meses para mostrar
  const indexOfFirstMonth = pageGroup * monthsPerPage;
  const currentMonthKeys = monthlyKeys.slice(indexOfFirstMonth, indexOfFirstMonth + monthsPerPage);
  const reversedMonthKeys = [...currentMonthKeys].reverse(); // Invertir el orden para la visualización

  // Obtener los datos para la página actual (mes)
  const currentMonthKey = monthlyKeys[currentPage - 1];
  const currentItems = monthlyLoggers[currentMonthKey] || [];

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Moverse al siguiente o anterior grupo de páginas
  const nextPageGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
      setCurrentPage(pageGroup * monthsPerPage); // Moverse a la última página del grupo anterior
    }
  };

  const prevPageGroup = () => {
    if (pageGroup < totalPageGroups - 1) {
      setPageGroup(pageGroup + 1);
      setCurrentPage((pageGroup + 1) * monthsPerPage + 1); // Moverse a la primera página del siguiente grupo
    }
  };

  return (
    <>
    <div className="table-responsive activities-list">
      <div className="cont-back cont-back-activity" onClick={() => navigate(`/admin/lists?show=users`)}>
        <i>
          <FaArrowLeft />
        </i>
        <p>Usuarios</p>
      </div> 
      <div className="data-table">
        <div className="contAddUsers">
          <p className="users">Actividad de usuarios</p>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr className='user-row'>
            <th>Usuario</th>
            <th>Actividad</th>
            <th>Fecha y hora</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((a) => (
            <Activity key={a.id} user={user} activity={a}/>
          ))}
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${pageGroup === 0 ? 'disabled' : ''}`}>
            <button className="btn btn-primary login" onClick={prevPageGroup} aria-label="Previous">
              <span aria-hidden="true">&laquo; Anterior</span>
            </button>
          </li>
          {reversedMonthKeys.map((monthKey, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === indexOfFirstMonth + (reversedMonthKeys.length - index) ? 'active' : ''}`}
            >
              <button
                className="btn btn-primary login"
                onClick={() => paginate(indexOfFirstMonth + (reversedMonthKeys.length - index))}
              >
                {monthKey}
              </button>
            </li>
          ))}
          <li className={`page-item ${pageGroup === totalPageGroups - 1 ? 'disabled' : ''}`}>
            <button className="btn btn-primary login" onClick={nextPageGroup} aria-label="Next">
              <span aria-hidden="true">Siguiente &raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    </>
  );
}

export default UserActivity;
