import React from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteItemBill } from '../../redux/apiCalls/itemBills';
import { Toast } from '../../helpers/alert';
import Swal from 'sweetalert2';

const ItemBill = ({item}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);


  async function handleDelete(e) {
    e.preventDefault();
    Swal.fire({
      title:`¿Está seguro de que desea eliminar el concepto?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Sí, eliminar el concepto.",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteItemBill(dispatch, item.id, user.accessToken);
            navigate("/admin/lists?show=itemsBill");
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: "No se pudo eliminar el concepto.",
          });
        }
      }
    });
  }

  return (
    <tr className='user-row' id={`itemsBill-${item.id}`}>
      <td>{item.lineItemText}</td>
      <td>{item.class}</td>
      <td>{item.consumptionUnit && item.consumptionUnit !== "null" ? item.consumptionUnit : "N/A"}</td>
      <td>{item.costUnit && item.costUnit !== "null" ? item.costUnit : "N/A"}</td>
      <td>{item.proveedor}</td>
      <td className='cont-actions'>
              <>
              <div className="btnUser-edit btn-actions" onClick={()=>navigate(`/itemBill/edit/${item.id}`)}>
                  <FaEdit />
                  <p>Editar</p>
              </div>
              <div
                  onClick={(e) => handleDelete(e)}
                  className="btnUser-delete btn-actions"
              >
                  <FaTrashAlt />
                  <p>Eliminar</p>
              </div>
              </>
        </td>
    </tr>
  )
}

export default ItemBill