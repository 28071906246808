import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, ArcElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { provinces } from "../../helpers/infoFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DonutChart = ({ data, type }) => {
  let labels;
  let dataValues;

  const dataChart = useMemo(
    function () {
      if (data) {
        if (type === "summary") {
          labels = Object.keys(data);
          dataValues = Object.values(data);
        }
        if (type === "finance" || type === "consumption") {
          // Agrupa y formatea los costos totales por proveedor
          let groupedData = data?.reduce((acc, item) => {
            if (item?.supplier && item?.lineItemText !== "Total Factura") {
              const supplier = `${item?.supplier?.trim().split(" ")[0].toUpperCase()} - ${item?.utilityType}`;
              const cost = type === "finance" ? parseFloat(item?.cost) : parseFloat(item?.consumption);
              if (!isNaN(cost) && isFinite(cost) && cost !== null && item?.cost !== " ") {
                if (acc[supplier]) {
                  acc[supplier] += cost;
                } else {
                  // Si el proveedor no está en el acumulador, iniciar el valor del costo
                  acc[supplier] = cost;
                }
              }
            }
            return acc;
          }, {});

          let normalizedData = {};

          for (let key in groupedData) {
              if (groupedData.hasOwnProperty(key)) {
                  let normalizedKey = key.toUpperCase();
                  if (normalizedData[normalizedKey]) {
                      normalizedData[normalizedKey] += groupedData[key] || 0;
                  } else {
                      normalizedData[normalizedKey] = groupedData[key] || 0;
                  }
              }
            }
            groupedData = normalizedData;
          
          labels = Object.keys(groupedData);
          dataValues = Object.values(groupedData);
        }
        if (type === "Water") {
          let groupedData = data?.reduce((acc, item) => {
            if (item?.medicion && item?.CO2) {
                const medicion = item?.medicion.trim();
                const co2 = Number(item?.CO2);
                if (!isNaN(co2) && co2 !== -1) { // Verifica si el CO2 es un número válido
                    if (acc[medicion]) {
                        acc[medicion] += co2;
                    } else {
                        acc[medicion] = co2;
                    }
                }
            }
            return acc;
          }, {});
        
          for (let medicion in groupedData) {
              if (!isNaN(groupedData[medicion])) {
                  groupedData[medicion] = (groupedData[medicion])?.toFixed(6)
              }
          }
          
          labels = Object.keys(groupedData);
          dataValues = Object.values(groupedData);   
        }
      
    }
            
      return {
        datasets: [
          {
            data: dataValues,
            backgroundColor: [
              "#c3c8cc", // electricidad
              "#70be70b6", // agua
              "#eee454d5",
              "#0927ce65", 
              "#1dd6a894",
              "#86d61d94",
              "#cad61d8a",
              "#d68c1d8a",
              "#d6291d8a",
              "#1d5ed68a",
              "#2ca1be8a"

            ],
          },
        ],
        labels,
      };
    },
    [data, type]
  );

  const options = {
    fill: false,
    responsive: true,
    scales: {
      y: {
        min: 0,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          font: {
            size: 8
          }
        },
      },
      title: {
        display: true,
        text: type === "Water" ? "Emisiones de CO2" : type === "summary" ? "Gasto por provincia" : type === "consumption" ? "Consumos por proveedor" : "Gasto por proveedor",
        padding: { bottom: 10 },
      },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.label || '';
              if (type === "summary") {
                const value = dataValues[context.dataIndex];
                const provinceName = provinces.find(province => province.value === labels[context.dataIndex]);
                const formattedValue = value ? value?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null;
                label = `${provinceName && provinceName?.name ? provinceName?.name : null}: $${formattedValue}`; // Agregar el texto personalizado al tooltip
              } else if (type === "finance"){
                const value = context?.parsed;
                const formattedValue = value ? value?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null;
                label = `AR$ ${formattedValue}`
              } else if (type === "consumption"){
                const value = context?.parsed;
                const formattedValue = value ? value?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null;
                label = `Consumo: ${formattedValue}`
              } else if (type === "Water"){
                const value = context?.parsed;
                const formattedValue = value ? value?.toLocaleString('es-AR', {minimumFractionDigits: 3, maximumFractionDigits: 3}) : null;
                label = `${formattedValue} Ton CO2`
              } else{
                const value = context?.parsed;
                const unit = label === "ELECTRICIDAD" ? "kWh" : "m3";
                const formattedValue = value ? value?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null;
                label = `${formattedValue} ${unit}`
                
              }
              return label;
            }
          }
        }
      
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <div className="cont-donutsf">
      <Doughnut data={dataChart} options={options} />
    </div>
  );
};

export default DonutChart;
